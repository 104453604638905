import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { endpoint } from "../config";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  DateRangePicker,
  Divider,
  Grid,
  Icon,
  InputNumber,
  Modal,
  Panel,
  PanelGroup,
  Popover,
  Row,
  SelectPicker,
  Table,
  TagPicker,
  Tooltip,
  Whisper,
  Loader,
} from "rsuite";
import { initEvtMgrParState, changeEvtMgrParState } from "../actions/evtmgrpar";
import { loadSuggestedEvents } from "../actions/evtmgr";
import { addToWallet } from "../actions/wallet";
import GoogleMap from "./GoogleMap";
import moment from "moment";
import ReactMarkdown from "react-markdown";

const { Column, HeaderCell, Cell } = Table;

const labelFieldStyles = {
  fontSize: 12,
  color: " #aaa",
  marginTop: 10,
  marginBottom: 10,
  textDecoration: "underline",
};

const weekDays = [
  { value: 0, label: "Pn", name: "poniedziałek" },
  { value: 1, label: "Wt", name: "wtorek" },
  { value: 2, label: "Śr", name: "środa" },
  { value: 3, label: "Cz", name: "czwartek" },
  { value: 4, label: "Pt", name: "piątek" },
  { value: 5, label: "So", name: "sobota" },
  { value: 6, label: "Nd", name: "niedziela" },
];

const locale = {
  sunday: "Nd",
  monday: "Pn",
  tuesday: "Wt",
  wednesday: "Śr",
  thursday: "Cz",
  friday: "Pt",
  saturday: "So",
  ok: "OK",
  today: "Dzisiaj",
  yesterday: "Wczoraj",
  last7Days: "Ostatni tydzień",
  hours: "Godzin",
  minutes: "Minut",
  seconds: "Sekund",
};

class EventManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actTitles: {},
      eventForGoogleMap: null,
      eventHistory: {},
      googleMapModalOpen: false,
      isLoading: false,
      isNewEventAdding: false,
      suggestedActs: { 0: {}, 1: {}, 2: {} },
    };

    this.handleChangeDateRange = this.handleChangeDateRange.bind(this);
    this.handleChangeTeams = this.handleChangeTeams.bind(this);
    this.handleChangeDateTime = this.handleChangeDateTime.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangeWeekDays = this.handleChangeWeekDays.bind(this);
    this.handleChangeActs = this.handleChangeActs.bind(this);
    this.handleSuggestedActsYearsBack = this.handleSuggestedActsYearsBack.bind(
      this
    );
    this.renderActsItems = this.renderActsItems.bind(this);
    this.renderActsValues = this.renderActsValues.bind(this);
    this.handleLoadSuggestedTerms = this.handleLoadSuggestedTerms.bind(this);
    this.handleAddToCalendar = this.handleAddToCalendar.bind(this);
    this.handleShowOnMap = this.handleShowOnMap.bind(this);
    this.handleGoogleMapModalClose = this.handleGoogleMapModalClose.bind(this);
  }

  componentDidMount() {
    // console.log("EventManager mounted");
    if (this.props.evtmgrpar === undefined) {
      this.props.initEvtMgrParState(this.props.instytucja);
    }
    let actTitles = {};
    this.props.acts.forEach((act) => (actTitles[act.id] = act.title));
    this.setState({ actTitles });
    this.loadEventHistory();
  }

  loadEventHistory(instid = this.props.instytucja) {
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .get(endpoint + "/eventmanager/eventhistory/?instid=" + instid, config)
      .then((res) => {
        this.setState(
          {
            eventHistory: res.data.event_history,
          },
          this.getActSuggestions(
            this.props.evtmgrpar.suggestedActsYearsBack,
            res.data.event_history
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleChangeDateRange(val) {
    this.props.changeEvtMgrParState(this.props.instytucja, {
      dateRange: val,
    });
  }

  handleChangeTeams(val) {
    this.props.changeEvtMgrParState(this.props.instytucja, {
      teams: val,
    });
  }

  handleChangeDateTime(name, val) {
    const newState = {};
    newState[name] = val;
    this.props.changeEvtMgrParState(this.props.instytucja, newState);
  }

  handleChangeInput(val, evt, name) {
    const newState = {};
    newState[name] = val;
    this.props.changeEvtMgrParState(this.props.instytucja, newState);
  }

  handleChangeWeekDays(val) {
    this.props.changeEvtMgrParState(this.props.instytucja, {
      weekDays: val,
    });
  }

  handleChangeActs(val) {
    this.props.changeEvtMgrParState(this.props.instytucja, {
      acts: val,
    });
  }

  handleSuggestedActsYearsBack(yearsBack) {
    this.props.changeEvtMgrParState(this.props.instytucja, {
      suggestedActsYearsBack: yearsBack,
    });
    this.getActSuggestions(yearsBack);
  }

  getActSuggestions(yearsBack = 2, eventHistory = this.state.eventHistory) {
    const { actTitles } = this.state;
    let seasons = [];
    for (let i = 0; i <= yearsBack; i++) {
      seasons[i] = this.season_str(i);
    }
    let suggestedActs = { 0: {}, 1: {}, 2: {} };
    let newActs = Object.assign({}, actTitles);
    if (eventHistory != null) {
      for (let [s, o] of Object.entries(eventHistory)) {
        if (seasons.includes(s)) {
          for (const obj of o) {
            let na = {};
            na[obj] = actTitles[obj];
            Object.assign(suggestedActs[2], na);
            delete newActs[obj];
          }
        } else {
          for (const obj of o) {
            let na = {};
            na[obj] = actTitles[obj];
            Object.assign(suggestedActs[1], na);
            delete newActs[obj];
          }
        }
      }
    }
    for (let id of Object.keys(suggestedActs[1])) {
      if (Object.keys(suggestedActs[2]).includes(id)) {
        delete suggestedActs[1][id];
      }
    }
    Object.assign(suggestedActs[0], newActs);
    this.setState({ suggestedActs: suggestedActs });
    let acts = [];
    for (const id of Object.keys(suggestedActs[0])) {
      acts.push(parseInt(id));
    }
    for (const id of Object.keys(suggestedActs[1])) {
      acts.push(parseInt(id));
    }
    this.props.changeEvtMgrParState(this.props.instytucja, {
      acts: acts,
    });
  }

  renderActsItems(label, item) {
    const itemStyle = {};
    if (this.state.suggestedActs) {
      if (
        !Object.keys(this.state.suggestedActs[1]).includes(
          item.value.toString()
        )
      ) {
        itemStyle["color"] = "green";
      }
    }
    const itemView = <div style={itemStyle}>{label}</div>;
    return itemView;
  }

  renderActsValues(value, item) {
    const itemStyle = {};
    if (this.state.suggestedActs) {
      if (
        Object.keys(this.state.suggestedActs[0]).includes(item.value.toString())
      ) {
        itemStyle["color"] = "green";
      }
    }
    const itemView = <div style={itemStyle}>{item.label}</div>;
    return itemView;
  }

  season_str(yb) {
    let d = new Date();
    let y = d.getFullYear();
    let ss = 0;
    if (d.getMonth() < 8) {
      ss = y - yb - 1 + "/" + (y - yb);
    } else {
      ss = y - yb + "/" + (y - yb + 1);
    }
    return ss;
  }

  handleLoadSuggestedTerms() {
    this.props.loadSuggestedEvents(this.props.instytucja);
    this.props.addToWallet([this.props.instytucja]);
  }

  addNewEvent(startdate, enddate, team) {
    this.setState({ isNewEventAdding: true });
    let data = {};
    data["instytucja"] = this.props.instytucja;
    data["team"] = team;
    data["startdate"] = startdate;
    data["enddate"] = enddate;
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .post(endpoint + "/events/eventc/", data, config)
      .then((res) => {
        console.log("Appointment created");
        console.log(res.data);
        if (this.props.resetEventHistoryPanel) {
          this.props.resetEventHistoryPanel();
        }
        this.props.setEventForHarmonogram(res.data);
        this.setState({ isNewEventAdding: false });
        Alert.success("Poprawnie utworzono nowe wydarzenie");
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isNewEventAdding: false });
        Alert.error("Błąd utworzenia nowego wydarzenia");
      });
  }

  handleAddToCalendar(eventData) {
    this.addNewEvent(eventData.startdate, eventData.enddate, eventData.team.id);
    console.log(eventData);
  }

  handleShowOnMap(eventData) {
    this.setState(
      { eventForGoogleMap: eventData },
      this.setState({ googleMapModalOpen: true })
    );
  }

  handleGoogleMapModalClose() {
    this.setState({ googleMapModalOpen: false });
  }

  render() {
    let teamsData = this.props.teams.map((team) => ({
      label: team.name,
      value: team.id,
    }));
    if (!this.props.auth.user?.is_staff) {
      teamsData = teamsData.filter((team) =>
        this.props.auth.user.teams.includes(team.value)
      );
    }
    const actsData = this.props.acts.map((act) => ({
      label: act.title,
      value: act.id,
    }));

    const {
      actTitles,
      isNewEventAdding,
      googleMapModalOpen,
      eventForGoogleMap,
    } = this.state;

    const DateCell = ({ rowData, dataKey, ...props }) => {
      return (
        <Cell {...props}>
          {moment(rowData[dataKey]).format("DD-MM-YYYY (dddd)")}
        </Cell>
      );
    };

    const TimeCell = ({ rowData, dataKey, ...props }) => {
      return <Cell {...props}>{moment(rowData[dataKey]).format("HH:mm")}</Cell>;
    };

    const VerificationCell = ({ rowData, dataKey, ...props }) => {
      const colorOK = "rgba(0, 150, 0, 0.5)";
      const coloriNOK = "rgba(255, 0, 0, 1)";
      return (
        <Cell {...props}>
          <Whisper
            placement="top"
            trigger="hover"
            speaker={<Tooltip>Akceptowalny czas dojazdu (max 30 min)</Tooltip>}
          >
            <Icon
              icon="thumbs-up"
              style={{
                color: rowData[dataKey].min_conditions ? colorOK : coloriNOK,
              }}
            />
          </Whisper>
          <Whisper
            placement="top"
            trigger="hover"
            speaker={<Tooltip>Zgodność zakresu dat</Tooltip>}
          >
            <Icon
              icon="calendar"
              style={{
                color: rowData[dataKey].dates ? colorOK : coloriNOK,
                marginLeft: 15,
              }}
            />
          </Whisper>
          <Whisper
            placement="top"
            trigger="hover"
            speaker={<Tooltip>Zgodność zakresu godzin</Tooltip>}
          >
            <Icon
              icon="clock-o"
              style={{
                color: rowData[dataKey].hours ? colorOK : coloriNOK,
                marginLeft: 15,
              }}
            />
          </Whisper>
          <Whisper
            placement="top"
            trigger="hover"
            speaker={<Tooltip>Zgodność dni tygodnia</Tooltip>}
          >
            <Icon
              icon="calendar-check-o"
              style={{
                color: rowData[dataKey].week_days ? colorOK : coloriNOK,
                marginLeft: 15,
              }}
            />
          </Whisper>
          <Whisper
            placement="top"
            trigger="hover"
            speaker={
              <Tooltip>Zgodność czasu dojazdu i trwania spektaklu</Tooltip>
            }
          >
            <Icon
              icon="hourglass-1"
              style={{
                color: rowData[dataKey].duration ? colorOK : coloriNOK,
                marginLeft: 15,
              }}
            />
          </Whisper>
          <Whisper
            placement="top"
            trigger="hover"
            speaker={<Tooltip>Zgodność dostępnych spektakli</Tooltip>}
          >
            <Icon
              icon="film"
              style={{
                color: rowData[dataKey].acts ? colorOK : coloriNOK,
                marginLeft: 15,
              }}
            />
          </Whisper>
          <Whisper
            placement="top"
            trigger="hover"
            speaker={<Tooltip>Zgodność zespołów teatralnych</Tooltip>}
          >
            <Icon
              icon="people-group"
              style={{
                color: rowData[dataKey].teams ? colorOK : coloriNOK,
                marginLeft: 15,
              }}
            />
          </Whisper>
        </Cell>
      );
    };

    const InstytucjaInfo = (props) => {
      const inst = props.instytucja_info;
      const address =
        (inst.profil && inst.profil.ulica) ||
        (inst.ulica || "") + (inst.numerbud || "");
      const telefon =
        (inst.profil && inst.profil.telefon.replace(/<<.*>>/g, "")) || "";
      return (
        <div style={{ textAlign: "left" }}>
          <h4
            onClick={() => {
              if (this.props.changeTab) {
                this.props.changeTab(0);
              }
              this.props.history.push("/admin/instytucje/" + inst.id);
            }}
            style={{ fontWeight: "bold" }}
          >
            {(inst.profil && inst.profil.nazwa) || inst.nazwa}
          </h4>
          <div style={{ marginTop: "1em", marginBottom: "1em" }}>
            {address} <br />
            {(inst.profil && inst.profil.kodpocz) || inst.kodpocz} {"  "}
            {(inst.profil && inst.profil.miejscowosc) || inst.miejscowosc}{" "}
            <br />
            <ReactMarkdown
              source={inst.profil !== null ? telefon : inst.telefon}
            />
          </div>
        </div>
      );
    };

    const EventInfo = ({ event_info, ...props }) => {
      return (
        <p>
          czas trwania:{" "}
          <span style={{ fontWeight: "bold" }}>
            {moment(event_info.startdate).format("HH:mm")}
            {" - "}
            {moment(event_info.enddate).format("HH:mm")}
          </span>{" "}
          <br />
          spektakle:{" "}
          {event_info.acts.length > 0
            ? event_info.acts.map((act) => (
                <span key={act} style={{ fontWeight: "bold" }}>
                  {props.actTitles[act]},{" "}
                </span>
              ))
            : "Brak zaplanowanych spektakli"}
        </p>
      );
    };

    const EventPopover = ({ eventObj, actTitles, ...props }) => {
      const inst = eventObj.instytucja_info;
      return (
        <Popover {...props} title={inst?.profil?.nzawa | inst?.nazwa}>
          <div style={{ maxWidth: 500 }}>
            <InstytucjaInfo instytucja_info={inst} />
            <Divider />
            <h4 style={{ fontWeight: "bold" }}>Dane wydarzenia:</h4>
            <EventInfo event_info={eventObj} actTitles={actTitles} />
          </div>
        </Popover>
      );
    };

    const MainEventPopover = ({
      rowData,
      instytucja_info,
      actTitles,
      ...props
    }) => {
      const inst = instytucja_info;
      return (
        <Popover {...props} title={inst?.profil?.nzawa | inst?.nazwa}>
          <div style={{ maxWidth: 500 }}>
            <InstytucjaInfo instytucja_info={inst} />
            <Divider />
            <h4 style={{ fontWeight: "bold" }}>Dane wydarzenia:</h4>
            <p>
              proponowany czas trwania:{" "}
              <span style={{ fontWeight: "bold" }}>
                {moment(rowData.startdate).format("HH:mm")}
                {" - "}
                {moment(rowData.enddate).format("HH:mm")}
              </span>{" "}
              <br />
              dostępne spektakle:{" "}
              {rowData.weekacts != null
                ? rowData.weekacts.mainacts.map((act) => (
                    <span
                      key={act}
                      style={{ fontWeight: "bold", color: "red" }}
                    >
                      {actTitles[act]},{" "}
                    </span>
                  ))
                : null}
              {rowData.weekacts != null
                ? rowData.weekacts.resvacts.map((act) => (
                    <span
                      key={act}
                      style={{ fontWeight: "bold", color: "green" }}
                    >
                      {actTitles[act]},{" "}
                    </span>
                  ))
                : null}
            </p>
          </div>
        </Popover>
      );
    };

    const RouteArrowPopover = ({ distdur, ...props }) => {
      return (
        <Popover {...props}>
          <div style={{ maxWidth: 500 }}>
            <h4 style={{ fontWeight: "bold" }}>Parametry dojazdu:</h4>
            {distdur != null ? (
              <p>
                dystans:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {distdur?.distance.text}
                </span>{" "}
                <br />
                czas dojazdu:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {distdur?.duration.text}
                </span>
              </p>
            ) : (
              "Brak danych"
            )}
          </div>
        </Popover>
      );
    };

    const RouteCell = ({ rowData, dataKey, actTitles, ...props }) => {
      const instytucja_info = props.instytucja_info;
      return (
        <Cell {...props}>
          {rowData.prev_event != null ? (
            <Whisper
              placement="top"
              trigger="click"
              speaker={
                <EventPopover
                  eventObj={rowData.prev_event}
                  actTitles={actTitles}
                />
              }
            >
              <Whisper
                placement="top"
                trigger="hover"
                speaker={<Tooltip>Wydarzenie poprzednie</Tooltip>}
              >
                <Icon icon="peoples" />
              </Whisper>
            </Whisper>
          ) : (
            <Whisper
              placement="top"
              trigger="hover"
              speaker={<Tooltip>Brak wydarzenia poprzedniego</Tooltip>}
            >
              <Icon icon="home" />
            </Whisper>
          )}
          <Whisper
            placement="top"
            trigger="click"
            speaker={<RouteArrowPopover distdur={rowData.prev_distdur} />}
          >
            <Whisper
              placement="top"
              trigger="hover"
              speaker={<Tooltip>Parametry dojazdu do</Tooltip>}
            >
              <Icon
                icon="long-arrow-right"
                style={{
                  marginLeft: 20,
                  color:
                    rowData.prev_distdur != null
                      ? rowData.prev_distdur.duration.value / 60 <= 30
                        ? "green"
                        : "red"
                      : "grey",
                }}
              />
            </Whisper>
          </Whisper>
          <Whisper
            placement="top"
            trigger="click"
            speaker={
              <MainEventPopover
                instytucja_info={instytucja_info}
                actTitles={actTitles}
                rowData={rowData}
              />
            }
          >
            <Whisper
              placement="top"
              trigger="hover"
              speaker={<Tooltip>Planowane wydarzenie</Tooltip>}
            >
              <Icon
                icon="peoples"
                style={{ marginLeft: 20, color: "rgba(0, 0, 150, 0.6)" }}
              />
            </Whisper>
          </Whisper>
          <Whisper
            placement="top"
            trigger="click"
            speaker={<RouteArrowPopover distdur={rowData.next_distdur} />}
          >
            <Whisper
              placement="top"
              trigger="hover"
              speaker={<Tooltip>Parametry dojazdu od</Tooltip>}
            >
              <Icon
                icon="long-arrow-right"
                style={{
                  marginLeft: 20,
                  color:
                    rowData.next_distdur != null
                      ? rowData.next_distdur.duration.value / 60 <= 30
                        ? "green"
                        : "red"
                      : "grey",
                }}
              />
            </Whisper>
          </Whisper>
          {rowData.next_event != null ? (
            <Whisper
              placement="top"
              trigger="click"
              speaker={
                <EventPopover
                  eventObj={rowData.next_event}
                  actTitles={actTitles}
                />
              }
            >
              <Whisper
                placement="top"
                trigger="hover"
                speaker={<Tooltip>Wydarzenie następne</Tooltip>}
              >
                <Icon icon="peoples" style={{ marginLeft: 20 }} />
              </Whisper>
            </Whisper>
          ) : (
            <Whisper
              placement="top"
              trigger="hover"
              speaker={<Tooltip>Brak wydarzenia następnego</Tooltip>}
            >
              <Icon icon="home" style={{ marginLeft: 20 }} />
            </Whisper>
          )}
        </Cell>
      );
    };

    const ActionsCell = ({ rowData, dataKey, ...props }) => {
      return (
        <Cell {...props}>
          <Whisper
            placement="top"
            trigger="hover"
            speaker={<Tooltip>Pokaż na mapie</Tooltip>}
          >
            <Icon
              icon="map-marker"
              onClick={() => this.handleShowOnMap(rowData)}
            />
          </Whisper>
          <Whisper
            placement="top"
            trigger="hover"
            speaker={<Tooltip>Dodaj do terminarza</Tooltip>}
          >
            <Icon
              icon="calendar-plus-o"
              style={{ marginLeft: 20 }}
              onClick={() => this.handleAddToCalendar(rowData)}
            />
          </Whisper>{" "}
        </Cell>
      );
    };

    return (
      <Panel bodyFill={true}>
        <PanelGroup accordion>
          <Panel header="Menadżer wydarzeń" collapsible defaultExpanded>
            <Row>
              <Col xs={24}>
                <h4>Określ kryteria proponowanych terminów spektakli</h4>
              </Col>
            </Row>
            <Grid fluid>
              <Row>
                <Col xs={24} md={8}>
                  <div style={labelFieldStyles}>Zakres dat</div>
                  <DateRangePicker
                    name="dateRange"
                    value={this.props.evtmgrpar?.dateRange}
                    onChange={this.handleChangeDateRange}
                    cleanable={false}
                    format="DD-MM-YYYY"
                    locale={locale}
                  />
                </Col>
                <Col xs={24} md={16}>
                  <div style={labelFieldStyles}>Zespoły teatralne</div>
                  <TagPicker
                    id="teams"
                    placeholder="Zespoły teatralne"
                    name="teams"
                    data={teamsData}
                    value={this.props.evtmgrpar?.teams}
                    onChange={this.handleChangeTeams}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={8}>
                  <div style={labelFieldStyles}>Zakres godzin</div>
                  <span style={{ marginLeft: 10, marginRight: 10 }}>od:</span>
                  <DatePicker
                    name="timeFrom"
                    format="HH:mm"
                    ranges={[]}
                    value={this.props.evtmgrpar?.timeFrom}
                    hideHours={(hour) => hour < 7 || hour > 20}
                    hideMinutes={(minute) => minute % 5 !== 0}
                    onChange={(date) =>
                      this.handleChangeDateTime("timeFrom", date)
                    }
                    cleanable={false}
                    locale={locale}
                  />
                  <span style={{ marginLeft: 10, marginRight: 10 }}>do:</span>
                  <DatePicker
                    name="timeTo"
                    format="HH:mm"
                    ranges={[]}
                    value={this.props.evtmgrpar?.timeTo}
                    hideHours={(hour) => hour < 7 || hour > 20}
                    hideMinutes={(minute) => minute % 5 !== 0}
                    onChange={(date) =>
                      this.handleChangeDateTime("timeTo", date)
                    }
                    cleanable={false}
                    locale={locale}
                  />
                </Col>
                <Col xs={24} md={4}>
                  <div style={labelFieldStyles}>Czas trwania spektaklu</div>
                  <InputNumber
                    style={{ width: 100 }}
                    name="eventDuration"
                    value={this.props.evtmgrpar?.eventDuration}
                    min={30}
                    max={180}
                    step={5}
                    onChange={(val, evt) =>
                      this.handleChangeInput(val, evt, "eventDuration")
                    }
                  />
                </Col>
                <Col xs={24} md={12}>
                  <div style={labelFieldStyles}>Dni tygodnia</div>
                  <TagPicker
                    id="weekDays"
                    placeholder="Dni tygodnia"
                    name="weekDays"
                    data={weekDays}
                    value={this.props.evtmgrpar?.weekDays}
                    onChange={this.handleChangeWeekDays}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={6}>
                  <div style={labelFieldStyles}>Nie grane od</div>
                  <SelectPicker
                    data={[
                      {
                        value: 0,
                        label: this.season_str(0) + " (w tym sezonie)",
                      },
                      {
                        value: 1,
                        label: this.season_str(1) + " (od ubiegłego sezonu)",
                      },
                      {
                        value: 2,
                        label: this.season_str(2) + " (od 2 sezonów)",
                      },
                      {
                        value: 3,
                        label: this.season_str(3) + " (od 3 sezonów)",
                      },
                      {
                        value: 4,
                        label: this.season_str(4) + " (od 4 sezonów)",
                      },
                      {
                        value: 5,
                        label: this.season_str(5) + " (od 5 sezonów)",
                      },
                    ]}
                    value={this.props.evtmgrpar?.suggestedActsYearsBack}
                    searchable={false}
                    cleanable={false}
                    onChange={this.handleSuggestedActsYearsBack}
                  />
                </Col>
                <Col xs={24} md={18}>
                  <div style={labelFieldStyles}>Przedstawienia teatralne</div>
                  <TagPicker
                    id="acts"
                    block
                    name="acts"
                    placeholder="Przedstawienia teatralne"
                    data={actsData}
                    value={this.props.evtmgrpar?.acts}
                    onChange={this.handleChangeActs}
                    renderMenuItem={this.renderActsItems}
                    renderValue={this.renderActsValues}
                  />
                </Col>
              </Row>
              <Row>
                <Divider />
                <Col xs={24}>
                  <Button
                    appearance="primary"
                    onClick={this.handleLoadSuggestedTerms}
                    loading={this.props.suggestedEventsLoading}
                  >
                    Wyszukaj propozycje terminów spektakli
                  </Button>
                  {isNewEventAdding ? (
                    <Loader
                      backdrop
                      content="Dodaję wydarzenie do terminarza..."
                    />
                  ) : null}
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col xs={24}>
                  <h4>
                    Propozycje terminów spektakli{" "}
                    {this.props.suggestedEvents?.length > 0
                      ? " (znaleziono: " +
                        this.props.suggestedEvents.length +
                        ")"
                      : null}
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  {this.props.suggestedEvents?.length > 0 ? (
                    <Table
                      data={this.props.suggestedEvents}
                      loading={this.props.suggestedEventsLoading}
                      height={600}
                    >
                      <Column width={200} align="left">
                        <HeaderCell>Data</HeaderCell>
                        <DateCell dataKey="startdate" />
                      </Column>
                      <Column width={80} align="center">
                        <HeaderCell>Godzina</HeaderCell>
                        <TimeCell dataKey="startdate" />
                      </Column>
                      <Column width={300} align="center">
                        <HeaderCell>Status</HeaderCell>
                        <VerificationCell dataKey="verified" />
                      </Column>
                      <Column width={250} align="center">
                        <HeaderCell>Plan trasy</HeaderCell>
                        <RouteCell
                          dataKey=""
                          actTitles={actTitles}
                          instytucja_info={this.props.instytucja_info}
                        />
                      </Column>
                      <Column width={200} align="center">
                        <HeaderCell>Akcje</HeaderCell>
                        <ActionsCell dataKey="" />
                      </Column>
                    </Table>
                  ) : (
                    "Brak danych propozycji spektakli"
                  )}
                </Col>
              </Row>
            </Grid>
            <Modal
              full={true}
              backdrop={true}
              autoFocus={true}
              enforceFocus={true}
              overflow={true}
              show={googleMapModalOpen}
              onExit={this.handleGoogleMapModalClose}
            >
              <Modal.Header closeButton={false}>
                <Modal.Title>Mapa Geograficzna Wydarzeń</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <GoogleMap
                  instytucja={this.props.instytucja}
                  instytucja_info={this.props.instytucja_info}
                  eventData={eventForGoogleMap}
                  googleMapModalClose={this.handleGoogleMapModalClose}
                  setEventForGoogleMap={this.handleShowOnMap}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  appearance="primary"
                  style={{ marginTop: 20 }}
                  onClick={this.handleGoogleMapModalClose}
                >
                  {" "}
                  Zamknij mapę
                </Button>
              </Modal.Footer>
            </Modal>
          </Panel>
        </PanelGroup>
      </Panel>
    );
  }
}

const mapStateToProps = (state, props) => ({
  auth: state.auth,
  acts: state.acts.acts,
  teams: state.teams.teams,
  evtmgrpar: state.evtmgrpar.instState[props.instytucja],
  suggestedEventsLoading: state.evtmgr.suggestedEventsLoading,
  suggestedEvents: state.evtmgr.suggestedEvents[props.instytucja],
});

export default connect(mapStateToProps, {
  initEvtMgrParState,
  changeEvtMgrParState,
  loadSuggestedEvents,
  addToWallet,
})(EventManager);
