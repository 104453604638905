import React, { Component, Fragment } from "react";
import { Panel, Divider } from "rsuite";
import Timetable from "./Timetable";

class TimetableMain extends Component {
  render() {
    return (
      <Fragment>
        <Panel>
          <h1>Harmonogram przedstawień teatralnych</h1>
        </Panel>
        <Divider />
        <Panel defaultExpanded>
          <Timetable {...this.props} />
        </Panel>
      </Fragment>
    );
  }
}

export default TimetableMain;
