import React, { Component, Fragment } from "react";
import axios from "axios";
import { endpoint } from "../config";
import { connect } from "react-redux";
import {
  Grid,
  Row,
  Col,
  Input,
  ButtonToolbar,
  Button,
  Modal,
  Loader,
  Divider
} from "rsuite";
import moment from "moment";

const labelFieldStyles = {
  fontSize: 12,
  color: " #aaa",
  marginTop: 10,
  marginBottom: 10,
  textDecoration: "underline"
};

class CallEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      call: undefined
    };
    this.handleChangeNotes = this.handleChangeNotes.bind(this);
    this.handleCallSave = this.handleCallSave.bind(this);
    this.handleCallCancel = this.handleCallCancel.bind(this);
  }

  componentDidMount() {
    this.setState({ call: this.props.call });
  }

  handleCallSave() {
    const data = this.state.call;
    delete data.modified;
    delete data.created;
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .put(endpoint + "/calls/update/" + data.id + "/", data, config)
      .then(res => {
        this.props.onCallEditFormClose(true);
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleCallCancel() {
    this.props.onCallEditFormClose(false);
  }

  handleChangeNotes(e) {
    this.setState({
      call: {
        ...this.state.call,
        notes: e.target.value,
      }
    });
  }

  render() {
    const { call } = this.state;
    if (!call) {
      return <Loader backdrop content="Wczytuję dane kontaktu..." vertical />;
    } else {
      return (
        <Fragment>
          <Modal.Header closeButton={false}>
            <Modal.Title>
              Edycja kontaktu ID: {call.id ? call.id : "undefined"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid fluid>
              <Row className="show-grid">
                <Col xs={24}>
                  <div style={labelFieldStyles}>Notatki</div>
                  <Input
                    componentClass="textarea"
                    style={{ width: 500 }}
                    rows={10}
                    name="notes"
                    defaultValue={call.notes}
                    onBlur={this.handleChangeNotes}
                  />
                </Col>
              </Row>
              <Divider />
              <Row className="show-grid">
                <Col xs={12}>
                  <div style={labelFieldStyles}>Data modyfikacji</div>
                  <div>
                    {moment(call.modified).format(
                      "[dnia: ] DD-MM-YYYY [ o godzinie: ] HH:mm:ss"
                    )}
                  </div>
                </Col>
                <Col xs={12}>
                  <div style={labelFieldStyles}>Data utworzenia</div>
                  <div>
                    {moment(call.created).format(
                      "[dnia: ] DD-MM-YYYY [ o godzinie: ] HH:mm:ss"
                    )}
                  </div>
                </Col>
              </Row>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Divider />
            <ButtonToolbar>
              <Button appearance="default" onClick={this.handleCallCancel}>
                Anuluj
              </Button>
              <Button appearance="primary" onClick={this.handleCallSave}>
                Zapisz
              </Button>
            </ButtonToolbar>
          </Modal.Footer>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {}
)(CallEditForm);
