const Locale = {
    sunday: 'Nd',
    monday: 'Pn',
    tuesday: 'Wt',
    wednesday: 'Śr',
    thursday: 'Cz',
    friday: 'Pt',
    saturday: 'Sb',
    ok: 'OK',
    today: 'Dzisiaj',
    yesterday: 'Wczoraj',
    hours: 'Godzin',
    minutes: 'Minut',
    seconds: 'Sekund'
};

export {Locale};
