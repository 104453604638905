import React, { Component, Fragment } from "react";
import axios from "axios";
import { endpoint } from "../config";
import { connect } from "react-redux";
import InstytucjaInfo from "./InstytucjaInfo";
import {
  Grid,
  Row,
  Col,
  Input,
  ButtonToolbar,
  Button,
  Modal,
  Loader,
  Divider,
  DatePicker,
  Checkbox
} from "rsuite";
import moment from "moment";

const labelFieldStyles = {
  fontSize: 12,
  color: " #aaa",
  marginTop: 10,
  marginBottom: 10,
  textDecoration: "underline"
};

class ReminderEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reminder: undefined
    };
    this.handleChangeNotes = this.handleChangeNotes.bind(this);
    this.handleReminderSave = this.handleReminderSave.bind(this);
    this.handleReminderCancel = this.handleReminderCancel.bind(this);
    this.handleChangeDone = this.handleChangeDone.bind(this);
    this.handleChangeDatetime = this.handleChangeDatetime.bind(this);
  }

  componentDidMount() {
    this.setState({ reminder: this.props.reminder });
  }

  handleReminderSave() {
    const data = this.state.reminder;
    delete data.modified;
    delete data.created;
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .put(endpoint + "/reminders/update/" + data.id + "/", data, config)
      .then(res => {
        this.props.onReminderEditFormClose(true);
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleReminderCancel() {
    this.props.onReminderEditFormClose(false);
  }

  handleChangeNotes(e) {
    this.setState({
      reminder: {
        ...this.state.reminder,
        notes: e.target.value,
      }
    });
  }

  handleChangeDone(value, checked, e) {
    this.setState({
      reminder: {
        ...this.state.reminder,
        done: checked
      }
    });
  }

  handleChangeDatetime(date) {
    this.setState({
      reminder: {
        ...this.state.reminder,
        date: moment(date).format("YYYY-MM-DD"),
        time:
          moment(date).format("HH:mm:ss") === "00:00:00"
            ? null
            : moment(date).format("HH:mm:ss")
      }
    });
  }

  render() {
    const { reminder } = this.state;
    if (!reminder) {
      return (
        <Loader backdrop content="Wczytuję dane przypomnienia..." vertical />
      );
    } else {
      const datetime = moment(
        reminder.date +
          " " +
          (reminder.time === null ? "00:00:00" : reminder.time),
        "YYYY-MM-DD HH:mm:ss"
      );
      return (
        <Fragment>
          <Modal.Header closeButton={false}>
            <Modal.Title>
              Edycja przypomnienia ID: {reminder.id ? reminder.id : "undefined"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid fluid>
              {reminder.instytucja_info && reminder.instytucja_info !== null ? (
                <div>
                  <Row className="show-grid">
                    <Col xs={24}>
                      <div style={labelFieldStyles}>Dane instytucji</div>
                      <InstytucjaInfo
                        instytucja_info={reminder.instytucja_info}
                        history={this.props.history}
                      />
                    </Col>
                  </Row>
                  <Divider />{" "}
                </div>
              ) : (
                true
              )}
              <Row className="show-grid">
                <Col xs={12}>
                  <div style={labelFieldStyles}>Data przypomnienia</div>
                  <DatePicker
                    block={true}
                    cleanable={false}
                    format="DD-MM-YYYY HH:mm:ss"
                    value={datetime}
                    onChange={this.handleChangeDatetime}
                    isoWeek={true}
                  />
                </Col>
                <Col xs={12}>
                  <div style={labelFieldStyles}>Wykonane</div>
                  <Checkbox
                    checked={reminder.done}
                    onChange={this.handleChangeDone}
                  />
                </Col>
              </Row>
              <Row className="show-grid">
                <Col xs={24}>
                  <div style={labelFieldStyles}>Notatki</div>
                  <Input
                    componentClass="textarea"
                    style={{ width: 500 }}
                    rows={10}
                    name="notes"
                    defaultValue={reminder.notes}
                    onBlur={this.handleChangeNotes}
                  />
                </Col>
              </Row>
              <Divider />
              <Row className="show-grid">
                <Col xs={12}>
                  <div style={labelFieldStyles}>Data modyfikacji</div>
                  <div>
                    {moment(reminder.modified).format(
                      "[dnia: ] DD-MM-YYYY [ o godzinie: ] HH:mm:ss"
                    )}
                  </div>
                </Col>
                <Col xs={12}>
                  <div style={labelFieldStyles}>Data utworzenia</div>
                  <div>
                    {moment(reminder.created).format(
                      "[dnia: ] DD-MM-YYYY [ o godzinie: ] HH:mm:ss"
                    )}
                  </div>
                </Col>
              </Row>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Divider />
            <ButtonToolbar>
              <Button appearance="default" onClick={this.handleReminderCancel}>
                Anuluj
              </Button>
              <Button appearance="primary" onClick={this.handleReminderSave}>
                Zapisz
              </Button>
            </ButtonToolbar>
          </Modal.Footer>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {}
)(ReminderEditForm);
