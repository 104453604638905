import {
  REMINDERS_LOADING,
  CHANGE_REMINDERS_STATE,
  REMINDERS_LOAD_SUCCESS,
  REMINDERS_LOAD_ERROR,
} from "../actions/types";

import moment from "moment";

const initialState = {
  isLoading: false,
  reminders: [],
  count: 0,
  perPage: 25,
  activePage: 1,
  dateBefore: moment().endOf("day"),
  alsoDone: false,
  onlyUser: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REMINDERS_LOAD_SUCCESS:
      return {
        ...state,
        reminders: action.payload.reminders,
        count: action.payload.count,
        isLoading: false,
      };
    case REMINDERS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case REMINDERS_LOAD_ERROR:
      return {
        ...state,
        instytucje: [],
        count: 0,
        isLoading: false,
      };
    case CHANGE_REMINDERS_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
