import React from "react";
import ReactMarkdown from "react-markdown";
import { withRouter } from "react-router-dom";

function InstytucjaInfo(props) {
  const inst = props.instytucja_info;
  const address =
    (inst.profil && inst.profil.ulica) ||
    (inst.ulica || "") + (inst.numerbud || "");
  return (
    <div>
      <h4
        onClick={() => {
          if (
            props.history.location.pathname ===
            "/admin/instytucje/" + inst.id
          ) {
            if (props.changeTab) {
              props.changeTab(0);
            }
          } else {
            props.history.push("/admin/instytucje/" + inst.id);
            if (props.changeTab) {
              props.changeTab(0);
            }
          }
        }}
      >
        {(inst.profil && inst.profil.nazwa) || inst.nazwa}
      </h4>
      <div>
        {address} <br />
        {(inst.profil && inst.profil.kodpocz) || inst.kodpocz} {"  "}
        {(inst.profil && inst.profil.miejscowosc) || inst.miejscowosc} <br />
        <ReactMarkdown
          source={(inst.profil && inst.profil.telefon) || inst.telefon}
        />
      </div>
    </div>
  );
}

export default withRouter(InstytucjaInfo);
