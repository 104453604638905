import { GET_ACTS } from "../actions/types";

const initialState = {
  acts: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ACTS:
      return {
        ...state,
        acts: action.payload.results
      };
    default:
      return state;
  }
}
