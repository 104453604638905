import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getInstytucje, changeInstytucjeState } from "../actions/instytucje";
import { addToWallet, deleteFromWallet, clearWallet } from "../actions/wallet";
import Table from "react-bootstrap/Table";
import { Pagination, Alert, Toggle } from "rsuite";
import {
  InputPicker,
  Input,
  Button,
  Divider,
  Panel,
  Form,
  FormGroup,
  ControlLabel,
  HelpBlock,
  SelectPicker,
  Checkbox,
  Loader,
  IconButton,
  Icon,
  ButtonGroup,
} from "rsuite";
import WalletEmailForm from "./WalletEmailForm";
import axios from "axios";
import { endpoint } from "../config";
import moment from "moment";
import "moment/locale/pl";

const styleVisitedRow = {
  backgroundColor: "#cff9c9",
};

class Instytucje extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      searchOperator: "and",
      miejscowoscString: "",
      wojewodztwoString: "",
      kodpoczString: "",
      perPage: 25,
      activePage: 1,
      perPageNumbers: [
        { label: "10", value: 10 },
        { label: "25", value: 25 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
      ],
      sortedBy: null,
      sortedByData: [
        { label: "odwiedzone malejąco", value: 1 },
        { label: "odwiedzone rosnąco", value: 2 },
        { label: "cena malejąco", value: 3 },
        { label: "cena rosnąco", value: 4 },
      ],
      zipCodeNearby: "",
      checkAll: false,
      checkedItems: {},
      walletView: false,
      inList: [],
      walletEmailFormShow: false,
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handlePerPage = this.handlePerPage.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchEnter = this.handleSearchEnter.bind(this);
    this.updateSearchState = this.updateSearchState.bind(this);
    this.handleChangeSortedBy = this.handleChangeSortedBy.bind(this);
    this.handleAddNewInstytucja = this.handleAddNewInstytucja.bind(this);
    this.handleActionCheckboxChange = this.handleActionCheckboxChange.bind(
      this
    );
    this.handleCheckAllChange = this.handleCheckAllChange.bind(this);
    this.handleAddCheckedToWallet = this.handleAddCheckedToWallet.bind(this);
    this.handleDeleteCheckedFromWallet = this.handleDeleteCheckedFromWallet.bind(
      this
    );
    this.handleClearWallet = this.handleClearWallet.bind(this);
    this.handleShowWallet = this.handleShowWallet.bind(this);
    this.handleChangeSearchOperator = this.handleChangeSearchOperator.bind(
      this
    );
    this.handleShowEmailFromWalletForm = this.handleShowEmailFromWalletForm.bind(
      this
    );
  }

  componentDidMount() {
    if (!this.props.count) {
      this.props.getInstytucje();
    } else {
      this.setState(this.props.searchState);
      this.props.getInstytucje(
        this.props.searchState?.perPage,
        (this.props.searchState?.activePage - 1) *
          this.props.searchState?.perPage,
        this.props.searchState?.searchString,
        this.props.searchState?.searchOperator,
        this.props.searchState?.miejscowoscString,
        this.props.searchState?.wojewodztwoString,
        this.props.searchState?.kodpoczString,
        this.props.searchState?.zipCodeNearby,
        "",
        this.props.searchState?.sortedBy
      );
    }
    if (this.props.searchState == null) {
      this.updateSearchState();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.wallet.data !== prevProps.wallet.data &&
      this.state.walletView
    ) {
      if (this.props.wallet.data.size > 0) {
        this.handleShowWallet("wallet");
      } else {
        this.handleShowWallet("search");
      }
    }
  }

  updateSearchState(newObj = {}) {
    const searchState = Object.assign(this.state, newObj);
    this.props.changeInstytucjeState(searchState);
  }

  handleSelect(eventKey) {
    this.setState({
      activePage: eventKey,
    });
    this.props.getInstytucje(
      this.state.perPage,
      (eventKey - 1) * this.state.perPage,
      this.state.searchString,
      this.state.searchOperator,
      this.state.miejscowoscString,
      this.state.wojewodztwoString,
      this.state.kodpoczString,
      this.state.zipCodeNearby,
      this.state.inList,
      this.state.sortedBy
    );
    if (!this.state.walletView) {
      this.updateSearchState({ activePage: eventKey });
    }
  }

  handlePerPage(eventKey) {
    this.setState({
      activePage: 1,
      perPage: eventKey,
    });
    this.props.getInstytucje(
      eventKey,
      0,
      this.state.searchString,
      this.state.searchOperator,
      this.state.miejscowoscString,
      this.state.wojewodztwoString,
      this.state.kodpoczString,
      this.state.zipCodeNearby,
      this.state.inList,
      this.state.sortedBy
    );
    if (!this.state.walletView) {
      this.updateSearchState({ perPage: eventKey, activePage: 1 });
    }
  }

  handleSearch(eventKey, event) {
    let searchObj = {};
    searchObj[event.target.name] = eventKey;
    this.setState(searchObj);
  }

  handleSearchEnter(eventKey, event) {
    this.props.getInstytucje(
      this.state.perPage,
      0,
      this.state.searchString,
      this.state.searchOperator,
      this.state.miejscowoscString,
      this.state.wojewodztwoString,
      this.state.kodpoczString,
      this.state.zipCodeNearby,
      this.state.inList,
      this.state.sortedBy
    );
    if (!this.state.walletView) {
      this.updateSearchState({
        searchString: this.state.searchString,
        searchOperator: this.state.searchOperator,
        miejscowoscString: this.state.miejscowoscString,
        wojewodztwoString: this.state.wojewodztwoString,
        kodpoczString: this.state.kodpoczString,
        zipCodeNearby: this.state.zipCodeNearby,
        activePage: 1,
      });
    }
  }

  handleChangeSortedBy(sortedBy) {
    this.setState({ sortedBy });
    this.props.getInstytucje(
      this.state.perPage,
      0,
      this.state.searchString,
      this.state.searchOperator,
      this.state.miejscowoscString,
      this.state.wojewodztwoString,
      this.state.kodpoczString,
      this.state.zipCodeNearby,
      this.state.inList,
      sortedBy
    );
    if (!this.state.walletView) {
      this.updateSearchState({ sortedBy: sortedBy });
    }
  }

  handleAddNewInstytucja(event) {
    event.preventDefault();
    const initData = {
      rspo: 900000000,
      profil: { nazwa: "Nowa instytucja" },
    };
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .post(endpoint + "/rspo/instytucjac/", initData, config)
      .then((res) => {
        console.log(res);
        this.props.history.push("/admin/instytucje/" + res.data.id);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 403 && err.response.data) {
          Alert.error(err.response.data.status);
          this.props.history.push("/admin/instytucje");
        } else if (err.response) {
          Alert.error(err.response.data.detail);
          this.props.history.push("/admin/instytucje");
        }
      });
  }

  handleActionCheckboxChange(value, checked, event) {
    this.setState({
      ...this.state,
      checkedItems: {
        ...this.state.checkedItems,
        [value]: checked,
      },
      checkAll: false,
    });
  }

  handleCheckAllChange(value, checked, event) {
    const instytucje = this.props.instytucje;
    if (checked) {
      this.setState({ checkAll: true });
      let checkedItems = {};
      instytucje.forEach((inst) => {
        checkedItems[inst.id] = true;
      });
      this.setState({ checkedItems });
    } else {
      this.setState({ checkAll: false });
      let checkedItems = {};
      this.setState({ checkedItems });
    }
  }

  handleAddCheckedToWallet() {
    let toWallet = [];
    const checkedItems = this.state.checkedItems;
    for (const key of Object.keys(checkedItems)) {
      if (checkedItems[key]) {
        toWallet.push(key);
      }
    }
    this.props.addToWallet(toWallet);
    this.setState({
      checkAll: false,
      checkedItems: {},
    });
  }

  handleDeleteCheckedFromWallet() {
    let toWallet = [];
    const checkedItems = this.state.checkedItems;
    for (const key of Object.keys(checkedItems)) {
      if (checkedItems[key]) {
        toWallet.push(key);
      }
    }
    this.props.deleteFromWallet(toWallet);
    this.setState({
      checkAll: false,
      checkedItems: {},
    });
  }
  handleClearWallet() {
    this.props.clearWallet();
  }

  handleShowWallet(to = "search") {
    if (to === "search") {
      this.setState(
        {
          ...this.props.searchState,
          checkAll: false,
          checkedItems: {},
          inList: [],
          walletView: false,
        },
        this.props.getInstytucje(
          this.props.searchState.perPage,
          (this.props.searchState.activePage - 1) *
            this.props.searchState.perPage,
          this.props.searchState.searchString,
          this.props.searchState.searchOperator,
          this.props.searchState.miejscowoscString,
          this.props.searchState.wojewodztwoString,
          this.props.searchState.kodpoczString,
          this.props.searchState.zipCodeNearby,
          "",
          this.props.searchState.sortedBy
        )
      );
    } else if (to === "wallet") {
      this.setState(
        {
          inList: [...this.props.wallet.data],
          searchString: "",
          searchOperator: "or",
          miejscowoscString: "",
          wojewodztwoString: "",
          kodpoczString: "",
          perPage: 25,
          activePage: 1,
          sortedBy: null,
          zipCodeNearby: "",
          checkAll: false,
          checkedItems: {},
          walletView: true,
        },
        this.props.getInstytucje(
          this.state.perPage,
          0,
          "",
          "or",
          "",
          "",
          "",
          "",
          [...this.props.wallet.data],
          this.state.sortedBy
        )
      );
    }
  }

  handleChangeSearchOperator(operator) {
    this.setState({
      searchOperator: this.state.searchOperator === "or" ? "and" : "or",
    });
    this.props.getInstytucje(
      this.state.perPage,
      0,
      this.state.searchString,
      operator ? "and" : "or",
      this.state.miejscowoscString,
      this.state.wojewodztwoString,
      this.state.kodpoczString,
      this.state.zipCodeNearby,
      this.state.inList,
      this.state.sortedBy
    );
    this.updateSearchState({
      searchOperator: operator ? "and" : "or",
    });
  }

  handleShowEmailFromWalletForm() {
    this.setState({
      walletEmailFormShow: !this.state.walletEmailFormShow,
    });
  }

  render() {
    return (
      <Panel bodyFill={true}>
        <h1>Placówki Oświatowe</h1>
        <Divider />
        <Form layout="inline">
          <FormGroup>
            <ControlLabel>Wyszukaj</ControlLabel>
            <Input
              type="text"
              name="searchString"
              style={{ width: 260, marginLeft: 10 }}
              value={this.state.searchString}
              onChange={this.handleSearch}
              onPressEnter={this.handleSearchEnter}
            />
            <HelpBlock tooltip>
              Wyszukuje w polach: rspo, nazwa, ulica, kod pocztowy, miejscowość,
              powiat, wojewodztwo, kraj, email, telefon stosując do kolejnych
              wyrazów wybierz funktor "lub/i"
            </HelpBlock>
            <ControlLabel style={{ marginLeft: 10, marginRight: 10 }}>
              {this.state.searchOperator === "or" ? "lub" : "i"}
            </ControlLabel>
            <Toggle
              checked={this.state.searchOperator === "or" ? false : true}
              onChange={this.handleChangeSearchOperator}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Miejscowość</ControlLabel>
            <Input
              name="miejscowoscString"
              style={{ width: 260, marginLeft: 10 }}
              value={this.state.miejscowoscString}
              onChange={this.handleSearch}
              onPressEnter={this.handleSearchEnter}
            />
            <HelpBlock tooltip>
              np: Bochnia Brzesko ... - stosując dla kolejnych wyrazów funktor
              "lub"
            </HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>Województwo</ControlLabel>
            <Input
              name="wojewodztwoString"
              style={{ width: 260, marginLeft: 10 }}
              value={this.state.wojewodztwoString}
              onChange={this.handleSearch}
              onPressEnter={this.handleSearchEnter}
            />
            <HelpBlock tooltip>
              np: Małopolskie ... - porównanie dokładne, wielkość liter
              nieistotna
            </HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>Kod pocztowy</ControlLabel>
            <Input
              name="kodpoczString"
              style={{ width: 260, marginLeft: 10 }}
              value={this.state.kodpoczString}
              onChange={this.handleSearch}
              onPressEnter={this.handleSearchEnter}
            />
            <HelpBlock tooltip>
              np: 30-34 30-4 ... - stosując dla kolejnych wyrazów funktor "lub"
            </HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>W pobliżu</ControlLabel>
            <Input
              name="zipCodeNearby"
              style={{ width: 260, marginLeft: 10 }}
              value={this.state.zipCodeNearby}
              onChange={this.handleSearch}
              onPressEnter={this.handleSearchEnter}
            />
            <HelpBlock tooltip>
              np: "123", "123,2" - 123 to id placówki w pobliżu której
              poszukujemy, a 2 to liczba cyfr bez znaczenia (licząc od końca) w
              kodzie pocztowym placówki
            </HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>Sortuj według</ControlLabel>
            <SelectPicker
              size={300}
              style={{ marginLeft: 10 }}
              searchable={false}
              data={this.state.sortedByData}
              value={this.state.sortedBy}
              placeholder="Wybierz kryterium sortowania"
              onChange={this.handleChangeSortedBy}
            />
            <HelpBlock tooltip>
              Sortuje wyniki według zadanego kryterium
            </HelpBlock>
          </FormGroup>
        </Form>
        <div>
          <span>wyświetl </span>
          <InputPicker
            value={this.state.perPage}
            data={this.state.perPageNumbers}
            style={{ width: 100, margin: 10 }}
            searchable="false"
            onChange={this.handlePerPage}
          />
          <span style={{ margin: 10 }}>
            wyników na stronie spośród wszystkich{" "}
            <strong>{this.props.count}</strong>
          </span>
          <ButtonGroup block size="lg" style={{ marginLeft: 30 }}>
            <IconButton
              style={{ marginLeft: 2 }}
              icon={<Icon icon="shopping-cart" size="2x" />}
              color={this.state.walletView ? "blue" : "green"}
              onClick={() => {
                if (this.state.walletView) {
                  this.handleShowWallet("search");
                } else {
                  this.handleShowWallet("wallet");
                }
              }}
              disabled={this.props.wallet.data.size > 0 ? false : true}
              size="lg"
            />
            <IconButton
              style={{ marginLeft: 2 }}
              icon={<Icon icon="arrow-circle-o-down" size="2x" />}
              onClick={this.handleAddCheckedToWallet}
              disabled={this.state.walletView ? true : false}
              size="lg"
            />
            <IconButton
              style={{ marginLeft: 2 }}
              icon={<Icon icon="arrow-circle-o-up" size="2x" />}
              onClick={this.handleDeleteCheckedFromWallet}
              disabled={this.state.walletView ? false : true}
              size="lg"
            />
            <IconButton
              style={{ marginLeft: 2 }}
              icon={<Icon icon="close-circle" size="2x" />}
              onClick={this.handleClearWallet}
              disabled={this.state.walletView ? false : true}
              size="lg"
            />
          </ButtonGroup>
          <span style={{ margin: 10 }}>
            liczba instytucji w koszyku{" "}
            <strong>{this.props.wallet.data.size}</strong>
          </span>
          <Divider />
        </div>
        {this.props.isLoading ? (
          <Loader content="Wczytuję bazę danych placówek oświatowych..." />
        ) : (
          <div>
            <Pagination
              first
              prev
              ellipsis
              next
              last
              maxButtons={20}
              size="sm"
              pages={Math.ceil(this.props.count / this.state.perPage)}
              activePage={this.state.activePage}
              onSelect={this.handleSelect}
            />
            <br />
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th style={{ width: "25%" }}>Nazwa</th>
                  <th>Umówione</th>
                  <th style={{ width: "15%" }}>Dzwonić</th>
                  <th>Cena</th>
                  <th style={{ width: "15%" }}>Ulica</th>
                  <th>Miejscowość</th>
                  <th>Kod pocztowy</th>
                  <th>Województwo</th>
                  <th>
                    <Checkbox
                      name="checkAll"
                      checked={this.state.checkAll}
                      onChange={this.handleCheckAllChange}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.instytucje.map((inst) => (
                  <tr
                    key={inst.rspo}
                    style={
                      !(inst.profil != null && inst.profil.visited)
                        ? styleVisitedRow
                        : {}
                    }
                  >
                    <td>
                      <Link
                        style={{ color: "black" }}
                        to={`${this.props.match.url}/${inst.id}`}
                      >
                        {inst.id}
                      </Link>
                    </td>
                    <td>
                      <Link
                        style={{ color: "black" }}
                        to={`${this.props.match.url}/${inst.id}`}
                      >
                        {inst.nazwa.length > 0 || !inst.profil
                          ? inst.nazwa
                          : inst.profil.nazwa}
                      </Link>
                      {inst.profil != null && inst.profil.macierz !== null ? (
                        <Link
                          to={`${this.props.match.url}/${inst.profil.macierz}`}
                        >
                          {" "}
                          <span style={{ color: "#cc0000" }}>(filia->)</span>
                        </Link>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {inst.event.id === null
                        ? ""
                        : moment(inst.event.startdate).format("DD MMMM YYYY")}
                    </td>
                    <td>
                      {inst.reminder.id !== null ? (
                        <span
                          style={{
                            color: "#CC0000",
                          }}
                        >
                          {moment(
                            inst.reminder.date +
                              " " +
                              (inst.reminder.time === null
                                ? "00:00:00"
                                : inst.reminder.time),
                            "YYYY-MM-DD HH:mm:ss"
                          ).format("[dzwonić: ] DD MMMM YYYY")}
                        </span>
                      ) : (
                        inst.call.notes
                      )}
                    </td>
                    <td>
                      {inst.profil !== null ? inst.profil.maxpay : "0.00"}
                    </td>
                    <td>
                      {inst.profil !== null && inst.profil.ulica.length > 0
                        ? inst.profil.ulica
                        : inst.ulica +
                          (inst.ulica.length > 0 ? " " + inst.nrbudynku : "")}
                    </td>
                    <td>
                      {inst.profil !== null &&
                      inst.profil.miejscowosc.length > 0
                        ? inst.profil.miejscowosc
                        : inst.miejscowosc}
                    </td>
                    <td>
                      {inst.profil !== null && inst.profil.kodpocz.length > 0
                        ? inst.profil.kodpocz
                        : inst.kodpocz}
                    </td>
                    <td>
                      {inst.profil !== null &&
                      inst.profil.wojewodztwo.length > 0
                        ? inst.profil.wojewodztwo
                        : inst.wojewodztwo}
                    </td>
                    <td>
                      <Checkbox
                        name={inst.id}
                        value={inst.id}
                        checked={
                          this.state.checkedItems[inst.id]
                            ? this.state.checkedItems[inst.id]
                            : false
                        }
                        onChange={this.handleActionCheckboxChange}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination
              first
              prev
              ellipsis
              next
              last
              maxButtons={20}
              size="sm"
              pages={Math.ceil(this.props.count / this.state.perPage)}
              activePage={this.state.activePage}
              onSelect={this.handleSelect}
            />
          </div>
        )}
        <Divider />
        <div>
          <Button appearance="primary" onClick={this.handleAddNewInstytucja}>
            Dodaj nową placówkę
          </Button>
          {this.props.auth?.user?.emails?.length > 0 ? (
            <Button
              style={{ marginLeft: 10 }}
              appearance="primary"
              onClick={this.handleShowEmailFromWalletForm}
            >
              Wyślij email do placówek z koszyka
            </Button>
          ) : null}
        </div>
        <Divider />
        {this.props.auth?.user?.emails?.length > 0 ? (
          <WalletEmailForm
            show={this.state.walletEmailFormShow}
            onEmailFormClose={this.handleShowEmailFromWalletForm}
          />
        ) : null}
      </Panel>
    );
  }
}

const mapStateToProps = (state) => ({
  instytucje: state.instytucje.instytucje,
  isLoading: state.instytucje.isLoading,
  count: state.instytucje.count,
  searchState: state.instytucje.searchState,
  auth: state.auth,
  eventStatusesDict: state.events.eventStatusesDict,
  wallet: state.wallet,
});

export default connect(mapStateToProps, {
  getInstytucje,
  changeInstytucjeState,
  addToWallet,
  deleteFromWallet,
  clearWallet,
})(Instytucje);
