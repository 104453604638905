import React, { Component } from "react";

class EditableHtmlField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: this.props.isEditing || false,
      content: this.props.content || ""
    };
    this.handleFocus = this.handleFocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      isEditing: newProps.isEditing,
      content: newProps.content
    });
  }

  handleFocus() {
    if (this.state.isEditing) {
      if (typeof this.props.onFocusOut === "function") {
        this.props.onFocusOut(this.props.fieldName, this.state.content);
      }
    } else {
      if (typeof this.props.onFocus === "function") {
        this.props.onFocus(this);
      }
    }
    this.setState({
      isEditing: !this.state.isEditing
    });
  }

  handleChange(e) {
    this.setState({
      content: e.target.value
    });
  }

  render() {
    const { isEditing, content } = this.state;
    if (isEditing) {
      return (
        <textarea
          type="text"
          value={content}
          onChange={this.handleChange}
          onBlur={this.handleFocus}
          className={this.props.inputClassName}
          style={{ height: 700, maxHeight: 1000 }}
          autoFocus
        />
      );
    } else {
      return (
        <div
          style={{ margin: 10, fontSize: 16 }}
          dangerouslySetInnerHTML={{ __html: content }}
          onClick={this.handleFocus}
        />
      );
    }
  }
}

export default EditableHtmlField;
