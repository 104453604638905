import axios from "axios";
import { endpoint } from "../config";
import {
  EVTMGR_SUGGESTED_EVENTS_LOADING,
  EVTMGR_SUGGESTED_EVENTS_LOADED,
  EVTMGR_SUGGESTED_EVENTS_LOAD_ERROR,
  EVTMGR_UPDATE_EVENT,
} from "./types";
import moment from "moment";

export const loadSuggestedEvents = (instId) => (dispatch, getState) => {
  dispatch({ type: EVTMGR_SUGGESTED_EVENTS_LOADING });
  const {
    dateRange,
    teams,
    timeFrom,
    timeTo,
    eventDuration,
    weekDays,
    acts,
  } = getState().evtmgrpar.instState[instId];
  const token = getState().auth.token;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }
  axios
    .get(
      endpoint +
        "/eventmanager/suggestedterms/?instid=" +
        instId +
        "&date_from=" +
        moment(dateRange[0]).format("YYYY-MM-DD") +
        "&date_to=" +
        moment(dateRange[1]).format("YYYY-MM-DD") +
        "&time_from=" +
        moment(timeFrom).format("HH:mm") +
        "&time_to=" +
        moment(timeTo).format("HH:mm") +
        "&event_duration=" +
        eventDuration +
        "&teams=" +
        teams +
        "&acts=" +
        acts +
        "&week_days=" +
        weekDays,
      config
    )
    .then((res) => {
      let payload = {};
      payload[instId] = res.data.event_suggestions;
      dispatch({
        type: EVTMGR_SUGGESTED_EVENTS_LOADED,
        payload: payload,
      });
    })
    .catch((err) => {
      let payload = {};
      payload[instId] = [];
      dispatch({
        type: EVTMGR_SUGGESTED_EVENTS_LOAD_ERROR,
        payload: payload,
      });
      console.log(err);
    });
};

export const updateSuggestedEvent = (instId, eventData) => (
  dispatch,
  getState
) => {
  let instSuggestedEvents = getState().evtmgr.suggestedEvents[instId];
  instSuggestedEvents = instSuggestedEvents.map((event) => {
    if (event.id === eventData.id) {
      return eventData;
    } else {
      return event;
    }
  });
  let payload = {};
  payload[instId] = instSuggestedEvents;
  dispatch({
    type: EVTMGR_UPDATE_EVENT,
    payload: payload,
  });
};
