import {
  INSTYTUCJE_LOADING,
  CHANGE_INSTYTUCJE_STATE,
  INSTYTUCJE_LOAD_SUCCESS,
  INSTYTUCJE_LOAD_ERROR
} from "../actions/types";

const initialState = {
  instytucje: [],
  isLoading: false,
  count: 0,
  next: null,
  previous: null,
  searchState: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case INSTYTUCJE_LOAD_SUCCESS:
      return {
        ...state,
        instytucje: action.payload.results,
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
        isLoading: false
      };
    case CHANGE_INSTYTUCJE_STATE:
      return {
        ...state,
        searchState: action.payload
      };
    case INSTYTUCJE_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case INSTYTUCJE_LOAD_ERROR:
      return {
        ...state,
        instytucje: [],
        count: 0,
        next: null,
        previous: null,
        isLoading: false
      };
    default:
      return state;
  }
}
