import {
  EVTMGR_SUGGESTED_EVENTS_LOADING,
  EVTMGR_SUGGESTED_EVENTS_LOADED,
  EVTMGR_SUGGESTED_EVENTS_LOAD_ERROR,
  EVTMGR_UPDATE_EVENT,
} from "../actions/types";

const initialState = {
  suggestedEventsLoading: false,
  suggestedEvents: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EVTMGR_SUGGESTED_EVENTS_LOADING:
      return {
        ...state,
        suggestedEventsLoading: true,
      };
    case EVTMGR_SUGGESTED_EVENTS_LOADED:
    case EVTMGR_SUGGESTED_EVENTS_LOAD_ERROR:
    case EVTMGR_UPDATE_EVENT:
      return {
        ...state,
        suggestedEventsLoading: false,
        suggestedEvents: {
          ...state.suggestedEvents,
          ...action.payload,
        }
      }
    default:
      return state;
  }
}
