import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { endpoint } from "../config";
import {
  Panel,
  Loader,
  Divider,
  Pagination,
  Grid,
  Row,
  Col,
  Input,
  Alert,
} from "rsuite";
import Table from "react-bootstrap/Table";
import moment from "moment";

const labelFieldStyles = {
  fontSize: 12,
  color: " #aaa",
  marginTop: 10,
  marginBottom: 10,
  textDecoration: "underline",
};

class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      count: 0,
      perPage: 25,
      activePage: 1,
      events: undefined,
      statusNotesSearchString: "",
    };
    this.handleSelectPage = this.handleSelectPage.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchEnter = this.handleSearchEnter.bind(this);
  }

  componentDidMount() {
    if (this.props.auth && !this.props.auth.user.is_staff) {
      this.props.history.push("/admin/home");
      Alert.error("Dostęp zabroniony");
    } else {
      this.loadEvents();
    }
  }

  handleSearch(eventKey, event) {
    this.setState({ [event.target.name]: eventKey });
  }

  handleSearchEnter(eventKey, event) {
    this.loadEvents();
  }

  loadEvents(limit = this.state.perPage, offset = 0) {
    this.setState({ isLoading: true });
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .get(
        endpoint +
          "/events/event/?limit=" +
          limit +
          "&offset=" +
          offset +
          "&team=999" +
          "&not_paid=1" +
          "&status_notes=" +
          this.state.statusNotesSearchString +
          "&ordering=-startdate",
        config
      )
      .then((res) => {
        if (res.data.count > 0) {
          let events = res.data.results;
          this.setState({ events, count: res.data.count });
        } else {
          this.setState({ events: [] });
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => console.log(err));
  }

  handleSelectPage(eventKey) {
    this.setState({
      activePage: eventKey,
    });
    this.loadEvents(this.state.perPage, (eventKey - 1) * this.state.perPage);
  }

  render() {
    const {
      isLoading,
      events,
      count,
      perPage,
      activePage,
      statusNotesSearchString,
    } = this.state;
    return (
      <Panel bodyFill={true}>
        <h1>Zaległe płatności</h1>
        <Divider />
        <Grid fluid>
          <Row className="show-grid">
            <Col xs={12}>
              <div style={labelFieldStyles}>Wyszukaj w notatce statusu</div>
              <Input
                name="statusNotesSearchString"
                value={statusNotesSearchString}
                onChange={this.handleSearch}
                onPressEnter={this.handleSearchEnter}
              />
            </Col>
          </Row>
        </Grid>
        <Divider />
        {isLoading ? (
          <Loader content="Wczytuję dane kontaktów z placówkami..." />
        ) : events && events.length > 0 ? (
          <Fragment>
            <Pagination
              first
              prev
              ellipsis
              next
              last
              maxButtons={20}
              size="sm"
              pages={Math.ceil(count / perPage)}
              activePage={activePage}
              onSelect={this.handleSelectPage}
            />
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Data wydarzenia</th>
                  <th>Nazwa placówki</th>
                  <th>Notatka</th>
                  <th>Miejscowość</th>
                </tr>
              </thead>
              <tbody>
                {events.map((event) => (
                  <tr key={event.id}>
                    <td>{event.id}</td>
                    <td>
                      {moment(event.startdate, "YYYY-MM-DD HH:mm:ss").format(
                        "[dnia: ] DD-MM-YYYY [ o godzinie: ] HH:mm:ss"
                      )}
                    </td>
                    <td>
                      <Link
                        to={"/admin/instytucje/" + event.instytucja_info.id}
                      >
                        {event.instytucja_info.profil !== null &&
                        event.instytucja_info.profil.nazwa.length > 0
                          ? event.instytucja_info.profil.nazwa
                          : event.instytucja_info.nazwa}
                      </Link>
                    </td>
                    <td>{event.eventstatuschange_set[0].notes}</td>
                    <td>
                      {event.instytucja_info.profil !== null &&
                      event.instytucja_info.profil.miejscowosc.length > 0
                        ? event.instytucja_info.profil.miejscowosc
                        : event.instytucja_info.miejscowosc}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination
              first
              prev
              ellipsis
              next
              last
              maxButtons={20}
              size="sm"
              pages={Math.ceil(count / perPage)}
              activePage={activePage}
              onSelect={this.handleSelectPage}
            />
          </Fragment>
        ) : (
          <div>Brak wydarzeń w wynikach wyszukiwania</div>
        )}
      </Panel>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  eventStatusesDict: state.events.eventStatusesDict,
});

export default connect(mapStateToProps, {})(Payments);
