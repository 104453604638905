import { EVTMGRPAR_INIT_STATE, EVTMGRPAR_CHANGE_STATE } from "./types";

export const initEvtMgrParState = (instId) => (dispatch) => {
  dispatch({
    type: EVTMGRPAR_INIT_STATE,
    payload: { instId: instId },
  });
};

export const changeEvtMgrParState = (instId, parState) => (dispatch) => {
  dispatch({
    type: EVTMGRPAR_CHANGE_STATE,
    payload: { instId: instId, parState: parState },
  });
};
