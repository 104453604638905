import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InstytucjaInfo from "./InstytucjaInfo";
import {
  Grid,
  Row,
  Col,
  Input,
  ButtonToolbar,
  Button,
  Modal,
  Loader,
  Divider
} from "rsuite";
import moment from "moment";

const labelFieldStyles = {
  fontSize: 12,
  color: " #aaa",
  marginTop: 10,
  marginBottom: 10,
  textDecoration: "underline"
};

class ReminderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reminder: undefined
    };
    this.handleReminderCancel = this.handleReminderCancel.bind(this);
    this.handleGoToInstytucja = this.handleGoToInstytucja.bind(this);
    this.handleReminderEdit = this.handleReminderEdit.bind(this);
  }

  componentDidMount() {
    this.setState({ reminder: this.props.reminder });
  }

  handleReminderCancel() {
    this.props.onReminderViewClose(false);
  }

  handleGoToInstytucja() {
    let path = "/admin/instytucje/" + this.state.reminder.instytucja_info.id;
    this.props.history.push(path);
  }

  handleReminderEdit() {
    this.props.onReminderEditClick(this.state.reminder);
  }

  render() {
    const { reminder } = this.state;
    if (!reminder) {
      return (
        <Loader backdrop content="Wczytuję dane przypomnienia..." vertical />
      );
    } else {
      const datetime =
        "dnia: " +
        reminder.date +
        " " +
        (reminder.time === null
          ? "(nie określono czasu)"
          : "o godzinie: " + reminder.time);
      return (
        <Fragment>
          <Modal.Header closeButton={false}>
            <Modal.Title>
              Dane przypomnienia ID: {reminder.id ? reminder.id : "undefined"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid fluid>
              <Row className="show-grid">
                <Col xs={24}>
                  <div style={labelFieldStyles}>Dane instytucji</div>
                  <InstytucjaInfo
                    instytucja_info={reminder.instytucja_info}
                    history={this.props.history}
                  />
                </Col>
              </Row>
              <Divider />
              <Row className="show-grid">
                <Col xs={12}>
                  <div style={labelFieldStyles}>Data przypomnienia</div>
                  <div onClick={this.handleReminderEdit}>{datetime}</div>
                </Col>
                <Col xs={12}>
                  <div style={labelFieldStyles}>Wykonane</div>
                  <div onClick={this.handleReminderEdit}>
                    {reminder.done ? "TAK" : "NIE"}
                  </div>
                </Col>
              </Row>
              <Row className="show-grid">
                <Col xs={24}>
                  <div style={labelFieldStyles}>Notatki</div>
                  <Input
                    componentClass="textarea"
                    style={{ width: 500 }}
                    rows={10}
                    name="notes"
                    disabled={false}
                    value={reminder.notes}
                    onClick={this.handleReminderEdit}
                  />
                </Col>
              </Row>
              <Divider />
              <Row className="show-grid">
                <Col xs={12}>
                  <div style={labelFieldStyles}>Data modyfikacji</div>
                  <div>
                    {moment(reminder.modified).format(
                      "[dnia: ] DD-MM-YYYY [ o godzinie: ] HH:mm:ss"
                    )}
                  </div>
                </Col>
                <Col xs={12}>
                  <div style={labelFieldStyles}>Data utworzenia</div>
                  <div>
                    {moment(reminder.created).format(
                      "[dnia: ] DD-MM-YYYY [ o godzinie: ] HH:mm:ss"
                    )}
                  </div>
                </Col>
              </Row>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Divider />
            <ButtonToolbar>
              <Button appearance="default" onClick={this.handleReminderCancel}>
                Zamknij
              </Button>
              <Button appearance="primary" onClick={this.handleReminderEdit}>
                Edytuj
              </Button>
              <Button appearance="primary" onClick={this.handleGoToInstytucja}>
                Przejdź do panelu Instytucji
              </Button>
            </ButtonToolbar>
          </Modal.Footer>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {}
)(withRouter(ReminderView));
