import "bootstrap/dist/css/bootstrap.css";
// import "rsuite/dist/styles/rsuite.min.css";
import "./rsuite.css";
import 'rsuite/styles/index.less'
import './index.less'
import React, { Fragment, Component } from "react";
import { Provider } from "react-redux";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import store from "./store";
import PrivateRoute from "./components/common/PrivateRoute";
import Login from "./components/accounts/Login";
import Alerts from "./components/Alerts";
import NotFound from "./components/NotFound";
import Dashboard from "./components/Dashboard";
// Alert Options
const alertOptions = {
  timeout: 3000,
  position: "top center"
};
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <Router>
            <Fragment>
              <Alerts />
              <Switch>
                <PrivateRoute exact path="/" component={Dashboard} />
                <PrivateRoute path="/admin" component={Dashboard} />
                <Route exact path="/login" component={Login} />
                <Route component={NotFound} />
              </Switch>
            </Fragment>
          </Router>
        </AlertProvider>
      </Provider>
    );
  }
}

export default App;
