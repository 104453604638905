import React, { Component, Fragment } from "react";
import axios from "axios";
import { endpoint } from "../config";
import { connect } from "react-redux";
import {
  Drawer,
  Button,
  Alert,
  Divider,
  Grid,
  Row,
  Col,
  Input,
  SelectPicker,
} from "rsuite";
import EditableHtmlField from "./EditableHtmlField";
// import renderHTML from "react-render-html";

const labelFieldStyles = {
  fontSize: 12,
  color: " #aaa",
  marginTop: 10,
  marginBottom: 10,
  textDecoration: "underline",
};

class EventConfirmationEmailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instytucjaId: this.props.instytucjaId,
      from:
        this.props.auth?.user?.emails.length > 0
          ? this.props.auth.user.emails[0].id
          : null,
      to: "",
      subject: "",
      html_content: "",
    };
    this.handleChangeField = this.handleChangeField.bind(this);
    this.handleChangeHtmlContent = this.handleChangeHtmlContent.bind(this);
    this.handleSendEmail = this.handleSendEmail.bind(this);
    this.handleSelectEmail = this.handleSelectEmail.bind(this);
    this.changeEmailInHtmlContent = this.changeEmailInHtmlContent.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
    }
    if (prevState.html_content !== this.state.html_content) {
      this.changeEmailInHtmlContent(-1, this.state.from);
    }
  }

  handleChangeField(val, obj) {
    this.setState({
      [obj.target.name]: val,
    });
  }

  handleChangeHtmlContent(name, content) {
    this.setState({
      [name]: content,
    });
  }

  handleSelectEmail(val) {
    this.setState({ from: val });
    this.changeEmailInHtmlContent(this.state.from, val);
  }

  changeEmailInHtmlContent(emailIdOld = -1, emailIdNew = 0) {
    function getSubEmail(str) {
      const re = /<.*>/g;
      const found = str.match(re);
      if (found !== null && found[0].length > 0) {
        return found[0].slice(1, -1);
      } else {
        return str;
      }
    }
    const emailOld =
      parseInt(emailIdOld) === -1
        ? "lukaszmalec@teatreden.pl"
        : this.props.auth.user?.emails?.filter(
            (obj) => parseInt(obj.id) === parseInt(emailIdOld)
          )[0].email;
    const emailNew = this.props.auth.user?.emails?.filter(
      (obj) => parseInt(obj.id) === parseInt(emailIdNew)
    )[0].email;
    const { html_content } = this.state;
    const re = new RegExp(getSubEmail(emailOld), "g");
    const new_html_content = html_content.replace(re, getSubEmail(emailNew));
    this.setState({ html_content: new_html_content });
  }

  handleSendEmail() {
    const data = {
      instytucjaId: this.state.instytucjaId,
      from: this.props.auth.user.emails.filter(
        (email) => email.id === this.state.from
      )[0].email,
      to: this.state.to,
      subject: this.state.subject,
      html_content: this.state.html_content,
    };
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .post(
        endpoint +
          "/mailing/eventsconfirmationemail/" +
          data.instytucjaId +
          "/",
        data,
        config
      )
      .then((res) => {
        Alert.success("Email został wysłany");
        this.props.onEmailFormClose();
      })
      .catch((err) => {
        console.log(err);
        Alert.error("Błąd wysyłki maila");
      });
  }

  loadData() {
    const inst_id = this.props.instytucjaId;
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .get(
        endpoint + "/mailing/eventsconfirmationemail/" + inst_id + "/",
        config
      )
      .then((res) => {
        if (res.data.events.length > 0) {
          const data = res.data;
          this.setState(
            {
              ...data,
              to:
                data.instytucja.profil !== null
                  ? data.instytucja.profil.email
                  : data.instytucja.email,
            },
            this.changeEmailInHtmlContent(-1, this.state.from)
          );
        } else {
          Alert.warning("Brak zaplanowanych spektakli");
          this.props.onEmailFormClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const { from, to, subject, html_content } = this.state;
    return (
      <Fragment>
        <Drawer.Header>
          <Drawer.Title style={{ fontSize: 20 }}>
            Emailowe potwierdzenie zaplanowanych spektakli
          </Drawer.Title>
        </Drawer.Header>
        <Divider />
        <Drawer.Body>
          <Grid fluid>
            <Row className="show-grid">
              <Col xs={24} md={12}>
                <div style={labelFieldStyles}>Od</div>
                <SelectPicker
                  name="from"
                  data={this.props.auth.user.emails}
                  value={from}
                  valueKey="id"
                  labelKey="email"
                  onChange={this.handleSelectEmail}
                  cleanable={false}
                />
              </Col>
              <Col xs={24} md={12}>
                <div style={labelFieldStyles}>Do</div>
                <Input name="to" value={to} onChange={this.handleChangeField} />
              </Col>
            </Row>
            <Row className="show-grid">
              <Col xs={24}>
                <div style={labelFieldStyles}>Temat</div>
                <Input
                  name="subject"
                  value={subject}
                  onChange={this.handleChangeField}
                />
              </Col>
            </Row>
            <Row className="show-grid">
              <Col xs={24}>
                <div style={labelFieldStyles}>Treść</div>
                <EditableHtmlField
                  fieldName="html_content"
                  content={html_content}
                  onFocusOut={this.handleChangeHtmlContent}
                  inputClassName="rs-input"
                />
              </Col>
            </Row>
          </Grid>
        </Drawer.Body>
        <Drawer.Footer>
          <Button onClick={this.props.onEmailFormClose} appearance="subtle">
            Anuluj
          </Button>
          <Button
            onClick={this.handleSendEmail}
            disabled={from === null}
            appearance="primary"
          >
            Wyślij
          </Button>
        </Drawer.Footer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(EventConfirmationEmailForm);
