import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { endpoint } from "../config";
import { Panel, Divider, Button, Loader, Alert } from "rsuite";
import moment from "moment";
import { save } from "save-file";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dumpdataIsLoadind: false,
      emailDBIsClearing: false,
      queuedMailSending: false,
    };
    this.handleDumpData = this.handleDumpData.bind(this);
    this.handleClearEmailDb = this.handleClearEmailDb.bind(this);
    this.saveBackupFile = this.saveBackupFile.bind(this);
    this.handleSendQueuedMail = this.handleSendQueuedMail.bind(this);
  }

  handleDumpData() {
    this.setState({ dumpdataIsLoadind: true });
    this.loadDumpData();
  }

  handleClearEmailDb() {
    this.setState({ emailDBIsClearing: true });
    this.clearEmailDb();
  }

  handleSendQueuedMail() {
    this.setState({ queuedMailSending: true });
    this.sendQueuedMail();
  }

  saveBackupFile(data) {
    const filename = moment().format("YYYY-MM-DD_HH_mm[.json]");
    save(data, filename);
  }

  loadDumpData() {
    const token = this.props.auth.token;
    const config = {
      headers: {
        // "Content-Type": "application/json",
        Accept: "application/json; indent=4",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .get(endpoint + "/dumpdata/", config)
      .then((res) => {
        this.setState({ dumpdataIsLoadind: false });
        this.saveBackupFile(res.data[0]);
        Alert.success("Import danych przebiegł pomyślnie");
      })
      .catch((err) => {
        console.log(err);
        this.setState({ dumpdataIsLoadind: false });
        Alert.error("Błąd importu danych");
      });
  }

  clearEmailDb() {
    const token = this.props.auth.token;
    const config = {
      headers: {
        // "Content-Type": "application/json",
        Accept: "application/json; indent=4",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .get(endpoint + "/mailing/clearemaildb/", config)
      .then((res) => {
        this.setState({ emailDBIsClearing: false });
        if (res.data.email_deleted[0] > 0) {
          Alert.success(
            "Pomyślnie usunięto: " +
              res.data.email_deleted[1]["post_office.Email"] +
              " emaili oraz " +
              res.data.email_deleted[1]["post_office.Log"] +
              " logów"
          );
        } else {
          Alert.success("Baza emaili jest pusta");
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ dumpdataIsLoadind: false });
        Alert.error("Błąd serwera");
      });
  }

  sendQueuedMail() {
    const token = this.props.auth.token;
    const config = {
      headers: {
        // "Content-Type": "application/json",
        Accept: "application/json; indent=4",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .get(endpoint + "/mailing/sendqueuedmail/", config)
      .then((res) => {
        console.log(res.data);
        this.setState({ queuedMailSending: false });
        Alert.success("Wysłano maile z kolejki");
      })
      .catch((err) => {
        console.log(err);
        this.setState({ queuedMailSending: false });
        Alert.error("Błąd wysyłki maili");
      });
  }

  render() {
    const {
      dumpdataIsLoadind,
      emailDBIsClearing,
      queuedMailSending,
    } = this.state;
    return (
      <Panel>
        <h1>Home</h1>
        <Divider />
        {this.props.auth.user ? (
          <div>
            <h4 style={{ textDecoration: "underline" }}>
              Zalogowano użytkownika:
            </h4>
            <div>
              {this.props.auth.user.username} - {this.props.auth.user.email}
            </div>
            <Divider />
            {this.props.auth.user.is_staff ? (
              <div>
                {dumpdataIsLoadind ? (
                  <Loader content="Wczytuję bazę danych aplikacji..." />
                ) : (
                  <Button onClick={this.handleDumpData} appearance="primary">
                    Pobierz kopię zapasową danych aplikacji
                  </Button>
                )}
                {emailDBIsClearing ? (
                  <Loader content="Czyszczę bazę danych emaili..." />
                ) : (
                  <Button
                    onClick={this.handleClearEmailDb}
                    appearance="primary"
                    style={{ marginLeft: 15 }}
                  >
                    Wyczyść bazę emaili
                  </Button>
                )}
                {queuedMailSending ? (
                  <Loader content="Wysyłam maile z kolejki..." />
                ) : (
                  <Button
                    onClick={this.handleSendQueuedMail}
                    appearance="primary"
                    style={{ marginLeft: 15 }}
                  >
                    Wyślij zakolejkowane maile
                  </Button>
                )}
              </div>
            ) : null}
          </div>
        ) : (
          true
        )}
      </Panel>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Home);
