import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getReminders, changeRemindersState } from "../actions/reminders";
import { Locale } from "../locale";
import {
  Panel,
  Loader,
  Divider,
  Modal,
  Grid,
  Row,
  Col,
  DatePicker,
  Checkbox,
  Pagination,
  SelectPicker,
} from "rsuite";
import Table from "react-bootstrap/Table";
import moment from "moment";
import ReminderView from "./ReminderView";
import ReminderEditForm from "./ReminderEditForm";

const labelFieldStyles = {
  fontSize: 12,
  color: " #aaa",
  marginTop: 10,
  marginBottom: 10,
  textDecoration: "underline",
};

class RemindersWithState extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reminderViewModalOpen: false,
      reminderForView: undefined,
      reminderEditModalOpen: false,
      reminderForEdit: undefined,
    };
    this.handleReminderViewClose = this.handleReminderViewClose.bind(this);
    this.handleReminderEditClick = this.handleReminderEditClick.bind(this);
    this.handleReminderEditFormClose = this.handleReminderEditFormClose.bind(
      this
    );
    this.handleChangeDateBefore = this.handleChangeDateBefore.bind(this);
    this.handleChangeAlsoDone = this.handleChangeAlsoDone.bind(this);
    this.handleSelectPage = this.handleSelectPage.bind(this);
    this.handleChangeOnlyUser = this.handleChangeOnlyUser.bind(this);
  }

  componentDidMount() {
    if (
      this.props.reminders.onlyUser === null &&
      this.props.reminders.reminders.length === 0 &&
      this.props.auth.user
    ) {
      this.props.changeRemindersState({ onlyUser: this.props.auth.user.id });
    }
    if (this.props.reminders.reminders.length === 0) {
      this.props.getReminders({ onlyUser: this.props.auth.user.id });
    }
  }

  handleOnReminderClick(e, reminder) {
    e.preventDefault();
    this.setState({ reminderForView: reminder, reminderViewModalOpen: true });
  }

  handleReminderViewClose() {
    this.setState({ reminderForView: undefined, reminderViewModalOpen: false });
  }

  handleReminderEditClick(reminder) {
    this.setState({
      reminderForView: undefined,
      reminderForEdit: reminder,
      reminderViewModalOpen: false,
      reminderEditModalOpen: true,
    });
  }

  handleReminderEditFormClose(reminder) {
    this.setState({
      reminderForEdit: undefined,
      reminderEditModalOpen: false,
    });
    this.props.getReminders({});
  }

  handleChangeAlsoDone(value, checked, e) {
    this.props.getReminders({ alsoDone: checked, activePage: 1 });
    this.props.changeRemindersState({ alsoDone: checked, activePage: 1 });
  }

  handleChangeDateBefore(date) {
    this.props.getReminders({ dateBefore: moment(date), activePage: 1 });
    this.props.changeRemindersState({
      dateBefore: moment(date),
      activePage: 1,
    });
  }

  handleChangeOnlyUser(val) {
    this.props.getReminders({ onlyUser: val, activePage: 1 });
    this.props.changeRemindersState({
      onlyUser: val,
      activePage: 1,
    });
  }

  handleSelectPage(eventKey) {
    this.props.getReminders({ activePage: eventKey });
    this.props.changeRemindersState({ activePage: eventKey });
  }

  render() {
    const {
      isLoading,
      reminders,
      dateBefore,
      alsoDone,
      count,
      perPage,
      activePage,
    } = this.props.reminders;
    const {
      reminderViewModalOpen,
      reminderForView,
      reminderEditModalOpen,
      reminderForEdit,
    } = this.state;
    return (
      <Panel bodyFill={true}>
        <h1>Przypomnienia o kontaktach</h1>
        <Divider />
        <Fragment>
          <div>Wyświetl przypomnienia</div>
          <Grid fluid>
            <Row className="show-grid">
              <Col xs={8}>
                <div style={labelFieldStyles}>do daty</div>
                <DatePicker
                  locale={Locale}
                  block={true}
                  cleanable={false}
                  format="DD-MM-YYYY"
                  value={dateBefore}
                  onChange={this.handleChangeDateBefore}
                  isoWeek={true}
                />
              </Col>
              <Col xs={8}>
                {this.props.auth.user.is_staff ? (
                  <>
                    <div style={labelFieldStyles}>użytkownika</div>
                    <SelectPicker
                      block={true}
                      data={this.props.auth.userList}
                      labelKey={"username"}
                      valueKey={"id"}
                      defaultValue={this.props.auth.user.id}
                      value={this.props.reminders.onlyUser}
                      onChange={this.handleChangeOnlyUser}
                    />
                  </>
                ) : null}
              </Col>
              <Col xs={8}>
                <div style={labelFieldStyles}>także wykonane</div>
                <Checkbox
                  checked={alsoDone}
                  onChange={this.handleChangeAlsoDone}
                />
              </Col>
            </Row>
          </Grid>
          <Divider />
          {isLoading ? (
            <Loader content="Wczytuję dane kontaktów z placówkami..." />
          ) : reminders && reminders.length > 0 ? (
            <Fragment>
              <Pagination
                first
                prev
                ellipsis
                next
                last
                maxButtons={20}
                size="sm"
                pages={Math.ceil(count / perPage)}
                activePage={activePage}
                onSelect={this.handleSelectPage}
              />
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Data przypomnienia</th>
                    <th>Nazwa placówki</th>
                    <th>Miejscowość</th>
                    <th>Autor</th>
                  </tr>
                </thead>
                <tbody>
                  {reminders.map((reminder) => (
                    <tr key={reminder.id} style={reminder.style}>
                      <td>{reminder.id}</td>
                      <td>
                        <a
                          style={{ color: "black" }}
                          href="/"
                          appearance="link"
                          onClick={(e) =>
                            this.handleOnReminderClick(e, reminder)
                          }
                        >
                          {moment(
                            reminder.date +
                              " " +
                              (reminder.time === null
                                ? "00:00:00"
                                : reminder.time),
                            "YYYY-MM-DD HH:mm:ss"
                          ).format(
                            "[dnia: ] DD-MM-YYYY [ o godzinie: ] HH:mm:ss"
                          )}
                        </a>
                      </td>
                      <td>
                        {reminder.instytucja_info.profil !== null &&
                        reminder.instytucja_info.profil.nazwa.length > 0
                          ? reminder.instytucja_info.profil.nazwa
                          : reminder.instytucja_info.nazwa}
                      </td>
                      <td>
                        {reminder.instytucja_info.profil !== null &&
                        reminder.instytucja_info.profil.miejscowosc.length > 0
                          ? reminder.instytucja_info.profil.miejscowosc
                          : reminder.instytucja_info.miejscowosc}
                      </td>
                      <td>{reminder.username ? reminder.username : null}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination
                first
                prev
                ellipsis
                next
                last
                maxButtons={20}
                size="sm"
                pages={Math.ceil(count / perPage)}
                activePage={activePage}
                onSelect={this.handleSelectPage}
              />
            </Fragment>
          ) : (
            <div>Brak przypomnień w wynikach wyszukiwania</div>
          )}
        </Fragment>
        <Modal
          full={false}
          backdrop={true}
          autoFocus={true}
          enforceFocus={true}
          overflow={true}
          show={reminderViewModalOpen}
        >
          <ReminderView
            reminder={reminderForView}
            onReminderViewClose={this.handleReminderViewClose}
            onReminderEditClick={this.handleReminderEditClick}
            history={this.props.history}
          />
        </Modal>
        <Modal
          full={false}
          backdrop={true}
          autoFocus={true}
          enforceFocus={true}
          overflow={true}
          show={reminderEditModalOpen}
        >
          <ReminderEditForm
            reminder={reminderForEdit}
            onReminderEditFormClose={this.handleReminderEditFormClose}
            history={this.props.history}
          />
        </Modal>
      </Panel>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  reminders: state.reminders,
  eventStatusesDict: state.events.eventStatusesDict,
});

export default connect(mapStateToProps, { getReminders, changeRemindersState })(
  RemindersWithState
);
