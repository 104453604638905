import React, { Component } from "react";
import axios from "axios";
import { endpoint } from "../config";
import { connect } from "react-redux";
import { getEventStatuses } from "../actions/events";
import {
  Grid,
  Row,
  Col,
  Timeline,
  Icon,
  Input,
  SelectPicker,
  ButtonToolbar,
  Button
} from "rsuite";
import moment from "moment";

const labelFieldStyles = {
  fontSize: 12,
  color: " #aaa",
  marginTop: 10,
  marginBottom: 10,
  textDecoration: "underline"
};

class EventStatusHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventId: undefined,
      eventStatusesData: [],
      eventStatusChanges: [],
      newStatusId: undefined,
      newStatusNotes: undefined
    };

    this.handleChangeStatusSelector = this.handleChangeStatusSelector.bind(
      this
    );
    this.handleChangeStatusNotes = this.handleChangeStatusNotes.bind(this);
    this.handleNewStatusSave = this.handleNewStatusSave.bind(this);
  }

  componentDidMount() {
    this.setState({ eventId: this.props.eventId });

    if (this.props.eventStatuses.length === 0) {
      this.props.getEventStatuses();
    }
    this.eventStatusesPropsToState();
    this.getEventStatusChanges(this.props.eventId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.eventStatuses.length > prevProps.eventStatuses.length) {
      this.eventStatusesPropsToState();
    }
  }

  eventStatusesPropsToState() {
    const eventStatusesData = this.props.eventStatuses.map(eventStatus => ({
      label: eventStatus.name,
      value: eventStatus.id,
      color: eventStatus.color,
      backgroundColor: eventStatus.bgcolor
    }));
    this.setState({ eventStatusesData });
  }

  getEventStatusChanges(event = this.state.eventId) {
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .get(
        endpoint +
          "/events/statuschange/?event=" +
          event +
          "&ordering=-created",
        config
      )
      .then(res => {
        this.setState({ eventStatusChanges: res.data.results });
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleChangeStatusSelector(newStatusId) {
    this.setState({ newStatusId });
  }

  handleChangeStatusNotes(e) {
    this.setState({ newStatusNotes: e.target.value });
  }

  handleNewStatusSave() {
    if (this.state.eventId && this.state.newStatusId) {
      const { eventId, newStatusId, newStatusNotes } = this.state;
      let credata = {
        event: eventId,
        status: newStatusId,
        notes: newStatusNotes,
        active: true
      };
      const token = this.props.auth.token;
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      if (token) {
        config.headers["Authorization"] = `Token ${token}`;
      }
      axios
        .post(endpoint + "/events/statuschangec/", credata, config)
        .then(res => {
          this.getEventStatusChanges();
          if (typeof this.props.forceLoadEvents === "function") {
            this.props.forceLoadEvents();
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  render() {
    const {
      eventStatusChanges,
      eventStatusesData,
      newStatusId,
      newStatusNotes
    } = this.state;
    const { eventStatusesDict } = this.props;
    if (eventStatusesData && eventStatusesData.length > 0) {
      return (
        <Grid fluid>
          <Row className="show-grid">
            <Col xs={24} md={12}>
              {eventStatusChanges.length === 0 ? (
                <div> Brak statusów wydarzenia w historii... </div>
              ) : (
                <Timeline style={{ marginTop: 25 }}>
                  {eventStatusChanges.map(esc => (
                    <Timeline.Item
                      key={esc.id}
                      dot={
                        <Icon
                          icon="toggle-right"
                          size="lg"
                          style={{
                            color: eventStatusesDict
                              ? eventStatusesDict[esc.status].color
                              : "#000000"
                          }}
                        />
                      }
                    >
                      <div style={{ marginLeft: 10 }}>
                        <p>
                          {moment(esc.created).format(
                            "[dnia: ] DD-MM-YYYY [ o godzinie: ] HH:mm:ss"
                          )}
                        </p>
                        <div
                          style={{
                            backgroundColor: eventStatusesDict
                              ? eventStatusesDict[esc.status].bgcolor
                              : "#ffffff"
                          }}
                        >
                          {eventStatusesDict
                            ? eventStatusesDict[esc.status].name
                            : "nieznany status..."}
                        </div>
                        <p>{esc.notes}</p>
                      </div>
                    </Timeline.Item>
                  ))}
                </Timeline>
              )}
            </Col>
            <Col xs={24} md={12}>
              <h5>Zmień status wydarzenia:</h5>
              <div style={labelFieldStyles}>Status wydarzenia</div>
              <SelectPicker
                size={250}
                searchable={false}
                data={eventStatusesData}
                value={newStatusId}
                placeholder="Wybierz status wydarzenia"
                onChange={this.handleChangeStatusSelector}
              />
              <div style={labelFieldStyles}>Notatki</div>
              <Input
                componentClass="textarea"
                style={{ width: 400 }}
                rows={6}
                name="notes"
                defaultValue={newStatusNotes}
                placeholder="Dodaj notatkę..."
                onBlur={this.handleChangeStatusNotes}
              />
              <div style={{ marginTop: 10, textAlign: "right" }}>
                <ButtonToolbar>
                  <Button
                    appearance="primary"
                    onClick={this.handleNewStatusSave}
                  >
                    Zmień status wydarzenia
                  </Button>
                </ButtonToolbar>
              </div>
            </Col>
          </Row>
        </Grid>
      );
    } else {
      return <div>Problem danych...</div>;
    }
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  eventStatuses: state.events.eventStatuses,
  eventStatusesDict: state.events.eventStatusesDict
});

export default connect(
  mapStateToProps,
  { getEventStatuses }
)(EventStatusHistory);
