import moment from "moment";
import { EVTMGRPAR_INIT_STATE, EVTMGRPAR_CHANGE_STATE } from "../actions/types";

const initialState = {
  instState: {},
};

const instInitEvtMgrParState = {
  dateRange: [moment().add(1, "days"), moment().add(1, "years")],
  teams: [],
  timeFrom: moment("2020-01-01 08:00"),
  timeTo: moment("2020-01-01 18:00"),
  eventDuration: 60,
  weekDays: [0, 1, 2, 3, 4],
  acts: [],
  suggestedActsYearsBack: 2,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EVTMGRPAR_INIT_STATE:
      let initParState = {};
      initParState[action.payload.instId] = instInitEvtMgrParState;
      return {
        ...state,
        instState: {
          ...state.instState,
          ...initParState,
        },
      };
    case EVTMGRPAR_CHANGE_STATE:
      let instParState = {};
      instParState[action.payload.instId] = {
        ...state.instState[action.payload.instId],
        ...action.payload.parState
      };
      return {
        ...state,
        instState: {
          ...state.instState,
          ...instParState,
        },
      };
    default:
      return state;
  }
}
