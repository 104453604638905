import React, { Component } from "react";
import { connect } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { endpoint } from "../config";
import axios from "axios";
import { getTeams } from "../actions/teams";
import WeekActs from "./WeekActs";
import EventDetailForm from "./EventDetailForm";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Grid,
  Loader,
  Modal,
  Popover,
  Row,
  SelectPicker,
  Tag,
  Whisper,
} from "rsuite";
import { Locale } from "../locale";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const messages = {
  allDay: "cały dzień",
  previous: "poprzedni",
  next: "następny",
  today: "dzisiaj",
  month: "miesiąc",
  week: "tydzień",
  day: "dzień",
  agenda: "agenda",
  date: "data",
  time: "czas",
  event: "wydarzenie",
  showMore: (total) => `+ pokaż kolejne  ${total}`,
};

const labelFieldStyles = {
  fontSize: 12,
  color: " #aaa",
  marginTop: 10,
  marginBottom: 10,
  textDecoration: "underline",
};

class Timetable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actTitles: {},
      instytucja: null,
      isLoading: false,
      date: this.props.eventForHarmonogram
        ? new Date(this.props.eventForHarmonogram.startdate)
        : new Date(),
      datesForNewEvent: [
        moment().toDate(),
        moment().add(60, "minutes").toDate(),
      ],
      editingEvent: null,
      events: [],
      eventForHarmonogram: undefined,
      team: this.props.eventForHarmonogram
        ? this.props.eventForHarmonogram.team
        : this.props.auth.user.teams.length > 0
        ? this.props.auth.user.teams[0]
        : this.props.auth.user.is_staff
        ? 1
        : 0,
      teamsData: [],
      view: "week",
      weekActs: null,
      addEventModalOpen: false,
      editEventModalOpen: false,
    };

    this.handleOnNavigate = this.handleOnNavigate.bind(this);
    this.handleOnViewChange = this.handleOnViewChange.bind(this);
    this.handleChangeTeamSelector = this.handleChangeTeamSelector.bind(this);
    this.handleCurrentDateChangeBySuite = this.handleCurrentDateChangeBySuite.bind(
      this
    );
    this.handleSelectSlot = this.handleSelectSlot.bind(this);
    this.handleChangeDatesForNewEvent = this.handleChangeDatesForNewEvent.bind(
      this
    );
    this.handleAddNewEvent = this.handleAddNewEvent.bind(this);
    this.handleSelectEvent = this.handleSelectEvent.bind(this);
    this.handleEditEventModalClose = this.handleEditEventModalClose.bind(this);
    this.updateEvent = this.updateEvent.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
    this.handleEventResize = this.handleEventResize.bind(this);
    this.handleEventDrop = this.handleEventDrop.bind(this);
  }

  componentDidMount() {
    this.teamsPropsToState();
    if (this.props.eventForHarmonogram) {
      this.setState({
        eventForHarmonogram: this.props.eventForHarmonogram,
      });
      this.props.handleEventForHarmonogram(undefined);
    }
    if (this.props.instytucja) {
      this.setState({ instytucja: this.props.instytucja });
    }
    this.loadEvents(this.state.date, this.state.team);
    this.loadWeekActs();
    if (this.props.teams.length === 0) {
      this.props.getTeams();
    }
    let actTitles = {};
    this.props.acts.map((act) => (actTitles[act.id] = act.title));
    this.setState({ actTitles });
  }

  teamsPropsToState() {
    let teamsData = this.props.teams
      .filter(
        (team) =>
          this.props.auth.user.is_staff ||
          this.props.auth.user.teams.includes(team.id)
      )
      .map((team) => ({
        label: team.name,
        value: team.id,
      }));
    if (this.props.auth.user.is_staff) {
      teamsData.push({ label: "wszystkie", value: 999 });
    }
    this.setState({ teamsData });
  }

  loadEvents(
    currentDate = this.state.date,
    team = this.state.team,
    view = this.state.view
  ) {
    let team_string = "";
    if (team >= 0) {
      team_string = "&team=" + team;
    }
    let view_string = "&view=" + view;
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    this.setState({ isLoading: true });
    axios
      .get(
        endpoint +
          "/events/event/?current_date=" +
          currentDate.getTime() +
          team_string +
          view_string +
          "&limit=1000",
        config
      )
      .then((res) => {
        const newdata = res.data.results;
        newdata.forEach(function (e) {
          e["start"] = moment(e.startdate).toDate();
          e["end"] = moment(e.enddate).toDate();
          e["title"] =
            (e.instytucja_info.profil && e.instytucja_info.profil.nazwa) ||
            e.instytucja_info.nazwa;
          delete e["startdate"];
          delete e["enddate"];
        });
        this.setState({ events: newdata, isLoading: false });
        console.log(newdata);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("Loading events for: ", currentDate);
  }

  loadWeekActs(
    year = this.state.date.getFullYear(),
    week = moment(this.state.date).week(),
    team = this.state.team
  ) {
    let year_string = "?year=" + year;
    let week_string = "&week=" + week;
    let team_string = "";
    if (team > 0) {
      team_string = "&team=" + team;
    }
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .get(
        endpoint +
          "/weekacts/" +
          year_string +
          week_string +
          team_string +
          "&limit=1000",
        config
      )
      .then((res) => {
        if (res.data.count > 0) {
          this.setState({ weekActs: res.data.results[0] });
        } else {
          this.setState({ weekActs: null });
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  addNewEvent() {
    let data = {};
    data["instytucja"] = this.props.instytucja;
    data["team"] = this.state.team || 1;
    data["startdate"] = this.state.datesForNewEvent[0];
    data["enddate"] = this.state.datesForNewEvent[1];
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .post(endpoint + "/events/eventc/", data, config)
      .then((res) => {
        console.log("Appointment created");
        console.log(res.data);
        this.loadEvents(this.state.date, this.state.team);
        if (this.props.resetEventHistoryPanel) {
          this.props.resetEventHistoryPanel();
        }
        Alert.success("Poprawnie utworzono nowe wydarzenie");
      })
      .catch((err) => {
        console.log(err);
        Alert.error("Błąd utworzenia nowego wydarzenia");
      });
  }

  updateEvent(eventData) {
    let data = eventData;
    if (data["start"]) {
      data["startdate"] = data["start"];
      delete data["start"];
    }
    if (data["end"]) {
      data["enddate"] = data["end"];
      delete data["end"];
    }
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .put(endpoint + "/events/eventu/" + data.id + "/", data, config)
      .then((res) => {
        this.loadEvents(this.state.date, this.state.team);
        this.handleEditEventModalClose();
        if (this.props.resetEventHistoryPanel) {
          this.props.resetEventHistoryPanel();
        }
        Alert.success("Poprawnie zapisano dane wydarzenia");
        console.log("Appointment updated");
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        Alert.error("Błąd zapisu danych wydarzenia");
      });
  }

  deleteEvent(eventId) {
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .delete(endpoint + "/events/eventu/" + eventId + "/", config)
      .then((res) => {
        Alert.success("Poprawnie usunięto wydarzenie");
        console.log("Event deleted");
        console.log(res.data);
        this.loadEvents(this.state.date, this.state.team);
        this.handleEditEventModalClose();
        if (this.props.resetEventHistoryPanel) {
          this.props.resetEventHistoryPanel();
        }
      })
      .catch((err) => {
        Alert.error("Błąd usunięcia wydarzenia");
        console.log(err);
      });
  }

  handleChangeTeamSelector(val) {
    this.setState({ team: val });
    this.loadEvents(this.state.date, val);
    this.loadWeekActs(
      this.state.date.getFullYear(),
      moment(this.state.date).week(),
      val
    );
  }

  handleCurrentDateChangeBySuite(currentDate) {
    this.setState({ date: currentDate });
    this.loadEvents(currentDate, this.state.team);
    this.loadWeekActs(
      currentDate.getFullYear(),
      moment(currentDate).week(),
      this.state.team
    );
  }

  handleOnNavigate(date, view) {
    this.setState({ date: date });
    if (!(moment(this.state.date).week() === moment(date).week())) {
      this.loadEvents(date, this.state.team, view);
    }
    this.loadWeekActs(date.getFullYear(), moment(date).week(), this.state.team);
  }

  handleOnViewChange(view) {
    const { date, team } = this.state;
    this.setState({ view: view });
    if (view === "month") {
      this.loadEvents(date, team, view);
    }
    this.loadWeekActs(date.getFullYear(), moment(date).week(), team);
  }

  handleSelectSlot({ start, end }) {
    console.log(start);
    if (!this.props.instytucja) {
      Alert.warning(
        "Aby dodać wydarzenie przejdź do terminarza wybranej placówki"
      );
    } else {
      this.setState({
        datesForNewEvent: [moment(start).toDate(), moment(end).toDate()],
        addEventModalOpen: true,
      });
    }
  }

  handleChangeDatesForNewEvent(date, evt) {
    if (evt === "start") {
      if (moment(date) < moment(this.state.datesForNewEvent[1])) {
        this.setState({
          datesForNewEvent: [date, this.state.datesForNewEvent[1]],
        });
      } else {
        Alert.error("Błąd wyboru czasu wydarzenia");
      }
    } else if (evt === "end") {
      if (moment(date) > moment(this.state.datesForNewEvent[0])) {
        this.setState({
          datesForNewEvent: [this.state.datesForNewEvent[0], date],
        });
      } else {
        Alert.error("Błąd wyboru czasu wydarzenia");
      }
    }
  }

  handleAddNewEvent() {
    this.addNewEvent();
    this.setState({ addEventModalOpen: false });
  }

  handleSelectEvent(eevent, evt) {
    this.setState({ editingEvent: eevent, editEventModalOpen: true });
  }

  handleEditEventModalClose() {
    this.setState({ editEventModalOpen: false, editingEvent: null });
  }

  handleEventResize({ event, start, end }) {
    let newEvent = event;
    newEvent.start = start;
    newEvent.end = end;
    this.updateEvent(newEvent);
    console.log(event, start, end);
  }

  handleEventDrop({ event, start, end }) {
    let newEvent = event;
    newEvent.start = start;
    newEvent.end = end;
    this.updateEvent(newEvent);
    console.log(event, start, end);
  }

  render() {
    const {
      addEventModalOpen,
      date,
      datesForNewEvent,
      editEventModalOpen,
      editingEvent,
      events,
      isLoading,
      team,
      teamsData,
      view,
      weekActs,
    } = this.state;

    const eventPropGetter = (event, start, end, isSelected) => {
      let newStyle = {
        backgroundColor: "lightgrey",
        color: "white",
        borderRadius: "10px",
        border: "1px solid black",
      };
      newStyle.backgroundColor = this.props.eventStatusesDict
        ? this.props.eventStatusesDict[
            event.eventstatuschange_set.length > 0
              ? event.eventstatuschange_set[0].status
              : 1
          ].color
        : "#FFC107";

      newStyle.border =
        this.state.eventForHarmonogram &&
        event.id === this.state.eventForHarmonogram.id
          ? "3px dashed red"
          : "1px solid black";
      return {
        style: newStyle,
        className: "eventbox",
      };
    };

    const EventPopover = ({ event, ...props }) => {
      const inst = event.event.instytucja_info;
      return (
        <Popover {...props}>
          <div style={{ width: 300 }}>
            <h4>{(inst.profil && inst.profil.nazwa) || inst.nazwa}</h4>
            <div>
              {event.event.acts?.map((act) => (
                <div key={act}>{this.state.actTitles[act]}</div>
              ))}
            </div>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            {event.event.notes?.length > 0 ? event.event.notes : "brak notatki"}
          </div>
        </Popover>
      );
    };

    const InstytucjaAddress = (props) => {
      const inst = props.event.event.instytucja_info;
      const address =
        (inst?.profil && inst?.profil?.ulica) ||
        (inst?.ulica || "") + (inst?.numerbud || "");
      return (
        <div>
          {(inst?.profil && inst?.profil?.miejscowosc.toUpperCase()) ||
            inst?.miejscowosc.toUpperCase()}
          <br />
          {(inst?.profil && inst?.profil?.kodpocz) || inst?.kodpocz}
          <br />
          {address}
        </div>
      );
    };

    const EventBox = (event) => {
      return (
        <Whisper
          trigger="hover"
          placement="top"
          speaker={<EventPopover event={event} />}
        >
          <div>
            <InstytucjaAddress event={event} />
          </div>
        </Whisper>
      );
    };

    return (
      <div>
        <div style={{ marginBottom: 20 }}>
          {" "}
          <span style={{ fontSize: 16 }}>Pokaż harmonogram dla</span>
          <SelectPicker
            cleanable={false}
            searchable={false}
            size={300}
            data={teamsData}
            value={team}
            onChange={this.handleChangeTeamSelector}
            style={{ marginLeft: 15, marginTop: 10 }}
          />
          <DatePicker
            locale={Locale}
            format="DD-MM-YYYY"
            isoWeek={true}
            ranges={[]}
            cleanable={false}
            defaultValue={date}
            value={date}
            onOk={this.handleCurrentDateChangeBySuite}
            style={{ marginLeft: 15, marginTop: 10 }}
          />
          {view === "week" ? (
            <Tag style={{ marginLeft: 20, marginTop: 20 }}>
              Tydzień: {view === "week" ? moment(date).week() : null}
            </Tag>
          ) : null}
          <span style={{ marginLeft: 20, marginTop: 20 }}>
            {isLoading ? <Loader content="Wczytuję dane wydarzeń..." /> : null}
          </span>
          {weekActs !== null && view === "week" ? (
            <WeekActs weekActs={weekActs} />
          ) : null}
        </div>
        <DnDCalendar
          components={{ event: EventBox }}
          date={date}
          defaultDate={moment().toDate()}
          defaultView="week"
          events={events}
          eventPropGetter={eventPropGetter}
          localizer={localizer}
          step={15}
          messages={messages}
          min={new Date(0, 0, 0, 7, 0, 0)}
          max={new Date(0, 0, 0, 20, 0, 0)}
          onNavigate={this.handleOnNavigate}
          onView={this.handleOnViewChange}
          onSelectSlot={this.handleSelectSlot}
          onSelectEvent={this.handleSelectEvent}
          onEventResize={this.handleEventResize}
          onEventDrop={this.handleEventDrop}
          resizable
          selectable
          style={{ height: "100vh", maxWidth: "80vw" }}
          tooltipAccessor={null}
          views={["day", "week", "month"]}
        />
        <Modal backdrop={true} autoFocus={true} show={addEventModalOpen}>
          <Modal.Header closeButton={false}>
            <Modal.Title>Dodaj wydarzenie</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid fluid>
              <Row>
                <Col xs={24}>
                  <div style={labelFieldStyles}>dla placówki</div>
                  {this.props.instytucja_info?.profil?.nazwa.length > 0
                    ? this.props.instytucja_info?.profil?.nazwa
                    : this.props.instytucja_info?.nazwa}
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <div style={labelFieldStyles}>w godzinach</div>
                  od:{" "}
                  <DatePicker
                    cleanable={false}
                    locale={Locale}
                    format="HH:mm"
                    value={datesForNewEvent[0]}
                    onOk={(date) =>
                      this.handleChangeDatesForNewEvent(date, "start")
                    }
                    hideHours={(hour) => hour < 7 || hour > 20}
                    hideMinutes={(minute) => minute % 5 !== 0}
                  />
                  do:{" "}
                  <DatePicker
                    cleanable={false}
                    locale={Locale}
                    format="HH:mm"
                    value={datesForNewEvent[1]}
                    onOk={(date) =>
                      this.handleChangeDatesForNewEvent(date, "end")
                    }
                    hideHours={(hour) => hour < 7 || hour > 20}
                    hideMinutes={(minute) => minute % 5 !== 0}
                  />
                  {"  "} dnia{" "}
                  {moment(datesForNewEvent[0]).format(" D MMMM (dddd) YYYY ")}{" "}
                  roku.
                </Col>
              </Row>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setState({ addEventModalOpen: false })}>
              Anuluj
            </Button>
            <Button appearance="primary" onClick={this.handleAddNewEvent}>
              Dodaj
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          full={true}
          backdrop={true}
          autoFocus={true}
          enforceFocus={true}
          overflow={true}
          show={editEventModalOpen}
        >
          <Modal.Header closeButton={false}>
            <Modal.Title>
              Edycja szczegółów wydarzenia ({editingEvent?.id})
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EventDetailForm
              eventData={editingEvent}
              onEditEventModalClose={this.handleEditEventModalClose}
              onEventUpdate={this.updateEvent}
              onEventDelete={this.deleteEvent}
              onChangeTeam={this.handleChangeTeamSelector}
              forceLoadEvents={this.loadEvents}
              changeTab={this.props.changeTab}
              weekActs={weekActs}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  acts: state.acts.acts,
  teams: state.teams.teams,
  eventStatusesDict: state.events.eventStatusesDict,
});

export default connect(mapStateToProps, { getTeams })(Timetable);
