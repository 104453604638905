import axios from "axios";
import {
  REMINDERS_LOADING,
  REMINDERS_LOAD_SUCCESS,
  REMINDERS_LOAD_ERROR,
  CHANGE_REMINDERS_STATE,
} from "./types";
import { returnErrors } from "./messages";
import { endpoint } from "../config";
import moment from "moment";

export const getReminders = (params={}) => (dispatch, getState) => {
  const {
    perPage,
    activePage,
    dateBefore,
    alsoDone,
    onlyUser
  } = getState().reminders;
  const _perPage = (params.perPage !== undefined) ? params.perPage : perPage;
  const _activePage = (params.activePage !== undefined) ? params.activePage : activePage;
  const offset = _perPage * (_activePage - 1);
  const _dateBefore = (params.dateBefore !== undefined) ? params.dateBefore : dateBefore;
  const _alsoDone = (params.alsoDone !== undefined) ? params.alsoDone : alsoDone;
  const _onlyUser = (params.onlyUser !== undefined) ? params.onlyUser : onlyUser;
  let get_params = "?limit=" + _perPage;
  get_params += "&offset=" + offset;
  get_params += "&date_before=" + moment(_dateBefore).format("YYYY-MM-DD");
  if(!_alsoDone) {
    get_params += "&done=" + _alsoDone;
  }
  if(_onlyUser > 0) {
    get_params += "&user=" + _onlyUser;
  }
  dispatch({ type: REMINDERS_LOADING });
  const token = getState().auth.token;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }
  axios
    .get(endpoint + "/reminders/viewwithinstytucja/" + get_params, config)
    .then((res) => {
      let reminders = res.data.results;
      if (res.data.count > 0) {
        let endofday = moment().endOf("day");
        let nowdate = moment();
        const eventStatusesDict = getState().events.eventStatusesDict;
        reminders.forEach((r) => {
          let rmoment = moment(
            r.date + " " + (r.time === null ? "00:00:00" : r.time),
            "YYYY-MM-DD HH:mm:ss"
          );
          if (r.done) {
            r["style"] = { backgroundColor: "#ffffff" };
          } else {
            if (rmoment > endofday) {
              r["style"] = {
                backgroundColor: eventStatusesDict[1].bgcolor,
              };
            } else if (rmoment <= endofday && rmoment >= nowdate) {
              r["style"] = {
                backgroundColor: eventStatusesDict[2].bgcolor,
              };
            } else {
              r["style"] = {
                backgroundColor: eventStatusesDict[3].bgcolor,
              };
            }
          }
        });
      }
      dispatch({
        type: REMINDERS_LOAD_SUCCESS,
        payload: { reminders: reminders, count: res.data.count },
      });
    })
    .catch((err) => {
      console.log(err);
      if (err.response) {
        dispatch(returnErrors(err.response.data, err.response.status));
      } else {
        dispatch(returnErrors(err.message, -1));
      }
      dispatch({
        type: REMINDERS_LOAD_ERROR,
      });
    });
};

export const changeRemindersState = (remindersState) => (dispatch) => {
  dispatch({
    type: CHANGE_REMINDERS_STATE,
    payload: remindersState,
  });
};
