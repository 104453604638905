import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { endpoint } from "../config";
import { changeHprinterState } from "../actions/hprinter";
import { getTeams } from "../actions/teams";
import {
  Panel,
  Loader,
  Divider,
  Grid,
  Row,
  Col,
  DateRangePicker,
  SelectPicker,
  Button,
} from "rsuite";
import moment from "moment";
import "moment/locale/pl";
import ReactMarkdown from "react-markdown";
import ReactToPrint from "react-to-print";

const labelFieldStyles = {
  fontSize: 12,
  color: " #aaa",
  marginTop: 10,
  marginBottom: 10,
  textDecoration: "underline",
};

function InstytucjaInfo(props) {
  const inst = props.instytucja_info;
  const address =
    (inst.profil && inst.profil.ulica) ||
    (inst.ulica || "") + (inst.numerbud || "");
  const telefon =
    (inst.profil && inst.profil.telefon.replace(/<<.*>>/g, "")) || "";
  return (
    <div style={{ textAlign: "center" }}>
      <h4
        onClick={() => props.history.push("/admin/instytucje/" + inst.id)}
        style={{ fontWeight: "bold", fontSize: 24 }}
      >
        {(inst.profil && inst.profil.nazwa) || inst.nazwa}
      </h4>
      <div style={{ fontSize: 24, marginTop: "1em", marginBottom: "1em" }}>
        {address} <br />
        {(inst.profil && inst.profil.kodpocz) || inst.kodpocz} {"  "}
        {(inst.profil && inst.profil.miejscowosc) || inst.miejscowosc} <br />
        <ReactMarkdown source={inst.profil !== null ? telefon : inst.telefon} />
      </div>
    </div>
  );
}

const Event = (props) => {
  const event = props.event;
  const actTitles = props.actTitles;
  return (
    <div
      style={{
        border: "1px solid black",
        marginTop: 2,
        display: "flex",
        flexWrap: "wrap",
        pageBreakInside: "avoid",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "40%",
        }}
      >
        <div
          style={{
            padding: 5,
            fontSize: 24,
            borderRight: "1px solid black",
            width: "100%",
          }}
        >
          <InstytucjaInfo instytucja_info={event.instytucja_info} {...props} />
        </div>
      </div>
      <div style={{ display: "flex", width: "20%" }}>
        <div
          style={{
            padding: 5,
            fontSize: 24,
            height: "100%",
            width: "100%",
            textAlign: "center",
          }}
        >
          {moment(event.startdate).format("HH:mm")}
          {" - "}
          {moment(event.enddate).format("HH:mm")}
          <Divider />
          <div style={{ fontWeight: "bold" }}>
            {event.payfrom}{" "}
            {parseFloat(event.payto) !== 0 ? " - " + event.payto : ""}
            {" zł"}
          </div>
        </div>
      </div>
      <div style={{ display: "flex", width: "40%" }}>
        <div
          style={{
            padding: 5,
            fontSize: 24,
            borderLeft: "1px solid black",
            width: "100%",
          }}
        >
          {event.acts.map((actId) => (
            <div key={actId} style={{ fontWeight: "bold" }}>
              {actTitles[actId]}
            </div>
          ))}
          <Divider />
          <div style={{ textAlign: "justify" }}>
            <ReactMarkdown style={{ fontSize: 24 }} source={event.notes} />
          </div>
        </div>
      </div>
    </div>
  );
};

const EventDay = (props) => {
  return (
    <Grid
      fluid
      style={{
        border: "2px solid black",
        margin: 20,
        padding: 5,
        color: "#000000",
        fontFamily: "Times New Roman",
        pageBreakBefore: "always",
      }}
    >
      <Row className="show-grid">
        <Col xs={24} style={{ fontSize: 24, fontWeight: "bold", padding: 5 }}>
          {props.eventDay.day}
        </Col>
        {props.eventDay.events.map((event) => (
          <Col xs={24} key={event.id}>
            <Event event={event} actTitles={props.actTitles} {...props} />
          </Col>
        ))}
      </Row>
    </Grid>
  );
};

class ToPrintContainer extends Component {
  render() {
    return { ...this.props.children };
  }
}

class HarmonogramPrinter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      dateRange: [moment().startOf("day"), moment().endOf("day")],
      team: this.props.eventForHarmonogram
        ? this.props.eventForHarmonogram.team
        : this.props.auth.user.teams.length > 0
        ? this.props.auth.user.teams[0]
        : this.props.auth.user.is_staff
        ? 1
        : 0,
      teamsData: [],
      eventDays: {},
      actTitles: {},
    };
    this.handleChangeDateRange = this.handleChangeDateRange.bind(this);
    this.handleChangeTeamSelector = this.handleChangeTeamSelector.bind(this);
    this.updateHprinterState = this.updateHprinterState.bind(this);
  }

  componentDidMount() {
    this.setState(this.props.hprinterState, this.loadEvents);
    let actTitles = {};
    this.props.acts.map((act) => (actTitles[act.id] = act.title));
    this.setState({ actTitles });
    this.teamsPropsToState();
  }

  eventsToDays(data) {
    let eventDays = {};
    data.forEach((event) => {
      if (event.eventstatuschange_set[0].status !== 5) {
        let date = moment(event.startdate).format("YYYY-MM-DD");
        let day = moment(event.startdate).format("DD MMMM (dddd)");
        if (!eventDays.hasOwnProperty(date)) {
          eventDays = { ...eventDays, [date]: { events: [event], day: day } };
        } else {
          eventDays = {
            ...eventDays,
            [date]: {
              ...eventDays[date],
              events: [...eventDays[date].events, event],
            },
          };
        }
      }
    });
    this.setState({ eventDays, isLoading: false });
  }

  loadEvents(dateRange = this.state.dateRange, team = this.state.team) {
    this.setState({ isLoading: true });
    const team_string = "&team=" + team;
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .get(
        endpoint +
          "/events/event/?start_date=" +
          moment(dateRange[0]).startOf("day").valueOf() +
          "&end_date=" +
          moment(dateRange[1]).endOf("day").valueOf() +
          team_string +
          "&ordering=startdate" +
          "&limit=1000",
        config
      )
      .then((res) => {
        this.eventsToDays(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  updateHprinterState(newObj = {}) {
    const hprinterState = {};
    Object.assign(hprinterState, this.props.hprinterState);
    Object.assign(hprinterState, newObj);
    this.props.changeHprinterState(hprinterState);
  }

  handleChangeDateRange(dateRange) {
    this.setState({ dateRange });
    this.loadEvents(dateRange, this.state.team);
    this.updateHprinterState({ dateRange });
  }

  handleChangeTeamSelector(val) {
    this.setState({ team: val });
    this.loadEvents(this.state.dateRange, val);
    this.updateHprinterState({ team: val });
  }

  teamsPropsToState() {
    const teamsData = this.props.teams
      .filter(
        (team) =>
          this.props.auth.user.is_staff ||
          this.props.auth.user.teams.includes(team.id)
      )
      .map((team) => ({
        label: team.name,
        value: team.id,
      }));
    this.setState({ teamsData });
  }

  render() {
    const {
      isLoading,
      dateRange,
      team,
      teamsData,
      eventDays,
      actTitles,
    } = this.state;
    return (
      <Fragment>
        <Panel>
          <h1>Harmonogram przedstawień teatralnych (wersja do druku)</h1>
        </Panel>
        <Divider />
        <Panel defaultExpanded>
          <div style={{ marginBottom: 10 }}>
            Określ zakres danych harmonogramu
          </div>
          <Grid fluid>
            <Row className="show-grid">
              <Col xs={12}>
                <div style={labelFieldStyles}>Czasokres</div>
                <DateRangePicker
                  block={true}
                  cleanable={false}
                  format="DD-MM-YYYY"
                  value={dateRange}
                  onChange={this.handleChangeDateRange}
                  isoWeek={true}
                />
              </Col>
              <Col xs={12}>
                <div style={labelFieldStyles}>Zespół</div>
                <SelectPicker
                  cleanable={false}
                  defaultValue={0}
                  searchable={false}
                  size={300}
                  data={teamsData}
                  value={team}
                  onChange={this.handleChangeTeamSelector}
                />
              </Col>
            </Row>
          </Grid>
          {isLoading ? (
            <Loader
              backdrop
              content="Wczytuję dane harmonogramu przedstawień teatralnych..."
              vertical
            />
          ) : eventDays ? (
            <Fragment>
              <ReactToPrint
                trigger={() => (
                  <Button appearance="primary" style={{ marginTop: 10 }}>
                    Drukuj harmonogram
                  </Button>
                )}
                content={() => this.componentRef}
              />
              <ToPrintContainer ref={(el) => (this.componentRef = el)}>
                <div>
                  {Object.keys(eventDays).map((item, i) => (
                    <EventDay
                      key={i}
                      eventDay={eventDays[item]}
                      actTitles={actTitles}
                      {...this.props}
                    />
                  ))}
                </div>
              </ToPrintContainer>
            </Fragment>
          ) : (
            true
          )}
        </Panel>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  hprinterState: state.hprinter.hprinterState,
  acts: state.acts.acts,
  teams: state.teams.teams,
});

export default connect(mapStateToProps, { getTeams, changeHprinterState })(
  HarmonogramPrinter
);
