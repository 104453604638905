import { GET_EVENT_STATUSES } from "../actions/types";

const initialState = {
  eventStatuses: [],
  eventStatusesDict: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_EVENT_STATUSES:
      let eventStatusDict = {};
      action.payload.results.forEach(function(status) {
        eventStatusDict[status.id] = status;
      });
      return {
        ...state,
        eventStatuses: action.payload.results,
        eventStatusesDict: eventStatusDict
      };
    default:
      return state;
  }
}
