import axios from "axios";
import {
  INSTYTUCJE_LOADING,
  INSTYTUCJE_LOAD_SUCCESS,
  INSTYTUCJE_LOAD_ERROR,
  CHANGE_INSTYTUCJE_STATE
} from "./types";
import { returnErrors } from "./messages";
import { endpoint } from "../config";

export const getInstytucje = (
  limit = 25,
  offset = 0,
  search = "",
  search_operator = "or",
  miejscowosc = "",
  wojewodztwo = "",
  kodpocz = "",
  zipcode_nearby = "",
  in_list = "",
  ordering = ""
) => (dispatch, getState) => {
  let params =
    "?limit=" +
    limit +
    "&offset=" +
    offset +
    "&search=" +
    search +
    "&search_operator=" +
    search_operator +
    "&miejscowosc=" +
    miejscowosc +
    "&wojewodztwo=" +
    wojewodztwo +
    "&kodpocz=" +
    kodpocz +
    "&zipcode_nearby=" +
    zipcode_nearby +
    "&in_list=" +
    in_list +
    "&ordering=" +
    ordering;
  dispatch({ type: INSTYTUCJE_LOADING });
  const token = getState().auth.token;
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }
  axios
    .get(endpoint + "/rspo/instytucja/" + params, config)
    .then(res => {
      dispatch({
        type: INSTYTUCJE_LOAD_SUCCESS,
        payload: res.data
      });
    })
    .catch(err => {
      console.log(err);
      if (err.response) {
        dispatch(returnErrors(err.response.data, err.response.status));
      } else {
        dispatch(returnErrors(err.message, -1));
      }
      dispatch({
        type: INSTYTUCJE_LOAD_ERROR
      });
    });
};

export const changeInstytucjeState = searchState => dispatch => {
  dispatch({
    type: CHANGE_INSTYTUCJE_STATE,
    payload: searchState
  });
};
