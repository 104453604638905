import { WALLET_UPDATE } from "../actions/types";

const initialState = {
  data: new Set()
};

export default function(state = initialState, action) {
  switch (action.type) {
    case WALLET_UPDATE:
      return {
        ...state,
        data: action.payload.data
      };
    default:
      return state;
  }
}
