import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getActs } from "../actions/acts";
import { getTeams } from "../actions/teams";
import { TagGroup, Tag } from "rsuite";

class WeekActs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weekActs: null,
      actTitles: null
    };
  }

  componentDidMount() {
    this.setState({ weekActs: this.props.weekActs });
    if (this.props.acts.length === 0) {
      this.props.getActs();
    }
    if (this.props.teams.length === 0) {
      this.props.getTeams();
    }
    let actTitles = {};
    this.props.acts.map(act => (actTitles[act.id] = act.title));
    this.setState({ actTitles });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.acts.length > prevProps.acts.length) {
      this.actsPropsToState();
    }
    if (this.props.teams.length > prevProps.teams.length) {
      this.teamsPropsToState();
    }
    if (
      this.props.weekActs.year !== prevProps.weekActs.year ||
      this.props.weekActs.week !== prevProps.weekActs.week ||
      this.props.weekActs.team !== prevProps.weekActs.team
    ) {
      this.setState({ weekActs: this.props.weekActs });
    }
  }

  render() {
    const { weekActs, actTitles } = this.state;
    return (
      <Fragment>
        <TagGroup style={{ marginTop: 5, marginBottom: 5 }}>
          { weekActs !== null ? weekActs.mainacts.map(act => (
            <Tag color="red" key={act}>
              {actTitles[act]}
            </Tag>
          )) : null }
          { weekActs !== null ? weekActs.resvacts.map(act => (
            <Tag color="green" key={act}>
              {actTitles[act]}
            </Tag>
          )) : null }
        </TagGroup>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  acts: state.acts.acts,
  teams: state.teams.teams
});

export default connect(mapStateToProps, { getActs, getTeams })(WeekActs);
