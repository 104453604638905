import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { endpoint } from "../config";
import {
  PanelGroup,
  Panel,
  Loader,
  Divider,
  Grid,
  Row,
  Col,
  TagGroup,
  Tag,
  SelectPicker,
  Button,
  Drawer,
  Alert,
} from "rsuite";
import Table from "react-bootstrap/Table";
import { Tabs, Tab } from "react-bootstrap-tabs";
import InstytucjeField from "./InstytucjeField";
import EventConfirmationEmailForm from "./EventConfirmationEmailForm";
import Calls from "./Calls";
import { getInstytucje, changeInstytucjeState } from "../actions/instytucje";
import WalletEmailForm from "./WalletEmailForm";
import EventManager from "./EventManager";
import _ from "lodash";
import moment from "moment";
import Timetable from "./Timetable";

const styleEventHistorySeason = {
  fontSize: 12,
  color: "#aaa",
  paddingTop: 10,
  textDecoration: "underline",
};

const styleEventHistoryItem = {
  fontSize: 16,
  paddingLeft: 15,
};

const styleEventHistoryMikolaj = {
  fontSize: 16,
  color: "#f00",
  paddingLeft: 10,
};

const labelFieldStyles = {
  fontSize: 12,
  color: " #aaa",
  marginTop: 10,
  marginBottom: 10,
  textDecoration: "underline",
};

class InstytucjeDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      tabSelected: 0,
      inst: null,
      history: null,
      actTitles: null,
      events: null,
      eventHistory: null,
      eventPreHistory: null,
      suggestedActs: null,
      suggestedActsYearsBack: 2,
      eventForHarmonogram: undefined,
      beEditable: false,
      macierzSelectorData: {},
      filieData: [],
      emailDarawerShow: false,
      walletEmailFormShow: false,
    };
    this._loadInstData = this._loadInstData.bind(this);
    this._handleFocusOut = this._handleFocusOut.bind(this);
    this._handleEventHistoryPanel = this._handleEventHistoryPanel.bind(this);
    this._handleSuggestedActsYearsBack = this._handleSuggestedActsYearsBack.bind(
      this
    );
    this._addEventsToHistory = this._addEventsToHistory.bind(this);
    this._getActSuggestions = this._getActSuggestions.bind(this);
    this._handleEventForHarmonogram = this._handleEventForHarmonogram.bind(
      this
    );
    this._resetEventHistoryPanel = this._resetEventHistoryPanel.bind(this);
    this._handleMacierzOnSearch = this._handleMacierzOnSearch.bind(this);
    this._handleMacierzOnChange = this._handleMacierzOnChange.bind(this);
    this._getInstytucjeForFilieContainer = this._getInstytucjeForFilieContainer.bind(
      this
    );
    this._handleFiliaOnClick = this._handleFiliaOnClick.bind(this);
    this._handleGoToNearby = this._handleGoToNearby.bind(this);
    this._handleEmailDrawerClose = this._handleEmailDrawerClose.bind(this);
    this.handleShowEmailFromWalletForm = this.handleShowEmailFromWalletForm.bind(
      this
    );
    this._handleChangeTab = this._handleChangeTab.bind(this);
    this._handleGoToEventInTimetable = this._handleGoToEventInTimetable.bind(
      this
    );
  }
  componentDidMount() {
    const { inst_id } = this.props.match.params;
    this._loadInstData(inst_id);
    document.body.style.overflow = "auto";
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const { inst_id } = this.props.match.params;
      this._loadInstData(inst_id);
      setTimeout(() => {
        this._resetEventHistoryPanel();
        document.body.style.overflow = "auto";
      }, 1000);
    }
  }

  _loadInstData(inst_id = this.state.inst.id) {
    this.setState({ isLoading: true });
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .get(endpoint + "/rspo/instytucja/" + inst_id, config)
      .then((res) => {
        if (
          res.data.profil !== null &&
          res.data.profil.history !== null &&
          res.data.profil.history.length > 0
        ) {
          const history = JSON.parse(res.data.profil.history);
          this.setState({ history: history });
        }
        if (res.data.profil !== null && res.data.profil.macierz !== null) {
          this._getInstytucjeForMacierzSelector(res.data.profil.macierz);
        }
        if (res.data.filie.length > 0) {
          this._getInstytucjeForFilieContainer(res.data.filie);
        }
        this.setState({
          inst: res.data,
          isLoading: false,
          isRSPO: res.data.id !== res.data.rspo - 900000000,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 403 && err.response.data) {
          Alert.error(err.response.data.status);
          this.props.history.push("/admin/instytucje");
        } else if (err.message) {
          Alert.error(err.message);
          this.props.history.push("/admin/instytucje");
        }
      });
  }

  _handleFocusOut(obj, fieldName, fieldValue) {
    if (fieldName === "maxpay" && isNaN(parseFloat(fieldValue))) {
      fieldValue = 0.0;
    }
    if (
      this.state.inst.profil === null ||
      fieldValue !== this.state.inst.profil[fieldName]
    ) {
      const prevState = this.state;
      const newState = {
        ...prevState,
        inst: {
          ...prevState.inst,
          profil: {
            ...prevState.inst.profil,
            [fieldName]: fieldValue,
          },
        },
      };

      this.setState(newState);
      const token = this.props.auth.token;
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (token) {
        config.headers["Authorization"] = `Token ${token}`;
      }
      axios
        .put(
          endpoint + "/rspo/instytucjau/" + this.state.inst.id + "/",
          newState.inst,
          config
        )
        .then((res) => {
          Alert.success("Pomyślnie zaktualizowano pole " + fieldName);
          this.setState({
            inst: res.data,
            isRSPO: res.data.id !== res.data.rspo - 900000000,
          });
          this.props.getInstytucje(
            this.props.searchState?.perPage,
            (this.props.searchState?.activePage - 1) *
              this.props.searchState?.perPage,
            this.props.searchState?.searchString,
            this.props.searchState?.searchOperator,
            this.props.searchState?.miejscowoscString,
            this.props.searchState?.wojewodztwoString,
            this.props.searchState?.kodpoczString,
            this.props.searchState?.zipCodeNearby,
            "",
            this.props.searchState?.sortedBy
          );
        })
        .catch((err) => {
          console.log(err);
          if (
            err.response &&
            err.response.status === 403 &&
            err.response.data
          ) {
            Alert.error(err.response.data.status);
            this._loadInstData();
          } else if (err.message) {
            Alert.error(err.message);
          }
        });
    }
  }

  _getEvents() {
    const { inst_id } = this.props.match.params;
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .get(
        endpoint +
          "/events/event/?instytucja=" +
          inst_id +
          "&team=999" +
          "&limit=1000" +
          "&ordering=-startdate",
        config
      )
      .then((res) => {
        if (res.data.count > 0) {
          this.setState({ events: res.data.results });
        } else {
          this.setState({ events: [] });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  _getEventPreHistory() {
    const { inst_id } = this.props.match.params;
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .get(
        endpoint +
          "/events/history/?instytucja=" +
          inst_id +
          "&limit=1000" +
          "&ordering=-season",
        config
      )
      .then((res) => {
        if (res.data.count > 0) {
          const grouped = _.groupBy(res.data.results, (event) => event.season);
          this.setState({ eventPreHistory: grouped });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  _handleEventHistoryPanel() {
    if (this.props.acts) {
      let actTitles = {};
      this.props.acts.map((act) => (actTitles[act.id] = act.title));
      this.setState({ actTitles: actTitles });
    }
    if (this.state.events === null) {
      this._getEvents();
    }
    if (this.state.eventPreHistory === null) {
      this._getEventPreHistory();
    }
    setTimeout(() => {
      if (this.state.events !== null && this.state.eventHistory === null) {
        this._addEventsToHistory(this._getActSuggestions);
      }
    }, 500);
    setTimeout(() => {
      this._getActSuggestions(this.state.suggestedActsYearsBack);
    }, 1000);
    /* Problem with setState callback functions -> setTimeout */
  }

  _resetEventHistoryPanel() {
    this.setState(
      {
        events: null,
        eventHistory: null,
        eventPreHistory: null,
        suggestedActs: null,
      },
      this._handleEventHistoryPanel
    );
  }

  _addEventsToHistory(_getActSuggestions) {
    const { eventPreHistory, events } = this.state;
    function season_str(date) {
      let d = new Date(date);
      let y = d.getFullYear();
      let ss = 0;
      if (d.getMonth() < 8) {
        ss = y - 1 + "/" + y;
      } else {
        ss = y + "/" + (y + 1);
      }
      return ss;
    }
    let newEventHistory = JSON.parse(JSON.stringify(eventPreHistory));
    if (newEventHistory === null) {
      newEventHistory = {};
    }
    events.forEach((event) => {
      let season = season_str(event.startdate);
      if (
        event.acts.length > 0 &&
        (event.eventstatuschange_set[0].status === 3 ||
          event.eventstatuschange_set[0].status === 4)
      ) {
        if (!(season in newEventHistory)) {
          newEventHistory[season] = [];
        }
        newEventHistory[season].push(event);
        event.acts.forEach((act) => {
          newEventHistory[season].push({
            act: act,
            season: season,
            created: event.created,
          });
        });
      }
    });
    this.setState({ eventHistory: newEventHistory }, _getActSuggestions());
  }

  _getActSuggestions(yearsBack = 3) {
    const { actTitles, eventHistory } = this.state;
    function season_str(yb) {
      let d = new Date();
      let y = d.getFullYear();
      let ss = 0;
      if (d.getMonth() < 8) {
        ss = y - yb - 1 + "/" + (y - yb);
      } else {
        ss = y - yb + "/" + (y - yb + 1);
      }
      return ss;
    }
    let seasons = [];
    for (let i = 0; i <= yearsBack; i++) {
      seasons[i] = season_str(i);
    }
    let suggestedActs = { 0: {}, 1: {}, 2: {} };
    let newActs = Object.assign({}, actTitles);
    if (eventHistory != null) {
      for (let [s, o] of Object.entries(eventHistory)) {
        if (seasons.includes(s)) {
          for (const obj of o) {
            let na = {};
            na[obj.act] = actTitles[obj.act];
            Object.assign(suggestedActs[2], na);
            delete newActs[obj.act];
          }
        } else {
          for (const obj of o) {
            let na = {};
            na[obj.act] = actTitles[obj.act];
            Object.assign(suggestedActs[1], na);
            delete newActs[obj.act];
          }
        }
      }
    }
    for (let id of Object.keys(suggestedActs[1])) {
      if (Object.keys(suggestedActs[2]).includes(id)) {
        delete suggestedActs[1][id];
      }
    }
    Object.assign(suggestedActs[0], newActs);
    this.setState({ suggestedActs: suggestedActs });
  }

  _handleSuggestedActsYearsBack(yearsBack) {
    this.setState({ suggestedActsYearsBack: yearsBack });
    this._getActSuggestions(yearsBack);
  }

  _handleOnEventClick(e, event) {
    e.preventDefault();
    if (
      this.props.auth.user.is_staff ||
      this.props.auth.user.teams.includes(event.team)
    ) {
      this._handleEventForHarmonogram(event);
      this.setState({ tabSelected: 3 });
    } else {
      Alert.error("Brak dostępu do wydarzenia zespołu: " + event.team);
    }
  }

  _handleChangeTab(tabnr) {
    this.setState({ tabSelected: tabnr });
  }

  _handleEventForHarmonogram(eventForHarmonogram) {
    this.setState({ eventForHarmonogram });
  }

  _handleGoToEventInTimetable(eventData) {
    this.setState({ eventForHarmonogram: eventData }, this._handleChangeTab(3));
  }

  _handleMacierzOnSearch(string) {
    this._getInstytucjeForMacierzSelector(string);
  }

  _handleMacierzOnChange(value, e) {
    this._handleFocusOut(null, "macierz", value);
  }

  _getInstytucjeForMacierzSelector(string) {
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .get(endpoint + "/rspo/instytucja/?limit=25&search=" + string, config)
      .then((res) => {
        if (res.data.count > 0) {
          const macierzSelectorData = res.data.results.map((inst) => ({
            label:
              (inst.profil !== null && inst.profil.miejscowosc.length > 0
                ? "(" + inst.profil.miejscowosc + " id: " + inst.id + ") "
                : "(" + inst.miejscowosc + " id: " + inst.id + " ) ") +
              (inst.profil !== null && inst.profil.nazwa.length > 0
                ? inst.profil.nazwa
                : inst.nazwa),
            value: inst.id,
          }));
          this.setState({ macierzSelectorData });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  _getInstytucjeForFilieContainer(filie) {
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .get(endpoint + "/rspo/instytucja/?limit=100&filie_set=" + filie, config)
      .then((res) => {
        if (res.data.count > 0) {
          this.setState({ filieData: res.data.results });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  _handleFiliaOnClick(e, filia_id) {
    e.preventDefault();
    this.props.history.replace("/admin/instytucje/" + filia_id);
  }

  _handleGoToNearby() {
    const searchState = {
      searchString: "",
      miejscowoscString: "",
      kodpoczString: "",
      perPage: 25,
      activePage: 1,
      sortedBy: null,
      zipCodeNearby: this.state.inst.id + ",1",
    };
    this.props.changeInstytucjeState(searchState);
    this.props.history.push("/admin/instytucje");
  }

  _handleEmailDrawerClose() {
    this.setState({ emailDarawerShow: false });
  }

  handleShowEmailFromWalletForm() {
    this.setState({
      walletEmailFormShow: !this.state.walletEmailFormShow,
    });
  }

  season_str(yb) {
    let d = new Date();
    let y = d.getFullYear();
    let ss = 0;
    if (d.getMonth() < 8) {
      ss = y - yb - 1 + "/" + (y - yb);
    } else {
      ss = y - yb + "/" + (y - yb + 1);
    }
    return ss;
  }

  render() {
    if (this.state.isLoading) {
      return (
        <Panel>
          <Loader
            backdrop
            content="Wczytuję dane placówki oświatowej..."
            vertical
          />
        </Panel>
      );
    } else {
      let rspoAdres = this.state.inst.ulica + " " + this.state.inst.nrbudynku;
      if (this.state.inst.nrlokalu.trim().length > 0) {
        rspoAdres += " / " + this.state.inst.nrlokalu;
      }
      const { events } = this.state;
      let events_new = undefined;
      let events_old = undefined;
      if (this.state.events !== null) {
        events_new = events.filter(
          (event) =>
            moment(event.startdate).toDate() > moment().startOf("day").toDate()
        );
        events_old = events.filter(
          (event) =>
            moment(event.startdate).toDate() < moment().startOf("day").toDate()
        );
      }
      return (
        <Panel bodyFill={true}>
          <h1>
            {this.state.inst.nazwa.length > 0
              ? this.state.inst.nazwa
              : this.state.inst.profil.nazwa}
          </h1>
          <TagGroup>
            {this.state.isRSPO ? (
              <Tag color="red">RSPO</Tag>
            ) : (
              <Tag color="blue">EDEN</Tag>
            )}
            {this.state.inst.profil != null ? (
              <Tag color="green">Profil</Tag>
            ) : (
              true
            )}
            {this.state.inst.profil != null &&
            this.state.inst.profil.visited ? (
              <Tag color="yellow">Odwiedzona</Tag>
            ) : (
              true
            )}
          </TagGroup>
          <Divider />
          <Tabs
            headerStyle={{ fontSize: 16 }}
            activeHeaderStyle={{ color: "#0F4A85" }}
            selected={this.state.tabSelected}
            onSelect={(index, label) => {
              if (label === "Wydarzenia") {
                this._handleEventHistoryPanel();
              } else if (label === "Dane kontaktowe") {
                this._loadInstData();
              }
              this.setState({ tabSelected: index });
            }}
          >
            <Tab label="Dane kontaktowe">
              <Grid fluid style={{ marginLeft: 0 }}>
                <PanelGroup accordion>
                  <Panel header="Dane kontaktowe placówki" defaultExpanded>
                    <Row className="show-grid">
                      <Col xs={24} sm={24} md={12}>
                        <InstytucjeField
                          isRSPO={this.state.isRSPO}
                          fieldName="nazwa"
                          fieldLabel="Nazwa placówki"
                          fieldRSPO={this.state.inst.nazwa}
                          fieldProfil={
                            this.state.inst.profil
                              ? this.state.inst.profil.nazwa
                              : null
                          }
                          handleFocusOut={this._handleFocusOut}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        {this.state.inst.filie.length > 0 &&
                        this.state.filieData.length > 0 ? (
                          <div>
                            <div style={labelFieldStyles}>Filie</div>
                            {this.state.filieData.map((inst) => (
                              <div key={inst.id}>
                                {inst.profil !== null &&
                                inst.profil.miejscowosc.length > 0
                                  ? "(" +
                                    inst.profil.miejscowosc +
                                    " id: " +
                                    inst.id +
                                    ") "
                                  : "(" +
                                    inst.miejscowosc +
                                    " id: " +
                                    inst.id +
                                    " ) "}
                                <div>
                                  <Button
                                    appearance="link"
                                    href="#"
                                    onClick={(e) =>
                                      this._handleFiliaOnClick(e, inst.id)
                                    }
                                  >
                                    {inst.profil !== null &&
                                    inst.profil.nazwa.length > 0
                                      ? inst.profil.nazwa
                                      : inst.nazwa}
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div>
                            <div
                              style={labelFieldStyles}
                              onClick={(e) =>
                                this._handleFiliaOnClick(
                                  e,
                                  this.state.inst.profil !== null &&
                                    this.state.inst.profil.macierz !== null
                                    ? this.state.inst.profil.macierz
                                    : this.state.inst.id
                                )
                              }
                            >
                              Placówka macierzysta
                            </div>
                            <SelectPicker
                              block={true}
                              onSearch={this._handleMacierzOnSearch}
                              onChange={this._handleMacierzOnChange}
                              data={this.state.macierzSelectorData}
                              value={
                                this.state.inst.profil !== null
                                  ? this.state.inst.profil.macierz
                                  : null
                              }
                            />
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row className="show-grid">
                      <Col xs={24} sm={24} md={8}>
                        <InstytucjeField
                          isRSPO={this.state.isRSPO}
                          fieldName="ulica"
                          fieldLabel="Adres"
                          fieldRSPO={rspoAdres}
                          fieldProfil={
                            this.state.inst.profil
                              ? this.state.inst.profil.ulica
                              : null
                          }
                          handleFocusOut={this._handleFocusOut}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <InstytucjeField
                          isRSPO={this.state.isRSPO}
                          fieldName="kodpocz"
                          fieldLabel="Kod pocztowy"
                          fieldRSPO={this.state.inst.kodpocz}
                          fieldProfil={
                            this.state.inst.profil
                              ? this.state.inst.profil.kodpocz
                              : null
                          }
                          handleFocusOut={this._handleFocusOut}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <InstytucjeField
                          isRSPO={this.state.isRSPO}
                          fieldName="miejscowosc"
                          fieldLabel="Miejscowość"
                          fieldRSPO={this.state.inst.miejscowosc}
                          fieldProfil={
                            this.state.inst.profil
                              ? this.state.inst.profil.miejscowosc
                              : null
                          }
                          handleFocusOut={this._handleFocusOut}
                        />
                      </Col>
                    </Row>
                    <Row className="show-grid">
                      <Col xs={24} sm={24} md={8}>
                        <InstytucjeField
                          isRSPO={this.state.isRSPO}
                          fieldName="powiat"
                          fieldLabel="Powiat"
                          fieldRSPO={this.state.inst.powiat}
                          fieldProfil={
                            this.state.inst.profil
                              ? this.state.inst.profil.powiat
                              : null
                          }
                          handleFocusOut={this._handleFocusOut}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <InstytucjeField
                          isRSPO={this.state.isRSPO}
                          fieldName="wojewodztwo"
                          fieldLabel="Województwo"
                          fieldRSPO={this.state.inst.wojewodztwo}
                          fieldProfil={
                            this.state.inst.profil
                              ? this.state.inst.profil.wojewodztwo
                              : null
                          }
                          handleFocusOut={this._handleFocusOut}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <InstytucjeField
                          isRSPO={this.state.isRSPO}
                          fieldName="kraj"
                          fieldLabel="Kraj"
                          fieldRSPO={this.state.inst.kraj}
                          fieldProfil={
                            this.state.inst.profil
                              ? this.state.inst.profil.kraj
                              : null
                          }
                          handleFocusOut={this._handleFocusOut}
                        />
                      </Col>
                    </Row>
                    <Row className="show-grid">
                      <Col xs={24} sm={24} md={12}>
                        <InstytucjeField
                          textArea={true}
                          isRSPO={this.state.isRSPO}
                          fieldName="telefon"
                          fieldLabel="Telefon"
                          fieldRSPO={this.state.inst.telefon}
                          fieldProfil={
                            this.state.inst.profil
                              ? this.state.inst.profil.telefon
                              : null
                          }
                          handleFocusOut={this._handleFocusOut}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <InstytucjeField
                          textArea={true}
                          isRSPO={this.state.isRSPO}
                          fieldName="vatdata"
                          fieldLabel="Dane do faktury"
                          fieldProfil={
                            this.state.inst.profil
                              ? this.state.inst.profil.vatdata
                              : ""
                          }
                          handleFocusOut={this._handleFocusOut}
                        />
                      </Col>
                    </Row>
                    <Row className="show-grid">
                      <Col xs={24} sm={24} md={8}>
                        <InstytucjeField
                          isRSPO={this.state.isRSPO}
                          fieldName="email"
                          fieldLabel="E-mail"
                          fieldRSPO={this.state.inst.email}
                          fieldProfil={
                            this.state.inst.profil
                              ? this.state.inst.profil.email
                              : null
                          }
                          handleFocusOut={this._handleFocusOut}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <InstytucjeField
                          isRSPO={this.state.isRSPO}
                          fieldName="www"
                          fieldLabel="WWW"
                          fieldRSPO={this.state.inst.www}
                          fieldProfil={
                            this.state.inst.profil
                              ? this.state.inst.profil.www
                              : null
                          }
                          handleFocusOut={this._handleFocusOut}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <InstytucjeField
                          isRSPO={this.state.isRSPO}
                          fieldName="facebook"
                          fieldLabel="Facebook"
                          fieldProfil={
                            this.state.inst.profil
                              ? this.state.inst.profil.facebook
                              : null
                          }
                          handleFocusOut={this._handleFocusOut}
                        />
                      </Col>
                    </Row>
                    <Row className="show-grid">
                      <Col xs={24} style={{ marginTop: 15 }}>
                        <Button
                          appearance="primary"
                          onClick={this._handleGoToNearby}
                        >
                          Wyszukaj placówki w pobliżu
                        </Button>
                        {this.props.auth?.user?.emails?.length > 0 ? (
                          <Button
                            style={{ marginLeft: 10 }}
                            appearance="primary"
                            onClick={this.handleShowEmailFromWalletForm}
                          >
                            Wyślij email z ofertą do placówki
                          </Button>
                        ) : null}
                      </Col>
                    </Row>
                  </Panel>
                  {this.props.auth?.user?.emails?.length > 0 ? (
                    <WalletEmailForm
                      show={this.state.walletEmailFormShow}
                      onEmailFormClose={this.handleShowEmailFromWalletForm}
                      instytucjaId={this.state.inst.id}
                    />
                  ) : null}
                  {this.state.inst.profil != null ? (
                    <Panel
                      header="Notatki i historia"
                      collapsible
                      defaultExpanded
                    >
                      <Row className="show-grid">
                        <Col xs={24} sm={24} md={24}>
                          <InstytucjeField
                            textArea={true}
                            isRSPO={this.state.isRSPO}
                            fieldName="notes"
                            fieldLabel="Notes"
                            fieldProfil={
                              this.state.inst.profil
                                ? this.state.inst.profil.notes
                                : ""
                            }
                            handleFocusOut={this._handleFocusOut}
                          />
                        </Col>
                      </Row>
                      {this.state.history != null ? (
                        <Row className="show-grid">
                          <Col xs={24} sm={24} md={24}>
                            <InstytucjeField
                              textArea={false}
                              isRSPO={true}
                              fieldLabel="Bajki"
                              staticText={true}
                              fieldRSPO={this.state.history.Bajki}
                            />
                          </Col>
                        </Row>
                      ) : (
                        true
                      )}
                      {/*
                  <Row className="show-grid">
                    <Col xs={24} sm={24} md={24}>
                      <InstytucjeField
                        textArea={true}
                        isRSPO={this.state.isRSPO}
                        fieldName="history"
                        fieldLabel="Historia"
                        fieldProfil={this.state.inst.profil.history}
                        handleFocusOut={this._handleFocusOut}
                      />
                    </Col>
                  </Row>
                  */}
                    </Panel>
                  ) : (
                    true
                  )}
                </PanelGroup>
              </Grid>
            </Tab>
            <Tab label="Wydarzenia">
              <Grid fluid style={{ marginLeft: 0 }} />
              <PanelGroup accordion>
                <Panel
                  header="Propozycje przedstawień"
                  collapsible
                  defaultExpanded
                >
                  <Row className="show-grid">
                    <Col xs={24} sm={24} md={12}>
                      <div>
                        nie grane od
                        <SelectPicker
                          style={{ marginLeft: 10 }}
                          data={[
                            {
                              value: 0,
                              label: this.season_str(0) + " (w tym sezonie)",
                            },
                            {
                              value: 1,
                              label:
                                this.season_str(1) + " (od ubiegłego sezonu)",
                            },
                            {
                              value: 2,
                              label: this.season_str(2) + " (od 2 sezonów)",
                            },
                            {
                              value: 3,
                              label: this.season_str(3) + " (od 3 sezonów)",
                            },
                            {
                              value: 4,
                              label: this.season_str(4) + " (od 4 sezonów)",
                            },
                            {
                              value: 5,
                              label: this.season_str(5) + " (od 5 sezonów)",
                            },
                          ]}
                          defaultValue={this.state.suggestedActsYearsBack}
                          searchable={false}
                          cleanable={false}
                          onChange={this._handleSuggestedActsYearsBack}
                        />
                      </div>
                      <Divider />
                      {this.state.suggestedActs != null ? (
                        <Grid fluid style={{ marginLeft: 0 }}>
                          <Row className="show-grid">
                            <Col xs={24} sm={24} md={12}>
                              <div>
                                {0 in this.state.suggestedActs ? (
                                  <div>
                                    <div
                                      style={{
                                        color: "green",
                                        marginBottom: 10,
                                      }}
                                    >
                                      nowe:
                                    </div>
                                    {Object.keys(
                                      this.state.suggestedActs[0]
                                    ).map((item, i) => (
                                      <div key={i}>
                                        {this.state.suggestedActs[0][item]}
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  true
                                )}
                              </div>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <div>
                                {1 in this.state.suggestedActs ? (
                                  <div>
                                    <div
                                      style={{ color: "red", marginBottom: 10 }}
                                    >
                                      grane wcześniej niż sezonów temu:{" "}
                                      {this.state.suggestedActsYearsBack}
                                    </div>
                                    {Object.keys(
                                      this.state.suggestedActs[1]
                                    ).map((item, i) => (
                                      <div key={i}>
                                        {this.state.suggestedActs[1][item]}
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  true
                                )}
                              </div>
                            </Col>
                          </Row>
                        </Grid>
                      ) : (
                        true
                      )}
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <div style={{ marginLeft: 20 }}>
                        <InstytucjeField
                          isRSPO={false}
                          fieldName="maxpay"
                          fieldLabel="Maksymalna cena"
                          fieldProfil={
                            this.state.inst.profil
                              ? this.state.inst.profil.maxpay
                              : null
                          }
                          handleFocusOut={this._handleFocusOut}
                        />
                      </div>
                    </Col>
                  </Row>
                </Panel>
                <Panel
                  header="Wydarzenia zaplanowane"
                  collapsible
                  defaultExpanded
                >
                  {events === null ? (
                    <Loader />
                  ) : events_new.length > 0 ? (
                    <Fragment>
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Data rozpoczęcia</th>
                            <th>Przedstawienia</th>
                            <th>Status</th>
                            <th>Zakres cenowy</th>
                          </tr>
                        </thead>
                        <tbody>
                          {events_new.map((event) => (
                            <tr
                              key={event.id}
                              style={{
                                backgroundColor: this.props.eventStatusesDict[
                                  event.eventstatuschange_set.length > 0
                                    ? event.eventstatuschange_set[0].status
                                    : 1
                                ].bgcolor,
                              }}
                            >
                              <td>{event.id}</td>
                              <td>
                                <a
                                  style={{ color: "black" }}
                                  href="/"
                                  appearance="link"
                                  onClick={(e) =>
                                    this._handleOnEventClick(e, event)
                                  }
                                >
                                  {moment(event.startdate).format(
                                    "[dnia: ] DD-MM-YYYY [ o godzinie: ] HH:mm:ss"
                                  )}
                                </a>
                              </td>
                              <td>
                                {event.acts.map(
                                  (act) => this.state.actTitles[act] + ", "
                                )}
                              </td>
                              <td>
                                {moment(
                                  event.eventstatuschange_set[0].created
                                ).format(
                                  "[dnia: ] DD-MM-YYYY [ o godzinie: ] HH:mm:ss"
                                )}{" "}
                                -
                                {
                                  this.props.eventStatusesDict[
                                    event.eventstatuschange_set.length > 0
                                      ? event.eventstatuschange_set[0].status
                                      : 1
                                  ].name
                                }
                              </td>
                              <td>{event.payfrom + " - " + event.payto}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      {this.props.auth?.user?.emails?.length > 0 ? (
                        <Button
                          style={{ marginTop: 10 }}
                          appearance="primary"
                          onClick={() =>
                            this.setState({ emailDarawerShow: true })
                          }
                        >
                          Wyślij email potwierdzający zaplanowane wydarzenia
                        </Button>
                      ) : null}
                    </Fragment>
                  ) : (
                    <div>Brak zaplanowanych wydarzeń</div>
                  )}
                </Panel>
                <Panel
                  header="Wydarzenia historyczne"
                  collapsible
                  defaultExpanded
                >
                  {events === null ? (
                    <Loader />
                  ) : events_old.length > 0 ? (
                    <Table striped bordered hover size="sm">
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Data rozpoczęcia</th>
                          <th>Przedstawienia</th>
                          <th>Status</th>
                          <th>Zapłacono</th>
                        </tr>
                      </thead>
                      <tbody>
                        {events_old.map((event) => (
                          <tr
                            key={event.id}
                            style={{
                              backgroundColor: this.props.eventStatusesDict[
                                event.eventstatuschange_set.length > 0
                                  ? event.eventstatuschange_set[0].status
                                  : 1
                              ].bgcolor,
                            }}
                          >
                            <td>{event.id}</td>
                            <td>
                              <a
                                style={{ color: "black" }}
                                href="/"
                                appearance="link"
                                onClick={(e) =>
                                  this._handleOnEventClick(e, event)
                                }
                              >
                                {moment(event.startdate).format(
                                  "[dnia: ] DD-MM-YYYY [ o godzinie: ] HH:mm:ss"
                                )}
                              </a>
                            </td>
                            <td>
                              {event.acts.map(
                                (act) => this.state.actTitles[act] + ", "
                              )}
                            </td>
                            <td>
                              {event.eventstatuschange_set.length > 0
                                ? moment(
                                    event.eventstatuschange_set[0].created
                                  ).format(
                                    "[dnia: ] DD-MM-YYYY [ o godzinie: ] HH:mm:ss"
                                  ) +
                                  " - " +
                                  this.props.eventStatusesDict[
                                    event.eventstatuschange_set[0].status
                                  ].name
                                : "brak statusu"}
                            </td>
                            <td>{event.paid}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div>Brak historycznych wydarzeń</div>
                  )}
                </Panel>
                <Panel
                  header="Wydarzenia prehistoryczne"
                  collapsible
                  defaultExpanded
                >
                  <Row className="show-grid">
                    <Col xs={24} sm={24} md={24}>
                      {this.state.eventPreHistory != null ? (
                        Object.keys(this.state.eventPreHistory).map(
                          (season) => (
                            <div key={season}>
                              <div style={styleEventHistorySeason}>
                                {season}
                              </div>
                              <div>
                                {this.state.eventPreHistory[season].map(
                                  (event) => (
                                    <div
                                      style={styleEventHistoryItem}
                                      key={
                                        season +
                                        "_" +
                                        event.act +
                                        "_" +
                                        event.created
                                      }
                                    >
                                      {this.state.actTitles != null
                                        ? this.state.actTitles[event.act]
                                        : event.act}
                                      {event.mikolaj ? (
                                        <span style={styleEventHistoryMikolaj}>
                                          +M
                                        </span>
                                      ) : null}
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          )
                        )
                      ) : (
                        <div>Nie znaleziono wydarzeń w historii</div>
                      )}
                    </Col>
                  </Row>
                  <Divider />
                  {this.state.history != null ? (
                    <Row className="show-grid">
                      <Col xs={24} sm={24} md={24}>
                        <InstytucjeField
                          textArea={false}
                          isRSPO={true}
                          fieldLabel="Bajki"
                          staticText={true}
                          fieldRSPO={this.state.history.Bajki}
                        />
                      </Col>
                    </Row>
                  ) : (
                    true
                  )}
                </Panel>
              </PanelGroup>
              <Drawer
                style={{
                  overflow: "auto",
                  height: "100%",
                  background: "#fff fixed",
                }}
                full
                placement="left"
                show={this.state.emailDarawerShow}
                onHide={this._handleEmailDrawerClose}
              >
                <EventConfirmationEmailForm
                  instytucjaId={this.state.inst.id}
                  onEmailFormClose={this._handleEmailDrawerClose}
                />
              </Drawer>
            </Tab>
            <Tab label="Kontakty">
              <Calls instytucja={this.state.inst.id} />
            </Tab>
            <Tab label="Terminarz">
              <Grid fluid style={{ marginLeft: 0 }}>
                <PanelGroup accordion>
                  <Panel
                    header="Terminarz przedstawień teatralnych"
                    collapsible
                    defaultExpanded
                  >
                    <Row className="show-grid">
                      <Col xs={24} sm={24} md={24}>
                        <Timetable
                          instytucja={this.state.inst.id}
                          instytucja_info={this.state.inst}
                          eventForHarmonogram={this.state.eventForHarmonogram}
                          handleEventForHarmonogram={
                            this._handleEventForHarmonogram
                          }
                          resetEventHistoryPanel={this._resetEventHistoryPanel}
                          changeTab={this._handleChangeTab}
                          {...this.props}
                        />
                      </Col>
                    </Row>
                  </Panel>
                </PanelGroup>
              </Grid>
            </Tab>
            <Tab label="Menadżer wydarzeń">
              <EventManager
                instytucja={this.state.inst.id}
                instytucja_info={this.state.inst}
                history={this.props.history}
                goToEventInTimetable={this._handleGoToEventInTimetable}
                setEventForHarmonogram={this._handleEventForHarmonogram}
                resetEventHistoryPanel={this._resetEventHistoryPanel}
                changeTab={this._handleChangeTab}
              />
            </Tab>
          </Tabs>
          <Divider />
        </Panel>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  searchState: state.instytucje.searchState,
  acts: state.acts.acts,
  eventStatusesDict: state.events.eventStatusesDict,
});

export default connect(mapStateToProps, {
  getInstytucje,
  changeInstytucjeState,
})(InstytucjeDetail);
