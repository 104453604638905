export const GET_ERRORS = "GET_ERRORS";

export const CREATE_MESSAGE = "CREATE_MESSAGE";

export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const USER_LIST_LOADED = "USER_LIST_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const INSTYTUCJE_LOADING = "INSTYTUCJE_LOADING";
export const CHANGE_INSTYTUCJE_STATE = "CHANGE_INSTYTUCJE_STATE";
export const INSTYTUCJE_LOAD_SUCCESS = "INSTYTUCJE_LOAD_SUCCESS";
export const INSTYTUCJE_LOAD_ERROR = "INSTYTUCJE_LOAD_ERROR";

export const GET_ACTS = "GET_ACTS";

export const GET_TEAMS = "GET_TEAMS";

export const GET_EVENT_STATUSES = "GET_EVENT_STATUSES";

export const CHANGE_HPRINTER_STATE = "CHANGE_HPRINTER_STATE";

export const WALLET_UPDATE = "WALLET_UPDATE";

export const REMINDERS_LOADING = "REMINDERS_LOADING";
export const CHANGE_REMINDERS_STATE = "CHANGE_REMINDERS_STATE";
export const REMINDERS_LOAD_SUCCESS = "REMINDERS_LOAD_SUCCESS";
export const REMINDERS_LOAD_ERROR = "REMINDERS_LOAD_ERROR";

export const EVTMGRPAR_INIT_STATE = "EVTMGRPAR_INIT_STATE";
export const EVTMGRPAR_CHANGE_STATE = "EVTMGRPAR_CHANGE_STATE";

export const EVTMGR_SUGGESTED_EVENTS_LOADING =
  "EVTMGR_SUGGESTED_EVENTS_LOADING";
export const EVTMGR_SUGGESTED_EVENTS_LOADED = "EVTMGR_SUGGESTED_EVENTS_LOADED";
export const EVTMGR_SUGGESTED_EVENTS_LOAD_ERROR =
  "EVTMGR_SUGGESTED_EVENTS_LOAD_ERROR";
export const EVTMGR_UPDATE_EVENT = "EVTMGR_UPDATE_EVENT";
