import { WALLET_UPDATE } from "./types";

export const addToWallet = instytucje => (dispatch, getState) => {
  let walletData = new Set(getState().wallet.data);
  instytucje.map(inst => walletData.add(inst));
  dispatch({
    type: WALLET_UPDATE,
    payload: { data: walletData }
  });
};

export const deleteFromWallet = instytucje => (dispatch, getState) => {
  let walletData = new Set(getState().wallet.data);
  instytucje.map(inst => walletData.delete(inst));
  dispatch({
    type: WALLET_UPDATE,
    payload: { data: walletData }
  });
};

export const clearWallet = () => (dispatch, getState) => {
  let walletData = new Set();
  dispatch({
    type: WALLET_UPDATE,
    payload: { data: walletData }
  });
};
