import React, { Component } from "react";
import axios from "axios";
import { endpoint } from "../config";
import { connect } from "react-redux";
import {
  Drawer,
  Button,
  Alert,
  Divider,
  Grid,
  Row,
  Col,
  Input,
  SelectPicker,
} from "rsuite";
import EditableHtmlField from "./EditableHtmlField";

const labelFieldStyles = {
  fontSize: 12,
  color: " #aaa",
  marginTop: 10,
  marginBottom: 10,
  textDecoration: "underline",
};

class WalletEmailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inst_ids: this.props.wallet.data,
      id_template: null,
      from:
        this.props.auth?.user?.emails.length > 0
          ? this.props.auth.user.emails[0].id
          : null,
      subject: "",
      html_content: "",
    };
    this.handleChangeField = this.handleChangeField.bind(this);
    this.handleChangeHtmlContent = this.handleChangeHtmlContent.bind(this);
    this.handleSendEmail = this.handleSendEmail.bind(this);
    this.handleSelectEmail = this.handleSelectEmail.bind(this);
    this.handleSelectTemplate = this.handleSelectTemplate.bind(this);
    this.changeEmailInHtmlContent = this.changeEmailInHtmlContent.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.wallet.data !== this.props.wallet.data) {
      this.setState({ inst_ids: this.props.wallet.data });
    }
    if (prevState.html_content !== this.state.html_content) {
      this.changeEmailInHtmlContent(-1, this.state.from);
    }
  }

  handleChangeField(val, obj) {
    this.setState({
      [obj.target.name]: val,
    });
  }

  handleChangeHtmlContent(name, content) {
    this.setState({
      [name]: content,
    });
  }

  handleSelectEmail(val) {
    this.setState({ from: val });
    this.changeEmailInHtmlContent(this.state.from, val);
  }

  changeEmailInHtmlContent(emailIdOld = -1, emailIdNew = 0) {
    function getSubEmail(str) {
      const re = /<.*>/g;
      const found = str.match(re);
      if (found !== null && found[0].length > 0) {
        return found[0].slice(1, -1);
      } else {
        return str;
      }
    }
    const emailOld =
      parseInt(emailIdOld) === -1
        ? "lukaszmalec@teatreden.pl"
        : this.props.auth.user?.emails?.filter(
            (obj) => parseInt(obj.id) === parseInt(emailIdOld)
          )[0].email;
    const emailNew = this.props.auth.user?.emails?.filter(
      (obj) => parseInt(obj.id) === parseInt(emailIdNew)
    )[0].email;
    const { html_content } = this.state;
    const re = new RegExp(getSubEmail(emailOld), "g");
    const new_html_content = html_content.replace(re, getSubEmail(emailNew));
    this.setState({ html_content: new_html_content });
  }

  handleSelectTemplate(val) {
    this.setState({ id_template: val });
    this.loadData(val);
  }

  handleSendEmail() {
    const data = {
      inst_ids: this.props.instytucjaId
        ? [this.props.instytucjaId]
        : [...this.state.inst_ids],
      from: this.props.auth.user.emails.filter(
        (email) => email.id === this.state.from
      )[0].email,
      subject: this.state.subject,
      html_content: this.state.html_content,
    };
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .post(endpoint + "/mailing/offeremail/", data, config)
      .then((res) => {
        const noEmail = JSON.parse(res.data.no_email);
        if (noEmail.length > 0) {
          let noEmailInfo = "";
          noEmail.forEach((inst) => {
            noEmailInfo = noEmailInfo.concat(", " + inst);
          });
          Alert.warning("Brak emaila w placówce:" + noEmailInfo, 10000);
        }
        Alert.success("Email został wysłany");
        this.props.onEmailFormClose();
      })
      .catch((err) => {
        console.log(err);
        Alert.error("Błąd wysyłki maila");
      });
  }

  loadData(id_template = null) {
    let id_template_str = "";
    if (id_template !== null) {
      id_template_str = "?id_template=" + id_template;
    }

    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .get(endpoint + "/mailing/offeremail/" + id_template_str, config)
      .then((res) => {
        const data = res.data;
        this.setState(
          {
            ...data,
          },
          this.changeEmailInHtmlContent(-1, this.state.from)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const {
      inst_ids,
      templates,
      id_template,
      from,
      subject,
      html_content,
    } = this.state;
    return (
      <Drawer
        style={{ overflow: "auto", height: "100%" }}
        show={this.props.show}
        full
        placement="left"
        onHide={this.props.onEmailFormClose}
      >
        <Drawer.Header>
          <Drawer.Title style={{ fontSize: 20 }}>
            {!this.props.instytucjaId
              ? "Email ofertowy do placówek z portfela"
              : "Email ofertowy"}
            {inst_ids.size === 0 && !this.props.instytucjaId ? (
              <div style={{ color: "#CC0000", fontWeight: "Bold" }}>
                {" "}
                Brak placówek w portfelu
              </div>
            ) : null}
          </Drawer.Title>
        </Drawer.Header>
        <Divider />
        <Drawer.Body>
          <Grid fluid>
            <Row className="show-grid">
              <Col xs={24}>
                <div style={labelFieldStyles}>Wzór emaila</div>
                <SelectPicker
                  name="id_template"
                  data={templates}
                  value={id_template}
                  valueKey="id"
                  labelKey="name"
                  onChange={this.handleSelectTemplate}
                  cleanable={false}
                />
                <div style={labelFieldStyles}>Od</div>
                <SelectPicker
                  name="from"
                  data={this.props.auth.user.emails}
                  value={from}
                  valueKey="id"
                  labelKey="email"
                  onChange={this.handleSelectEmail}
                  cleanable={false}
                />
                <div style={labelFieldStyles}>Temat</div>
                <Input
                  name="subject"
                  value={subject}
                  onChange={this.handleChangeField}
                />
                <div style={labelFieldStyles}>Treść</div>
                <EditableHtmlField
                  fieldName="html_content"
                  content={html_content}
                  onFocusOut={this.handleChangeHtmlContent}
                  inputClassName="rs-input"
                />
              </Col>
            </Row>
          </Grid>
        </Drawer.Body>
        <Drawer.Footer style={{ paddingButtom: 20 }}>
          <Button onClick={this.props.onEmailFormClose} appearance="subtle">
            Anuluj
          </Button>
          <Button
            onClick={this.handleSendEmail}
            appearance="primary"
            disabled={
              inst_ids.size === 0 && !this.props.instytucjaId ? true : false
            }
          >
            Wyślij
          </Button>
        </Drawer.Footer>
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  wallet: state.wallet,
});

export default connect(mapStateToProps, {})(WalletEmailForm);
