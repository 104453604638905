import { combineReducers } from "redux";
import errors from "./errors";
import messages from "./messages";
import auth from "./auth";
import instytucje from "./instytucje";
import acts from "./acts";
import teams from "./teams";
import events from "./events";
import hprinter from "./hprinter";
import wallet from "./wallet";
import reminders from "./reminders";
import evtmgrpar from "./evtmgrpar";
import evtmgr from "./evtmgr";

const appReducer = combineReducers({
  errors,
  messages,
  auth,
  instytucje,
  acts,
  teams,
  events,
  hprinter,
  wallet,
  reminders,
  evtmgrpar,
  evtmgr,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_SUCCESS") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
