import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import { Loader } from "rsuite";

export class Login extends Component {
  state = {
    username: "",
    password: "",
    isLoading: false
  };

  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.props.login(this.state.username, this.state.password, this.endLoading);
  };

  endLoading = () => {
    this.setState({ isLoading: false });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/admin" />;
    } else {
      const { username, password } = this.state;
      return (
        <div className="col-md-4 m-auto">
          <div className="card card-body mt-5">
            <h2 className="text-center">Teatr Eden - administracja</h2>
            <hr />
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <label>Nazwa użytkownika</label>
                <input
                  type="text"
                  className="form-control"
                  name="username"
                  onChange={this.onChange}
                  value={username}
                />
              </div>

              <div className="form-group">
                <label>Hasło</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  onChange={this.onChange}
                  value={password}
                />
              </div>
              <br />
              <div className="form-group">
                <button type="submit" className="btn btn-primary">
                  Zaloguj
                </button>
                {this.state.isLoading ? (
                  <Loader
                    style={{ marginLeft: 20 }}
                    size="sm"
                    content="Logowanie..."
                  />
                ) : (
                  true
                )}
              </div>
            </form>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(
  mapStateToProps,
  { login }
)(Login);
