import React from "react";
import PropTypes from "prop-types";
import { Icon } from "rsuite";
import ReactMarkdown from "react-markdown";

export default class EditableLabel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: this.props.isEditing || false,
      text: this.props.text || "",
    };

    this._handleFocus = this._handleFocus.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleEditField = this._handleEditField.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.text !== prevProps.text) {
      this.setState({
        ...this.state,
        text: this.props.text,
        // isEditing: this.props.isEditing,
      });
/*      if (this.props.isEditing) {
        this.props.unEdit();
      }
*/
    }
  }

  _handleFocus() {
    if (this.state.isEditing) {
      if (typeof this.props.onFocusOut === "function") {
        this.props.onFocusOut(this, this.props.fieldname, this.state.text);
      }
    } else {
      if (typeof this.props.onFocus === "function") {
        this.props.onFocus(this);
      }
    }

    this.setState({
      isEditing: !this.state.isEditing,
    });
  }

  _handleChange() {
    this.setState({
      text: this.textInput.value,
    });
  }

  _handleEditField() {
    this.setState({
      isEditing: true,
    });
  }

  render() {
    if (this.state.isEditing) {
      return (
        <div>
          {this.props.textArea ? (
            <textarea
              type="text"
              className={this.props.inputClassName}
              ref={(input) => {
                this.textInput = input;
              }}
              value={this.state.text}
              onChange={this._handleChange}
              onBlur={this._handleFocus}
              style={{
                fontSize: this.props.inputFontSize,
                fontWeight: this.props.inputFontWeight,
                borderWidth: this.props.inputBorderWidth,
              }}
              placeholder={this.props.inputPlaceHolder}
              tabIndex={this.props.inputTabIndex}
              cols={120}
              rows={100}
              autoFocus
            />
          ) : (
            <input
              type="text"
              className={this.props.inputClassName}
              ref={(input) => {
                this.textInput = input;
              }}
              value={this.state.text}
              onChange={this._handleChange}
              onBlur={this._handleFocus}
              style={{
                width: this.props.inputWidth,
                height: this.props.inputHeight,
                fontSize: this.props.inputFontSize,
                fontWeight: this.props.inputFontWeight,
                borderWidth: this.props.inputBorderWidth,
              }}
              maxLength={this.props.inputMaxLength}
              placeholder={this.props.inputPlaceHolder}
              tabIndex={this.props.inputTabIndex}
              autoFocus
            />
          )}
        </div>
      );
    }

    return (
      <div>
        {this.props.textArea ? (
          <ReactMarkdown
            className="reactmarkdown"
            source={this.state.text}
            onClick={this._handleFocus}
            style={{
              fontSize: this.props.labelFontSize,
              fontWeight: this.props.labelFontWeight,
            }}
          />
        ) : (
          <p
            className={this.props.labelClassName}
            onClick={this._handleFocus}
            style={{
              fontSize: this.props.labelFontSize,
              fontWeight: this.props.labelFontWeight,
            }}
          >
            {this.state.text}
          </p>
        )}
        <span>
          <Icon icon="edit" onClick={this._handleEditField} />
        </span>
      </div>
    );
  }
}

EditableLabel.propTypes = {
  text: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,

  labelClassName: PropTypes.string,
  labelFontSize: PropTypes.string,
  labelFontWeight: PropTypes.string,

  inputMaxLength: PropTypes.number,
  inputPlaceHolder: PropTypes.string,
  inputTabIndex: PropTypes.number,
  inputWidth: PropTypes.string,
  inputHeight: PropTypes.string,
  inputFontSize: PropTypes.string,
  inputFontWeight: PropTypes.string,
  inputClassName: PropTypes.string,
  inputBorderWidth: PropTypes.string,

  onFocus: PropTypes.func,
  onFocusOut: PropTypes.func,
};
