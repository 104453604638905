import React, { Component } from "react";
import { FormGroup, ControlLabel, Icon } from "rsuite";
import EditableLabel from "./EditableLabel";

const rspoFieldStyles = {
  paddingTop: 5,
  fontSize: 16,
  color: " #aaa",
};

const labelFieldStyles = {
  fontSize: 12,
  color: " #aaa",
  marginTop: 10,
  textDecoration: "underline",
};

class InstytucjeField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldProfil: "",
    };
    this.handleEditField = this.handleEditField.bind(this);
    this.handleUnEditField = this.handleUnEditField.bind(this);
  }

  componentDidMount() {
    this.setState({
      staticText: this.props.staticText,
      beEditable: this.props.beEditable,
      textArea: this.props.textArea,
      isRSPO: this.props.isRSPO,
      fieldName: this.props.fieldName,
      fieldLabel: this.props.fieldLabel,
      fieldRSPO: this.props.fieldRSPO,
      fieldProfil: this.props.fieldProfil || "",
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.fieldProfil !== prevProps.fieldProfil) {
      this.setState({
        ...this.state,
        fieldProfil: this.props.fieldProfil,
      });
    }
  }

  handleEditField(event) {
    event.preventDefault();
    this.setState({
      fieldProfil: "...",
      beEditable: true,
    });
  }

  handleUnEditField() {
    this.setState({ beEditable: false });
  }

  render() {
    return (
      <FormGroup>
        <ControlLabel style={labelFieldStyles}>
          {this.state.fieldLabel}
        </ControlLabel>
        {this.state.isRSPO ? (
          <p style={rspoFieldStyles}>
            {this.state.fieldRSPO}
            {!this.state.fieldProfil || this.state.fieldProfil.length === 0 ? (
              <span>
                &nbsp;&nbsp;&nbsp;{" "}
                <Icon
                  fieldname="nazwa"
                  icon="edit"
                  onClick={this.handleEditField}
                />
              </span>
            ) : (
              true
            )}
          </p>
        ) : (
          true
        )}
        {(this.state.fieldProfil || !this.state.isRSPO) &&
        !this.state.staticText ? (
          <EditableLabel
            fieldname={this.state.fieldName}
            textArea={this.state.textArea}
            isEditing={this.state.beEditable}
            text={this.state.fieldProfil}
            labelClassName="rs-label"
            inputClassName="rs-input"
            inputWidth={this.props.inputWidth}
            inputHeight="30px"
            inputMaxLength={256}
            inputFontSize="16px"
            labelFontSize="16px"
            onFocusOut={this.props.handleFocusOut}
            onFocus={this.props.handleFocus}
            unEdit={this.handleUnEditField}
          />
        ) : (
          true
        )}
      </FormGroup>
    );
  }
}

export default InstytucjeField;
