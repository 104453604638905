import axios from "axios";
import { GET_TEAMS } from "./types";
import { endpoint } from "../config";

export const getTeams = (limit = 100) => (dispatch, getState) => {
  let params = "?limit=" + limit;
  const token = getState().auth.token;
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }
  axios
    .get(endpoint + "/teams/" + params, config)
    .then(res => {
      dispatch({
        type: GET_TEAMS,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};
