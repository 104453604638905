import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { endpoint } from "../config";
import {
  PanelGroup,
  Panel,
  Loader,
  Modal,
  ButtonToolbar,
  Button,
} from "rsuite";
import Table from "react-bootstrap/Table";
import moment from "moment";
import CallEditForm from "./CallEditForm";
import ReminderEditForm from "./ReminderEditForm";
import { getReminders } from "../actions/reminders";

class Calls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instytucja: undefined,
      calls: undefined,
      callEditModalOpen: false,
      callForEdit: undefined,
      reminders: undefined,
      reminderEditModalOpen: false,
      reminderForEdit: undefined,
    };
    this.handleCallAdd = this.handleCallAdd.bind(this);
    this.handleCallEditFormClose = this.handleCallEditFormClose.bind(this);
    this.handleReminderAdd = this.handleReminderAdd.bind(this);
    this.handleReminderEditFormClose = this.handleReminderEditFormClose.bind(
      this
    );
  }

  componentDidMount() {
    this.setState({ instytucja: this.props.instytucja });
    this.loadCalls();
    this.loadReminders();
  }

  loadCalls() {
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .get(
        endpoint + "/calls/view/?instytucja=" + this.props.instytucja,
        config
      )
      .then((res) => {
        if (res.data.count > 0) {
          this.setState({ calls: res.data.results });
        } else {
          this.setState({ calls: [] });
        }
      })
      .catch((err) => console.log(err));
  }

  handleCallAdd() {
    const data = { instytucja: this.state.instytucja };
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .post(endpoint + "/calls/create/", data, config)
      .then((res) => {
        this.loadCalls();
        this.setState({
          callForEdit: res.data,
          callEditModalOpen: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleOnCallClick(e, call) {
    e.preventDefault();
    this.setState({ callForEdit: call, callEditModalOpen: true });
  }

  handleCallEditFormClose(save = false) {
    if (save) {
      this.loadCalls();
    }
    this.setState({ callForEdit: undefined, callEditModalOpen: false });
  }

  loadReminders() {
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .get(
        endpoint +
          "/reminders/view/?instytucja=" +
          this.props.instytucja +
          "&limit=1000",
        config
      )
      .then((res) => {
        if (res.data.count > 0) {
          let reminders = res.data.results;
          let startofday = moment().startOf("day");
          let endofday = moment().endOf("day");
          reminders.forEach((r) => {
            let rmoment = moment(
              r.date + " " + (r.time === null ? "00:00:00" : r.time),
              "YYYY-MM-DD HH:mm:ss"
            );
            if (r.done) {
              r["style"] = { backgroundColor: "#ffffff" };
            } else {
              if (rmoment > endofday) {
                r["style"] = {
                  backgroundColor: this.props.eventStatusesDict[1].bgcolor,
                };
              } else if (rmoment <= endofday && rmoment >= startofday) {
                r["style"] = {
                  backgroundColor: this.props.eventStatusesDict[2].bgcolor,
                };
              } else {
                r["style"] = {
                  backgroundColor: this.props.eventStatusesDict[3].bgcolor,
                };
              }
            }
          });
          this.setState({ reminders });
        } else {
          this.setState({ reminders: [] });
        }
      })
      .catch((err) => console.log(err));
  }

  handleReminderAdd() {
    const data = {
      instytucja: this.state.instytucja,
      time: moment("09:00:00", "HH:mm:ss").format("HH:mm:ss"),
    };
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .post(endpoint + "/reminders/create/", data, config)
      .then((res) => {
        this.loadReminders();
        this.setState({
          reminderForEdit: res.data,
          reminderEditModalOpen: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleOnReminderClick(e, reminder) {
    e.preventDefault();
    this.setState({ reminderForEdit: reminder, reminderEditModalOpen: true });
  }

  handleReminderEditFormClose(save = false) {
    if (save) {
      this.loadReminders();
      this.props.getReminders({});
    }
    this.setState({ reminderForEdit: undefined, reminderEditModalOpen: false });
  }

  render() {
    const {
      calls,
      callEditModalOpen,
      callForEdit,
      reminders,
      reminderEditModalOpen,
      reminderForEdit,
    } = this.state;
    return (
      <Panel bodyFill={true}>
        <PanelGroup accordion>
          <Panel
            header="Historia kontaktów z placówką"
            collapsible
            defaultExpanded
          >
            {!calls ? (
              <Loader content="Wczytuję dane kontaktów z placówką..." />
            ) : (
              <Fragment>
                <ButtonToolbar style={{ marginBottom: 15 }}>
                  <Button appearance="ghost" onClick={this.handleCallAdd}>
                    Dodaj Kontakt
                  </Button>
                </ButtonToolbar>
                {calls.length > 0 ? (
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Data modyfikacji</th>
                        <th>Data utworzenia</th>
                        <th>Autor</th>
                        <th>Akcja</th>
                      </tr>
                    </thead>
                    <tbody>
                      {calls.map((call) => (
                        <tr key={call.id}>
                          <td>{call.id}</td>
                          <td>
                            <a
                              style={{ color: "black" }}
                              href="/"
                              appearance="link"
                              onClick={(e) => this.handleOnCallClick(e, call)}
                            >
                              {moment(call.modified).format(
                                "[dnia: ] DD-MM-YYYY [ o godzinie: ] HH:mm:ss"
                              )}
                            </a>
                          </td>
                          <td>
                            {moment(call.created).format(
                              "[dnia: ] DD-MM-YYYY [ o godzinie: ] HH:mm:ss"
                            )}
                          </td>
                          <td>{call.username ? call.username : null}</td>
                          <td>
                            <a
                              href="/"
                              appearance="link"
                              onClick={(e) => this.handleOnCallClick(e, call)}
                            >
                              Edytuj
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div>Brak kontaktów z placówką</div>
                )}
              </Fragment>
            )}
          </Panel>
          <Panel
            header="Przypomnienia o kontaktach"
            collapsible
            defaultExpanded
          >
            {!reminders ? (
              <Loader content="Wczytuję dane kontaktów z placówką..." />
            ) : (
              <Fragment>
                <ButtonToolbar style={{ marginBottom: 15 }}>
                  <Button appearance="ghost" onClick={this.handleReminderAdd}>
                    Dodaj Przypomnienie
                  </Button>
                </ButtonToolbar>
                {reminders.length > 0 ? (
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Data przypomnienia</th>
                        <th>Data modyfikacji</th>
                        <th>Data utworzenia</th>
                        <th>Autor</th>
                        <th>Akcja</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reminders.map((reminder) => (
                        <tr key={reminder.id} style={reminder.style}>
                          <td>{reminder.id}</td>
                          <td>
                            <a
                              style={{ color: "black" }}
                              href="/"
                              appearance="link"
                              onClick={(e) =>
                                this.handleOnReminderClick(e, reminder)
                              }
                            >
                              {moment(
                                reminder.date +
                                  " " +
                                  (reminder.time === null
                                    ? "00:00:00"
                                    : reminder.time),
                                "YYYY-MM-DD HH:mm:ss"
                              ).format(
                                "[dnia: ] DD-MM-YYYY [ o godzinie: ] HH:mm:ss"
                              )}
                            </a>
                          </td>
                          <td>
                            {moment(reminder.modified).format(
                              "[dnia: ] DD-MM-YYYY [ o godzinie: ] HH:mm:ss"
                            )}
                          </td>
                          <td>
                            {moment(reminder.created).format(
                              "[dnia: ] DD-MM-YYYY [ o godzinie: ] HH:mm:ss"
                            )}
                          </td>
                          <td>
                            {reminder.username ? reminder.username : null}
                          </td>

                          <td>
                            <a
                              href="/"
                              appearance="link"
                              onClick={(e) =>
                                this.handleOnReminderClick(e, reminder)
                              }
                            >
                              Edytuj
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div>Brak przypomnień dla placówki</div>
                )}
              </Fragment>
            )}
          </Panel>
        </PanelGroup>
        <Modal
          full={false}
          backdrop={true}
          autoFocus={true}
          enforceFocus={true}
          overflow={true}
          show={callEditModalOpen}
        >
          <CallEditForm
            call={callForEdit}
            onCallEditFormClose={this.handleCallEditFormClose}
          />
        </Modal>
        <Modal
          full={false}
          backdrop={true}
          autoFocus={true}
          enforceFocus={true}
          overflow={true}
          show={reminderEditModalOpen}
        >
          <ReminderEditForm
            reminder={reminderForEdit}
            onReminderEditFormClose={this.handleReminderEditFormClose}
          />
        </Modal>
      </Panel>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  eventStatusesDict: state.events.eventStatusesDict,
});

export default connect(mapStateToProps, { getReminders })(Calls);
