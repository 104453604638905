import React, { Component } from "react";
import { connect } from "react-redux";
import { Panel, Icon } from "rsuite";
import GoogleMapReact from "google-map-react";
import Geocode from "react-geocode";
import { updateSuggestedEvent } from "../actions/evtmgr";

const GoogleApiKey = "AIzaSyCpV_7RpfIBhkpCEE6m4Y9eDcfvm2-KAFo";

Geocode.setApiKey(GoogleApiKey);
Geocode.setLanguage("pl");
Geocode.setRegion("pl");

function getInstytucjaAddress(instytucja_info) {
  let address = "";
  if (
    instytucja_info.profil !== null &&
    instytucja_info.profil.miejscowosc.length > 0
  ) {
    address =
      address +
      instytucja_info.profil.ulica +
      " " +
      instytucja_info.profil.miejscowosc +
      " " +
      instytucja_info.profil.kraj;
  } else if (instytucja_info.miejscowosc.length > 0) {
    address =
      address +
      instytucja_info.ulica +
      " " +
      instytucja_info.numerbud +
      " " +
      instytucja_info.miejscowosc +
      " " +
      instytucja_info.kraj;
  } else {
    address = null;
  }
  return address;
}

class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    console.log("Google Map mounted");
    if (!this.props.eventData?.eventLocations) {
      let eventLocations = { prevEvent: {}, event: {}, nextEvent: {} };
      if (this.props.instytucja_info) {
        eventLocations.event.address = getInstytucjaAddress(
          this.props.instytucja_info
        );
      }
      if (this.props.eventData?.prev_event != null) {
        eventLocations.prevEvent.address = getInstytucjaAddress(
          this.props.eventData?.prev_event?.instytucja_info
        );
      } else {
        eventLocations.prevEvent.address = null;
      }
      if (this.props.eventData?.next_event != null) {
        eventLocations.nextEvent.address = getInstytucjaAddress(
          this.props.eventData?.next_event?.instytucja_info
        );
      } else {
        eventLocations.nextEvent.address = null;
      }
      for (const [key, obj] of Object.entries(eventLocations)) {
        if (obj.address != null) {
          Geocode.fromAddress(obj.address).then(
            (res) => {
              obj.location = res.results[0].geometry.location;
              console.log(res.results);
              console.log(eventLocations[key]);
              this.setState({ eventLocations: eventLocations });
            },
            (err) => {
              console.log(err);
            }
          );
        }
      }
      let newEventData = this.props.eventData;
      newEventData.eventLocations = eventLocations;
      this.props.updateSuggestedEvent(this.props.instytucja, newEventData);
      this.props.setEventForGoogleMap(newEventData);
    }
  }

  componentWillUnmount() {
    console.log("Google Map unmounted");
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.eventData !== this.props.eventData) {
      console.log("Google Map props updated - new event for map");
    }
  }

  render() {
    const EventMarker = ({ text, color }) => (
      <div>
        <Icon icon="map-marker" size={"2x"} style={{ color: color }} />
        <span style={{ color: color, fontWeight: "bold" }}>{text}</span>
      </div>
    );
    return (
      <Panel bodyFill={true}>
        <div style={{ height: "100vh", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: GoogleApiKey }}
            defaultCenter={{ lat: 50.06161, lng: 19.93733 }}
            center={this.props.eventData?.eventLocations?.event?.location}
            defaultZoom={11}
          >
            {this.props.eventData?.prev_event != null ? (
              <EventMarker
                lat={
                  this.props.eventData?.eventLocations?.prevEvent?.location?.lat
                }
                lng={
                  this.props.eventData?.eventLocations?.prevEvent?.location
                    ?.lng
                }
                text={"-1"}
                color={"green"}
              />
            ) : null}
            <EventMarker
              lat={this.props.eventData?.eventLocations?.event?.location?.lat}
              lng={this.props.eventData?.eventLocations?.event?.location?.lng}
              text={"0"}
              color={"red"}
            />
            {this.props.eventData?.next_event != null ? (
              <EventMarker
                lat={
                  this.props.eventData?.eventLocations?.nextEvent?.location?.lat
                }
                lng={
                  this.props.eventData?.eventLocations?.nextEvent?.location?.lng
                }
                text={"+1"}
                color={"blue"}
              />
            ) : null}
          </GoogleMapReact>
        </div>
      </Panel>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { updateSuggestedEvent })(GoogleMap);
