import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { loadUser, loadUserList } from "../actions/auth";
import { logout } from "../actions/auth";
import Instytucje from "./Instytucje";
import InstytucjeDetail from "./InstytucjeDetail";
import Home from "./Home";
import TimetableMain from "./TimetableMain";
import HarmonogramPrinter from "./HarmonogramPrinter";
import RemindersWithState from "./RemindersWithState";
import Kontakty from "./Kontakty";
import Payments from "./Payments";
import NotFound from "./NotFound";
import { getActs } from "../actions/acts";
import { getTeams } from "../actions/teams";
import { getEventStatuses } from "../actions/events";

import { Navbar, Nav, Icon, Container, Sidebar, Sidenav } from "rsuite";

function mapStateToProps(state) {
  return { user: state.auth.user };
}

function mapDispatchToProps(dispatch) {
  return {
    loadUser: () => dispatch(loadUser()),
    loadUserList: () => dispatch(loadUserList()),
    logout: () => dispatch(logout()),
    getActs: () => dispatch(getActs()),
    getTeams: () => dispatch(getTeams()),
    getEventStatuses: () => dispatch(getEventStatuses()),
  };
}

const headerStyles = {
  padding: 16,
  fontSize: 16,
  height: 56,
  background: "#0F4A85",
  color: "#fff",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

const NavToggle = ({ expand, onChange }) => {
  return (
    <Navbar appearance="subtle" className="nav-toggle">
      <Navbar.Body>
        <Nav pullRight>
          <Nav.Item
            onClick={onChange}
            style={{ width: 50, textAlign: "center" }}
          >
            <Icon icon={expand ? "angle-left" : "angle-right"} />
          </Nav.Item>
        </Nav>
      </Navbar.Body>
    </Navbar>
  );
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: true,
      active: null,
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleActive = this.handleActive.bind(this);
  }
  handleToggle() {
    this.setState({
      expand: !this.state.expand,
    });
  }
  handleActive(eventKey) {
    this.setState({
      active: eventKey,
    });
  }
  componentDidMount() {
    if (!this.props.user) {
      this.props.loadUser();
    }
    if (this.props.user && this.props.user.is_staff) {
      this.props.loadUserList();
    }
    this.props.getActs();
    this.props.getTeams();
    this.props.getEventStatuses();
  }

  render() {
    const { expand } = this.state;
    return (
      <div className="show-fake-browser sidebar-page">
        <Container>
          <Sidebar
            style={{ display: "flex", flexDirection: "column" }}
            width={expand ? 260 : 56}
            collapsible
          >
            <Sidenav.Header>
              <div style={headerStyles}>
                <Icon
                  icon="logo-analytics"
                  size="lg"
                  style={{ verticalAlign: 0 }}
                />
                <span style={{ marginLeft: 15 }}> Teatr Eden Panel</span>
              </div>
            </Sidenav.Header>
            <Sidenav
              expanded={expand}
              appearance="subtle"
              activeKey={this.state.active}
              onSelect={this.handleActive}
            >
              <Sidenav.Body>
                <Nav>
                  <Nav.Item
                    eventKey="1"
                    icon={<Icon icon="building-o" />}
                    href="#admin/instytucje"
                  >
                    Placówki
                  </Nav.Item>
                  <Nav.Item
                    eventKey="2"
                    icon={<Icon icon="calendar" />}
                    href="#admin/terminarz"
                  >
                    Terminarz
                  </Nav.Item>
                  <Nav.Item
                    eventKey="3"
                    icon={<Icon icon="print" />}
                    href="#admin/hprinter"
                  >
                    HPrinter
                  </Nav.Item>
                  <Nav.Item
                    eventKey="4"
                    icon={<Icon icon="bell" />}
                    href="#admin/przypomnienia"
                  >
                    Przypomnienia
                  </Nav.Item>
                  <Nav.Item
                    eventKey="5"
                    icon={<Icon icon="money" />}
                    href="#admin/platnosci"
                  >
                    Płatności
                  </Nav.Item>
                  <Nav.Item
                    eventKey="6"
                    icon={<Icon icon="phone" />}
                    href="#admin/kontakty"
                  >
                    Kontakty
                  </Nav.Item>
                  <Nav.Item
                    eventKey="7"
                    icon={<Icon icon="home" />}
                    href="#admin/home"
                  >
                    Home
                  </Nav.Item>
                  <Nav.Item
                    eventKey="8"
                    icon={<Icon icon="sign-out" />}
                    onClick={this.props.logout}
                  >
                    Wyloguj
                  </Nav.Item>
                </Nav>
              </Sidenav.Body>
            </Sidenav>
            <NavToggle expand={expand} onChange={this.handleToggle} />
          </Sidebar>

          <Container style={{ marginLeft: 20, marginRight: 20 }}>
            <Switch>
              <Route
                exact
                path={`${this.props.match.url}/home`}
                component={Home}
              />
              <Route
                exact
                path={`${this.props.match.url}/instytucje`}
                component={Instytucje}
              />
              <Route
                exact
                path={`${this.props.match.url}/instytucje/:inst_id`}
                component={InstytucjeDetail}
              />
              <Route
                exact
                path={`${this.props.match.url}/terminarz`}
                component={TimetableMain}
              />
              <Route
                exact
                path={`${this.props.match.url}/hprinter`}
                component={HarmonogramPrinter}
              />
              <Route
                exact
                path={`${this.props.match.url}/przypomnienia`}
                component={RemindersWithState}
              />
              <Route
                exact
                path={`${this.props.match.url}/kontakty`}
                component={Kontakty}
              />
              <Route
                exact
                path={`${this.props.match.url}/platnosci`}
                component={Payments}
              />
              <Route exact path={`${this.props.match.url}`} component={Home} />
              <Route component={NotFound} />
            </Switch>
          </Container>
        </Container>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
