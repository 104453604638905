import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getActs } from "../actions/acts";
import { getTeams } from "../actions/teams";
import axios from "axios";
import { endpoint } from "../config";
import EventStatusHistory from "./EventStatusHistory";
import InstytucjaInfo from "./InstytucjaInfo";
import {
  Grid,
  Row,
  Col,
  Loader,
  Divider,
  ButtonToolbar,
  Button,
  Input,
  SelectPicker,
  TagPicker,
  IconButton,
  Icon,
  Alert,
  DatePicker,
} from "rsuite";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import { Locale } from "../locale";

const labelFieldStyles = {
  fontSize: 12,
  color: " #aaa",
  marginTop: 10,
  marginBottom: 10,
  textDecoration: "underline",
};

const eventNotesRef = React.createRef();

class EventDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventData: {},
      actsData: [],
      teamsData: [],
    };
    this.handleChangeField = this.handleChangeField.bind(this);
    this.handleChangeActsSelector = this.handleChangeActsSelector.bind(this);
    this.handleChangeTeamSelector = this.handleChangeTeamSelector.bind(this);
    this.handleDataSave = this.handleDataSave.bind(this);
    this.handleDeleteEvent = this.handleDeleteEvent.bind(this);
    this.handleOnClickHistoryNotes = this.handleOnClickHistoryNotes.bind(this);
    this.renderActsItems = this.renderActsItems.bind(this);
    this.handleChangeEventDate = this.handleChangeEventDate.bind(this);
  }

  componentDidMount() {
    this.setState({ eventData: this.props.eventData });
    if (this.props.acts.length === 0) {
      this.props.getActs();
    }
    if (this.props.teams.length === 0) {
      this.props.getTeams();
    }
    this.actsPropsToState();
    this.teamsPropsToState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.acts.length > prevProps.acts.length) {
      this.actsPropsToState();
    }
    if (this.props.teams.length > prevProps.teams.length) {
      this.teamsPropsToState();
    }
    if (
      this.props &&
      this.prevProps &&
      this.props.eventData !== this.prevProps.eventData
    ) {
      this.setState({ eventData: this.props.eventData });
    }
  }

  actsPropsToState() {
    const actsData = this.props.acts.map((act) => ({
      label: act.title,
      value: act.id,
    }));
    this.setState({ actsData });
  }

  teamsPropsToState() {
    const teamsData = this.props.teams
      .filter(
        (team) =>
          this.props.auth.user.is_staff ||
          this.props.auth.user.teams.includes(team.id)
      )
      .map((team) => ({
        label: team.name,
        value: team.id,
      }));
    this.setState({ teamsData });
  }

  handleChangeField(e) {
    this.setState({
      ...this.state,
      eventData: {
        ...this.state.eventData,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleChangeActsSelector(val) {
    this.setState({
      ...this.state,
      eventData: {
        ...this.state.eventData,
        acts: val,
      },
    });
    console.log(val);
  }

  handleChangeTeamSelector(val) {
    this.setState({
      ...this.state,
      eventData: {
        ...this.state.eventData,
        team: val,
      },
    });
    this.props.onChangeTeam(val);
  }

  handleDataSave() {
    this.props.onEventUpdate(this.state.eventData);
  }

  handleDeleteEvent() {
    this.props.onEventDelete(this.state.eventData.id);
  }

  handleOnClickHistoryNotes() {
    const inst_id = this.state.eventData.instytucja;
    const token = this.props.auth.token;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    axios
      .get(
        endpoint +
          "/events/event/?instytucja=" +
          inst_id +
          "&limit=100" +
          "&ordering=-modified" +
          "&team=999",
        config
      )
      .then((res) => {
        if (res.data.count > 0) {
          let history_notes = "";
          for (let el of res.data.results.entries()) {
            if (el[1].notes && el[1].notes.length > 0) {
              history_notes = el[1].notes;
              break;
            }
          }
          eventNotesRef.current.value = history_notes;
          this.setState({
            ...this.state,
            eventData: {
              ...this.state.eventData,
              notes: history_notes,
            },
          });
        } else {
          Alert.warning("Brak wydarzeń w historii");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  renderActsItems(label, item) {
    const itemStyle = {};
    if (this.props.weekActs) {
      if (this.props.weekActs.mainacts.includes(item.value)) {
        itemStyle["color"] = "red";
      } else if (this.props.weekActs.resvacts.includes(item.value)) {
        itemStyle["color"] = "green";
      }
    }
    const itemView = <div style={itemStyle}>{label}</div>;
    return itemView;
  }

  handleChangeEventDate(name, date) {
    if (name === "eventDate") {
      let newEnd = moment(this.state.eventData.end);
      newEnd.date = newEnd
        .date(moment(date).date())
        .month(moment(date).month())
        .year(moment(date).year());
      this.setState({
        ...this.state,
        eventData: {
          ...this.state.eventData,
          start: date,
          end: newEnd.toDate(),
        },
      });
      console.log(date, newEnd.toDate());
    } else if (name === "eventStartTime") {
      if (moment(date) < moment(this.state.eventData.end)) {
        this.setState({
          ...this.state,
          eventData: {
            ...this.state.eventData,
            start: date,
          },
        });
      } else {
        Alert.warning("Błędny wybór czasu wydarzenia");
      }
    } else if (name === "eventEndTime") {
      if (moment(date) > moment(this.state.eventData.start)) {
        this.setState({
          ...this.state,
          eventData: {
            ...this.state.eventData,
            end: date,
          },
        });
      } else {
        Alert.warning("Błędny wybór czasu wydarzenia");
      }
    }
  }

  render() {
    const { eventData, actsData, teamsData } = this.state;
    if (eventData === undefined || Object.keys(eventData).length === 0) {
      return <Loader backdrop content="Wczytuję dane wydarzenia..." vertical />;
    } else {
      return (
        <Fragment>
          <Grid fluid>
            <Row className="show-grid">
              <Col xs={12}>
                <div style={labelFieldStyles}>Dane kontaktowe</div>
                <InstytucjaInfo
                  instytucja_info={eventData.instytucja_info}
                  history={this.props.history}
                  changeTab={this.props.changeTab}
                />
              </Col>
              <Col xs={12}>
                {eventData.instytucja_info?.profil?.vatdata ? (
                  <>
                    <div style={labelFieldStyles}>Dane do faktury</div>
                    <ReactMarkdown
                      source={eventData.instytucja_info.profil.vatdata}
                    />
                  </>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Grid>
          <Grid fluid>
            <Row className="show-grid">
              <Col xs={6}>
                <div style={labelFieldStyles}>Zaplanowane na dzień</div>
                <DatePicker
                  cleanable={false}
                  name="eventDate"
                  locale={Locale}
                  format="YYYY-MM-DD"
                  isoWeek={true}
                  value={eventData.start}
                  onChange={(date) =>
                    this.handleChangeEventDate("eventDate", date)
                  }
                />
              </Col>
              <Col xs={6}>
                <div style={labelFieldStyles}>W godzinach</div>
                <span> od: </span>
                <DatePicker
                  cleanable={false}
                  name="eventStartTime"
                  locale={Locale}
                  format="HH:mm"
                  isoWeek={true}
                  hideHours={(hour) => hour < 7 || hour > 20}
                  hideMinutes={(minute) => minute % 5 !== 0}
                  value={eventData.start}
                  onChange={(date) =>
                    this.handleChangeEventDate("eventStartTime", date)
                  }
                />
                <span> do: </span>
                <DatePicker
                  cleanable={false}
                  name="eventEndTime"
                  value={eventData.end}
                  locale={Locale}
                  format="HH:mm"
                  isoWeek={true}
                  hideHours={(hour) => hour < 7 || hour > 20}
                  hideMinutes={(minute) => minute % 5 !== 0}
                  onChange={(date) =>
                    this.handleChangeEventDate("eventEndTime", date)
                  }
                />
              </Col>
            </Row>

            <Row className="show-grid">
              <Col xs={16}>
                <div style={labelFieldStyles}>Przedstawienia Teatralne</div>
                <TagPicker
                  block
                  data={actsData}
                  value={eventData.acts}
                  onChange={this.handleChangeActsSelector}
                  renderMenuItem={this.renderActsItems}
                />
              </Col>
              <Col xs={8}>
                <div style={labelFieldStyles}>Zespół Teatralny</div>
                <SelectPicker
                  cleanable={false}
                  block
                  data={teamsData}
                  value={eventData.team}
                  onChange={this.handleChangeTeamSelector}
                />
              </Col>
            </Row>
            <Row className="show-grid">
              <Col xs={8}>
                <div style={labelFieldStyles}>Cena od</div>
                <Input
                  style={{ width: 80 }}
                  name="payfrom"
                  defaultValue={eventData.payfrom}
                  onBlur={this.handleChangeField}
                />
              </Col>
              <Col xs={8}>
                <div style={labelFieldStyles}>Cena do</div>
                <Input
                  style={{ width: 80 }}
                  name="payto"
                  defaultValue={eventData.payto}
                  onBlur={this.handleChangeField}
                />
              </Col>
              <Col xs={8}>
                <div style={labelFieldStyles}>Zapłacono</div>
                <Input
                  style={{ width: 80 }}
                  name="paid"
                  defaultValue={eventData.paid}
                  onBlur={this.handleChangeField}
                />
              </Col>
            </Row>
            <Divider />
            <Row className="show-grid">
              <Col xs={24} sm={24} lg={8}>
                <div style={labelFieldStyles}>Notatki</div>
                <Input
                  componentClass="textarea"
                  style={{ width: 400 }}
                  rows={6}
                  name="notes"
                  defaultValue={eventData.notes}
                  onBlur={this.handleChangeField}
                  inputRef={eventNotesRef}
                />
                {!eventData.notes || eventData.notes.length === 0 ? (
                  <IconButton
                    icon={<Icon icon="history" />}
                    circle
                    size="md"
                    onClick={this.handleOnClickHistoryNotes}
                  />
                ) : (
                  true
                )}
              </Col>
              <Col xs={24} sm={24} lg={16}>
                <div style={labelFieldStyles}>Historia statusu wydarzenia</div>
                <EventStatusHistory
                  eventId={eventData.id}
                  forceLoadEvents={this.props.forceLoadEvents}
                />
              </Col>
            </Row>
            <Divider />
            <Row className="show-grid">
              <Col xs={12}>
                <div style={labelFieldStyles}>Data modyfikacji</div>
                <div>
                  {moment(eventData.modified).format(
                    "[dnia: ] DD-MM-YYYY [ o godzinie: ] HH:mm:ss"
                  )}
                </div>
              </Col>
              <Col xs={12}>
                <div style={labelFieldStyles}>Data utworzenia</div>
                <div>
                  {moment(eventData.created).format(
                    "[dnia: ] DD-MM-YYYY [ o godzinie: ] HH:mm:ss"
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Divider />
              <Col xs={24}>
                <ButtonToolbar style={{ textAlign: "right" }}>
                  <Button
                    appearance="default"
                    onClick={this.props.onEditEventModalClose}
                  >
                    Anuluj
                  </Button>
                  {this.props.auth.user.username !== "ewaporebska" ? (
                    <Button
                      appearance="primary"
                      color="red"
                      onClick={this.handleDeleteEvent}
                    >
                      Usuń wydarzenie
                    </Button>
                  ) : null}
                  <Button appearance="primary" onClick={this.handleDataSave}>
                    Zapisz dane wydarzenia
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          </Grid>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  acts: state.acts.acts,
  teams: state.teams.teams,
});

export default connect(mapStateToProps, { getActs, getTeams })(EventDetailForm);
